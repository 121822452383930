import styled from "styled-components";
import { useAuth } from "../../../services/auth";
import { Box, Divider, Flex, Icon, Type } from "../../../ui";
import List from "../../../ui/List";
import {
  currency,
  dateFromNow,
  getStatus,
  toThousand,
} from "../../../utilities/functions";

const InfoBox = styled(Box)`
  background-color: ${(props) => props.theme.color.gray50};
  border-radius: ${(props) => props.theme.borderRadius}px;
  padding: 20px 0;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.1);
  ul {
    padding: 0 40px;
    @media only screen and (max-width: 768px) {
      padding: 0 30px;
    }
    @media only screen and (max-width: 400px) {
      padding: 0 20px;
    }
  }
`;

function Info({ financialInfo, labels }) {
  const { user } = useAuth();

  function displayTimeLeft() {
    const days = Number(dateFromNow(financialInfo?.closingTime * 1000)[0]);
    const hours = Number(dateFromNow(financialInfo?.closingTime * 1000)[1]);

    if (days < 0 || hours < 0) {
      return "0 days";
    }

    if (days > 0) {
      return days + " days";
    }
    if (hours > 0) {
      return hours + " hours";
    }
    return "less than an hour";
  }

  return (
    <InfoBox>
      <Flex
        jc="space-between"
        sx={{ px: 40 }}
        dir={["row", "row", "row", "column"]}
        gap={[0, 0, 0, "20px"]}
      >
        <Box>
          <Type
            var="h3"
            as="h3"
            sx={{ mb: 0, ta: ["left", "left", "left", "center"] }}
          >
            {financialInfo?.companyName}
          </Type>
          <Type var="titleWisp" sx={{ mt: 8, fstyle: "italic" }}>
            Time left &bull; {displayTimeLeft()}
          </Type>
        </Box>
        <Box>
          <Flex gap={12}>
            <Type var="h4" as="span">
              Status
            </Type>{" "}
            <Type
              sx={{
                fs: 22,
                c: financialInfo?.status === 0 ? "gray500" : "primary",
                fw: 900,
              }}
              as="span"
            >
              {getStatus(financialInfo?.status)}
            </Type>{" "}
            <Icon
              name="donut"
              c={financialInfo?.status === 0 ? "gray500" : "primary"}
              w={30}
            />
          </Flex>
        </Box>
      </Flex>
      <Divider border="gray300" />
      <List as="ul">
        <li>
          <span>Amount Raised</span>
          <span>USDT {toThousand(financialInfo?.amountRaised)}</span>
        </li>
        <li className="primary">
          <span>Target</span>
          <span>
            USDT{" "}
            {toThousand(financialInfo?.tokenCap * financialInfo?.tokenPrice)}
          </span>
        </li>
        <li>
          <span>Investors</span>
          <span>{financialInfo?.investorCount}</span>
        </li>
        <li>
          <span>Min Invest</span>
          <span>{financialInfo?.minPurchase}</span>
        </li>
        <li>
          <span>Token Price</span>
          <span>USDT {toThousand(financialInfo?.tokenPrice)}</span>
        </li>
        <li>
          <span>Stitvest Commission</span>
          <span>{financialInfo?.stitvestRate}%</span>
        </li>
        <li>
          <span>Blockchain Details</span>
          <span>{user?.tokens?.blockchainData?.networkName}</span>
        </li>
        <li className="primary">
          <span>Currencies</span>
          <span>Tether (USDT)</span>
        </li>
      </List>
    </InfoBox>
  );
}

export default Info;
