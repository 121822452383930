import { useEffect, useState } from "react";
import LayoutMasked from "../../layout/LayoutMasked";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useAuth } from "../../services/auth";
import { Box } from "../../ui";
import { useNavigate } from "react-router-dom";

function VerifyIdentity() {
  const { user, generateSumsubToken } = useAuth();
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await generateSumsubToken(user.user.email.replace("@", ""));
      setToken(res.token);
    })();
  }, [generateSumsubToken, user]);

  const accessTokenExpirationHandler = (v) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await generateSumsubToken(user.email.replace("@", ""));
        resolve(res.token);
      } catch (e) {
        console.log(e);
        resolve(e);
      }
    });
    // return (async () => {
    //   const res = await generateSumsubToken(user.email.replace("@", ""));
    //   console.log(res.token);
    //   return res.token;
    // })();
  };

  const messageHandler = (v, t) => {
    console.log('Sumsub web sdk "messageHandler"', v, t);
  };

  const errorHandler = (v) => {
    console.log('Sumsub web sdk "errorHandler"', v);
  };

  if (!token) return;

  return (
    <LayoutMasked title="Verify your Identity" onClose={() => navigate('/')}>
      <Box sx={{ w: [700,700,700, '100%'] }}>
        <SumsubWebSdk
          accessToken={token}
          expirationHandler={accessTokenExpirationHandler}
          config={{ lang: "en", email: user.email }}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      </Box>
    </LayoutMasked>
  );
}

export default VerifyIdentity;
