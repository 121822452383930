import { useAuth } from "../services/auth";
import { Button, Flex, Icon, Link, Type } from "../ui";
import useMetamask from "../utilities/hooks/useMetamask";
import { useProfile } from "../views/profile/provider";

function ConnectMetamask() {
  const { user } = useAuth();
  const { setWallet } = useProfile();

  const { connecting, connectMetamask } = useMetamask({
    setWallet,
    network: user.tokens.blockchainData,
  });

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={connectMetamask}
        loading={connecting}
        disabled={!user.user.isVerified || !window.ethereum}
      >
        Connect Now
      </Button>
      {!window.ethereum && (
        <Type as="div" sx={{ pt: 10, c: "red" }}>
          You must have metamask browser plugin installed to be able to connect.
        </Type>
      )}
      {!user.user.isVerified && (
        <Type as="div" sx={{ pt: 10, c: "red" }}>
          You must verify your identity in order to connect to metamask.
        </Type>
      )}
    </>
  );
}

export default ConnectMetamask;
