import { useState } from "react";
import styled, { css } from "styled-components";
import Pagination from "./Pagination";
import Item from "./Item";
import Nav from "./Nav";
import { useRef } from "react";
import { useEffect } from "react";

const Wrap = styled.div`
  width: ${(p) => p.w}px;
  overflow-x: hidden;
  ${(p) =>
    p.center &&
    css`
      display: flex;
      justify-content: center;
    `}
  .items {
    display: flex;
    gap: ${(p) => p.gap}px;
    width: ${(p) => p.count * (p.itemW + p.gap) - p.gap}px;
    transform: translateX(${(p) => p.start}px);
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  }
`;

function Carousel({ options, ...props }) {
  const {
    slides,
    slide,
    itemW,
    page = 0,
    setPage = () => {},
    totalSlides = 2,
    gap = 30,
    nav = false,
    pager = false,
  } = options;

  const [start, setStart] = useState(0);
  const [w, setW] = useState(1);
  const ref = useRef(null);

  useEffect(() => {
    setW((totalSlides <= slides.length ? totalSlides : slides.length) * (itemW + gap) - gap);
  }, [totalSlides, itemW, gap, slides]);

  useEffect(() => {
    setStart(-page * (w));
  }, [page, w, gap]);

  // const addItemW = slides.length % totalSlides ? 0 : itemW;

  const next = () => {
    if (Math.abs(start) < (slides.length * itemW) - w) {
      setStart((start) => start - (itemW + gap));
      setPage((p) => ++p);
    } else {
      setStart(0);
      setPage(0);
    }
  };

  const prev = () => {
    if (Math.abs(start) > 0) {
      setStart((start) => start + (itemW + gap));
      setPage((p) => --p);
    } else {
      setStart(-(slides.length - totalSlides) * (itemW + gap));
      setPage(slides.length - 1);
    }
  };

  const setPager = (p) => setStart(-p * w);

  const hasHiddenSlides = w / itemW < slides.length;

  return (
    <div style={{ position: "relative" }}>
      <Wrap
        center={!hasHiddenSlides}
        className="carousel"
        {...props}
        itemW={itemW}
        w={w}
        start={start}
        gap={gap}
        count={slides.length}
        ref={ref}
      >
        <div className="items">
          {slides.map((slideData, i) => (
            <Item key={i} slide={slide} data={slideData} w={itemW} />
          ))}
        </div>
      </Wrap>
      {Boolean(pager) && hasHiddenSlides && (
        <Pagination
          {...{ page: Math.ceil(Math.abs(start) / w), setPager, pager }}
          count={Math.ceil((itemW * slides.length) / w)}
        />
      )}
      {Boolean(nav) && hasHiddenSlides && (
        <Nav nav={nav} handleNext={next} handlePrev={prev} />
      )}
    </div>
  );
}

export default Carousel;
