import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Type } from "../../../ui";
import { truncatWork } from "../../../utilities/functions";

const Styles = styled.div``;

function Closed({ data, to = null }) {
  const navigate = useNavigate();

  return (
    <Styles>
      <Type sx={{ h: [48, 48, 48, "auto"] }}>
        {truncatWork(data.description, 70)}
      </Type>
      <ul>
        <li>
          <span>Token Price:</span> <span>{data.tokenPrice || "N/A"}</span>
        </li>
        <li>
          <span>Tokens Left:</span> <span>{data.tokenLeft || "N/A"}</span>
        </li>
        <li>
          <span>Investors:</span> <span>{data.investorCount || "N/A"}</span>
        </li>
      </ul>
      <Button
        sx={{ mt: 30, mb: 10, w: "100%" }}
        size="small"
        onClick={() => navigate(to || `/company/rewards/${data.id}`)}
      >
        Details
      </Button>
    </Styles>
  );
}

export default Closed;
