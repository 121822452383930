import { useNavigate } from "react-router-dom";
import Layout from "../../../layout/Layout";
import theme from "../../../theme";
import { Box, Card, Type } from "../../../ui";
import Question from "./components/Question";

const data = [
  {
    q: "What is a blockchain-based crowdfunding platform?",
    a: (
      <p>
        A blockchain-based crowdfunding platform is an online marketplace that
        leverages blockchain technology to enable entrepreneurs and investors to
        connect and engage in fundraising activities. These platforms provide a
        secure, transparent, and tamper-proof ecosystem for fundraising
        activities, providing entrepreneurs with access to capital and investors
        with access to promising investment opportunities.
      </p>
    ),
  },
  {
    q: "How does your platform ensure regulatory compliance?",
    a: (
      <p>
        Our platform adheres to all relevant regulatory requirements, ensuring
        that all fundraising activities are conducted in a safe and compliant
        environment. We have implemented advanced compliance and security
        features, including KYC/AML verification, smart contract automation, and
        transparent reporting, to ensure that all parties involved in the
        fundraising process are held accountable and that all transactions are
        executed in a compliant manner.
      </p>
    ),
  },
  {
    q: "What types of fundraising activities are available on your platform?",
    a: (
      <p>
        Our platform offers a wide range of fundraising options, including
        equity and debt financing, revenue-sharing agreements, and token sales.
        We provide entrepreneurs with the flexibility to choose the best
        fundraising model for their business, allowing them to access capital
        and investors from all over the world.
      </p>
    ),
  },
  {
    q: "How does your platform ensure the security of investors' funds?",
    a: (
      <p>
        We have implemented advanced security features, including multi-factor
        authentication, cold storage wallets, and smart contract technology, to
        ensure the security of investors' funds. Our platform also provides full
        transparency and audit trails, allowing investors to track their
        investments and ensure that their funds are being used in a responsible
        and accountable manner.
      </p>
    ),
  },
  {
    q: "What are the benefits of using a blockchain-based crowdfunding platform?",
    a: (
      <p>
        Blockchain-based crowdfunding platforms provide several benefits over
        traditional fundraising methods, including increased security,
        transparency, efficiency, and accessibility. These platforms leverage
        advanced blockchain technology to create a secure and tamper-proof
        ecosystem for fundraising activities, providing entrepreneurs and
        investors with a safe and compliant environment to connect and engage in
        fundraising activities.
      </p>
    ),
  },
  {
    q: "How does your platform differ from other crowdfunding platforms?",
    a: (
      <p>
        Our platform is unique in that it leverages advanced blockchain
        technology to provide a secure, transparent, and tamper-proof ecosystem
        for fundraising activities. We comply with all relevant regulatory
        requirements, providing a safe and compliant environment for
        entrepreneurs and investors to connect and engage in fundraising
        activities. Our platform also offers a wide range of fundraising
        options, providing entrepreneurs with the flexibility to choose the best
        fundraising model for their business.
      </p>
    ),
  },
  {
    q: "7.	How do I get started with using your platform?",
    a: (
      <p>
        Getting started with our platform is easy. Simply create an account,
        complete the KYC/AML verification process, and start exploring the
        fundraising opportunities available on our platform. Our user-friendly
        interface and intuitive tools make it easy to connect with investors,
        showcase your business, and raise the capital you need to succeed.
      </p>
    ),
  },
];

function Faq() {
  return (
    <Layout title="FAQ" breadcrumbs={["home", "FAQ"]}>
      <Box center sx={{ mb: 160, mt: 60, mw: theme.pageWidth }}>
        <Card>

          {data.map((faq, i) => (
            <Question key={i} a={faq.a} q={faq.q} />
          ))}
        </Card>
      </Box>
    </Layout>
  );
}

export default Faq;
