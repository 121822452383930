import { useEffect, useState } from "react";
import { Box, Grid, InputRadio, Loading, Tooltip, Type } from "../../../ui";
import { QRCodeSVG } from "qrcode.react";
import { useProfile } from "../../profile/provider";
import { useUtils } from "../../../services/utilsProvider";
import Dropdown from "../../../ui/core/Dropdown";

const NetworksList = ({ setNetwork, network }) => {
  const {
    state: { networks },
  } = useProfile();

  return (
    <Box sx={{ ml: 30 }}>
      {!networks.length && <Loading size={24} />}
      {networks.map(({ networkName, chainID }, i) => (
        <InputRadio
          key={i}
          name="network"
          value={chainID}
          onChange={(e) =>
            setNetwork(
              networks.find((n) => n.chainID === Number(e.target.value))
            )
          }
          label={networkName}
          checked={chainID === network?.chainID}
        />
      ))}
    </Box>
  );
};

function TransferType({companyId}) {
  const [direct, setDirect] = useState(false);
  const [network, setNetwork] = useState(null);

  const { notify } = useUtils();

  const {
    state: { networks },
    fetchNetworkBalance,
    resetNetworkBalance,
    fetchNetworks,
  } = useProfile();

  useEffect(() => {
    if (!network) return;
    (async () => {
      try {
        await fetchNetworkBalance(network);
      } catch (e) {
        notify("An error occured while connecting to network.", "alert");
      }
    })();
  }, [fetchNetworkBalance, network, notify]);

  useEffect(() => {
    if (!direct) {
      resetNetworkBalance();
      setNetwork(null);
    } else if (!networks.length)
      (async () => {
        const data = await fetchNetworks(companyId);
        setNetwork(data[0]);
      })();
    else {
      setNetwork(networks[0]);
    }
  }, [direct, resetNetworkBalance, networks, fetchNetworks, companyId]);

  return (
    <Grid
      cols={["50% 50%", "50% 50%", "50% 50%", "100%"]}
      gap={10}
      sx={{ p: 30, bgc: "gray100", br: 15 }}
    >
      <Box style={{ alignSelf: "center" }}>
        <Box
          onChange={(e) => setDirect(e.target.value === "on" ? true : false)}
        >
          <InputRadio
            style={{ marginBottom: 20 }}
            name="direct"
            value="off"
            label="Metamask"
            defaultChecked={!direct}
          />
          <InputRadio
            name="direct"
            value="on"
            label="Direct Transfer"
            defaultChecked={direct}
          />
        </Box>
        {Boolean(direct) && (
          <NetworksList setNetwork={setNetwork} network={network} />
        )}
      </Box>
      {Boolean(direct && network?.internalWallet) && (
        <Box sx={{ ta: ["center", "center", "center", "left"] }}>
          <Type
            sx={{
              fs: 14,
              mb: 10,
              mnw: [350, 'auto', 'auto', 'auto'],
              mxw: 380,
              ml: ["auto", "auto", "auto", 0],
              mr: ["auto", "auto", "auto", 0],
              mt: [0, 0, 0, 30],
            }}
          >
            To invest, please recharge this wallet with enough USDT. This wallet
            will serve as your USDT balance.
          </Type>
          <div
            onClick={() => {
              navigator.clipboard.writeText(network?.internalWallet);
              notify("Content copied");
            }}
          >
            <Dropdown
              click={false}
              placement="bottom"
              content={
                <Tooltip size="small">Click or scan to copy Address</Tooltip>
              }
            >
              <Box sx={{ d: "inline-block", cursor: "pointer" }}>
                <QRCodeSVG
                  height={170}
                  width={170}
                  value={network?.internalWallet}
                />
              </Box>
            </Dropdown>
          </div>
        </Box>
      )}
    </Grid>
  );
}

export default TransferType;
