import { Flex, Loading, Type } from "./";
import Mask from "./Mask";

function LoadingMask({ msg, isLoading }) {
  return (
    <Mask show={isLoading}>
      <Flex
        abs={{ t: 0, l: 0 }}
        dir="column"
        gap={30}
        sx={{ w: 360, br: 15, bgc: "gray400", p: 30 }}
      >
        <Loading />
        <Type>{msg}</Type>
      </Flex>
    </Mask>
  );
}

export default LoadingMask;
