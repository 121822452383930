import styled from "styled-components";
import Carousel from "../../ui/carousel/Carousel";
import Type from "../../ui/Type";
import { Box, Flex } from "../../ui";
import { useEffect } from "react";
import { useCompanies } from "../company/provider";
import { useState } from "react";
import CompanyBox from "../company/companyBox/CompanyBox";
import useMedia from "../../utilities/hooks/useMedia";

const SBox = styled(Box)`
  position: relative;
  div.card {
    width: 320px;
    padding: 40px;
    border-radius: 20px;
    background-color: #222f3f;
    .notify {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 0;
      margin: 16px 0 20px 0;
      font-size: 20px;
      svg {
        fill: white;
        width: 22px;
        margin-right: 10px;
      }
      .checkbox-wrap {
        flex-grow: 1;
        text-align: right;
      }
      span.checkbox {
        border-radius: 10px;
        width: 46px;
        height: 20px;
        background-color: #244946;
        position: relative;
        display: inline-block;
        &::before {
          content: "";
          position: absolute;
          top: -2px;
          right: 0px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: ${(props) => props.theme.color.primary};
          opacity: 1;
          cursor: pointer;
          transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
        }
        &:active {
          &::before {
            right: 20px;
          }
        }
      }
    }
    .img {
      width: 100%;
      height: 262px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
`;

const Next = () => {
  return <Type sx={{ fs: 36 }}>&rarr;</Type>;
};

const Prev = () => {
  return <Type sx={{ fs: 36 }}>&larr;</Type>;
};

function ComingSoon() {
  const media = useMedia();
  const [data, setData] = useState([]);

  const { fetchList } = useCompanies();

  useEffect(() => {
    (async () => {
      const data = await fetchList({ statusID: 1 });
      setData(data);
    })();
  }, [fetchList]);

  if (!data.length) return null;

  return (
    <SBox center className="solutions" sx={{ mw: 1370 }}>
      <Flex dir={['row', 'row', 'column-reverse', 'column-reverse']}>
        <Box
          sx={{
            mw: [
              [1400, 870],
              [950, 'none'],
            ],
            w: media === 'xs' || media === "sm" || media === "md" ? '100%' : 'auto',
            ta: "center",
          }}
          center
        >
          {media !== "xs" && media !== "sm" ? (
            <Carousel
              options={{
                slide: CompanyBox,
                slides: data,
                itemW: 350,
                gap: 0,
                nav: {
                  style: {
                    textAlign: "center",
                    marginTop: "-20px",
                  },
                  next: <Next />,
                  prev: <Prev />,
                },
              }}
            />
          ) : (
            <>
              {data.map((item) => (
                <Box key={item.id} sx={{ mb: 40 }}>
                  <CompanyBox data={item} />
                </Box>
              ))}
            </>
          )}
        </Box>

        <Box sx={{mw: 440, mx: 30}}>
          <Type var="display" as="h1">
            coming soon
          </Type>
          <Type var="p" sx={{ fs: 18, mt: 12, mb: [30, 30, 30, 10], mw: 390 }} center as="p">
            Discover more golden investment opportunities in our marketplace. An
            open space to showcase issued and transferable tokens and make
            investments among our trustworthy users.
          </Type>

        </Box>
      </Flex>
    </SBox>
  );
}

export default ComingSoon;
