import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Type } from "../../../ui";
import { dateFromNow, truncatWork } from "../../../utilities/functions";

const Styles = styled.div``;

function Live({ data }) {
  const navigate = useNavigate();

  function displayTimeLeft() {
    const days = Number(dateFromNow(data.closingTime * 1000)[0]);
    const hours = Number(dateFromNow(data.closingTime * 1000)[1]);

    if (days < 0 || hours < 0) return "0 days";
    if (days > 0) return days + " days";
    if (hours > 0) return hours + " hours";
    return "less than an hour";
  }

  return (
    <Styles>
      <Type as="em" sx={{ fs: 17, fStyle: "italic" }}>
        Time Left &bull; {displayTimeLeft()}
      </Type>
      <Type sx={{ h: [48, 48, 48, "auto"], mt: 5 }}>
        {truncatWork(data.description, 60)}
      </Type>
      <ul>
        <li>
          <span>Token Price:</span> <span>{data.tokenPrice || "N/A"}</span>
        </li>
        <li>
          <span>Tokens Left:</span> <span>{data.tokenLeft || "N/A"}</span>
        </li>
        <li>
          <span>Investors:</span> <span>{data.investorCount || "N/A"}</span>
        </li>
      </ul>
      <Button
        sx={{ mt: 30, mb: 10, w: "100%" }}
        size="small"
        onClick={() => navigate(`/company/${data.id}/live-investment`)}
      >
        Invest Now
      </Button>
    </Styles>
  );
}

export default Live;
