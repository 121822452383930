import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";
import { useCompanies } from "../../views/company/provider";
import { useEvents } from "../../views/events/provider";

function useSubscribe(data) {
  const [type, setType] = useState(null);

  const navigation = useNavigate();

  const { user } = useAuth();

  const {
    state: { subscribed: sub },
    isSubscribed,
    subscribe,
  } = useCompanies();

  const {
    state: { subscribed: eventSub },
    isSubscribed: eventSubscribed,
    subscribe: eventSubscribe,
  } = useEvents();

  useEffect(() => {
    if (data.hasOwnProperty("companyName")) setType("company");
  }, [data]);

  useEffect(() => {
    if(!user) return;
    if (type === "company" && user) isSubscribed(data.id);
    else eventSubscribed(data.id);
  }, [type, data, isSubscribed, user, eventSubscribed]);

  const handleSub = () => {
    if (!user) navigation("/login");
    if (type === "company") subscribe(data.id, sub.includes(data.id) ? 0 : 1);
    else eventSubscribe(data.id);
  };

  return {
    handleSub,
    sub: type === 'company' ? sub.includes(data.id) : eventSub.includes(data.id),
  };
}

export default useSubscribe;
