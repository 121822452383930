import { emptyStringToNull, parseDate } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "firstName",
    type: "text",
    label: "First Name",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "lastName",
    type: "text",
    label: "Last Name",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "email",
    type: "text",
    label: "Email address",
    inputProps: {
      readOnly: true,
    }
  },
  {
    name: "mobile",
    type: "text",
    label: "Mobile Number",
    inputProps: {
      readOnly: true,
    }
  },
  {
    name: "countryID",
    type: "number",
    resourceName: "countries",
    data: [],
    mapData: { value: "id", label: "country" },
    label: "Country of Residence",
    required: true,
    validationType: "number",
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "dateOfBirth",
    type: "date",
    required: "required",
    label: "Date of Birth",
    validationType: "date",
    inputProps: {
      placeholder: "yyyy-mm-dd"
    },
    validations: [
      {
        type: "required",
        params: ["This field is required"],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [parseDate],
      },
    ],
  },
];

export default fields;
