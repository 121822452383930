import {
  Box,
  Button,
  Checkbox,
  InputText,
  Link,
  Select,
  Type,
} from "../../ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../services/auth";
import { useEffect, useState } from "react";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import useQuery from "../../utilities/hooks/useQuery";

const schema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  countryID: Yup.number()
    .nullable()
    .required("Country is required")
    .transform(emptyStringToNull),
  email: Yup.string().email().required(),
  terms: Yup.boolean().oneOf([true], "You must accept the terms"),
  password: Yup.string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function RegisterForm({ onSuccess }) {
  const query = useQuery();
  const [error, setError] = useState(null);

  const [countries, setCountries] = useState([]);
  const { addAccount, getCountries } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (query.get("email")) setValue("email", query.get("email"));
  }, [query, setValue]);

  useEffect(() => {
    (async () => {
      setCountries(await getCountries());
    })();
  }, [getCountries]);

  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "USER_ALREADY_EXISTS")
        setError("Email already exsist.");
    } else {
      setError(e.message);
    }
  };

  const onSubmit = async (values) => {
    console.log(values)
    try {
      delete values.terms;
      delete values.confirmPassword;
      await addAccount(values);
      localStorage.setItem("registerData", JSON.stringify({ ...values }));
      onSuccess(values);
      reset();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) handleError(e);
      console.log("e", e);
    }
  };

  return (
    <Box
      sx={{ w: [620, 620, 620, "100%"], px: 30 }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {error && (
        <Type sx={{ c: "red", ta: "center", mt: -10, mb: 40 }}>{error}</Type>
      )}
      <InputText
        placeholder="First Name"
        error={!!errors.firstName}
        msg={errors.firstName?.message}
        inputProps={{ ...register("firstName"), autoFocus: true }}
      />
      <InputText
        placeholder="Last Name"
        error={!!errors.lastName}
        msg={errors.lastName?.message}
        inputProps={{ ...register("lastName") }}
      />
      {Boolean(countries.length) && (
        <Select
          style={{ width: "100%", marginBottom: "20px" }}
          name="Country"
          data={countries}
          error={!!errors.countryID}
          msg={errors.countryID?.message}
          onChange={(data) => {
            setValue("countryID", data.value);
          }}
          mapData={{ label: "country", value: "id" }}
          inputProps={{ ...register("countryID") }}
        />
      )}
      <InputText
        error={!!errors.email}
        msg={errors.email?.message}
        placeholder="Email"
        inputProps={{ ...register("email") }}
      />
      <InputText
        placeholder="Password"
        error={!!errors.password}
        msg={errors.password?.message}
        inputProps={{ ...register("password") }}
        type="password"
      />
      <InputText
        error={!!errors.confirmPassword}
        msg={errors.confirmPassword?.message}
        placeholder="Confirm Password"
        inputProps={{ ...register("confirmPassword") }}
        type="password"
      />
      <Checkbox
        style={{ marginLeft: "15px" }}
        inputProps={{ ...register("terms") }}
        id="agree"
        error={!!errors.terms}
        msg={errors.terms?.message}
        label={
          <span>
            By creating this account, you agree to{" "}
            <Link to="/terms">Terms of Use</Link> and{" "}
            <Link to="/privacy">Privacy</Link> Policy
          </span>
        }
      />
      <Button
        type="submit"
        size="large"
        bg="secondary"
        style={{ margin: "40px auto 40px" }}
        loading={isSubmitting}
      >
        Register
      </Button>
      <Type sx={{ ta: "center", fw: 300, mt: 30 }}>
        Already a member? <Link to="/login">Login</Link> here
      </Type>
    </Box>
  );
}

export default RegisterForm;
