import { useCallback, useEffect } from "react";
import useMetamask from "./useMetamask";
import Web3 from "web3";
import { useState } from "react";
import {
  CROWD_SALE_ABI,
  TETHER_ABI,
  TOKEN_ABI,
  EXCHANE_ABI,
} from "../../data/ABI";
import { useProfile } from "../../views/profile/provider";
import { useAuth } from "../../services/auth";
import { useCompanies } from "../../views/company/provider";
import { useUtils } from "../../services/utilsProvider";

function useWeb3() {
  const [crowdsale, setCrowdsale] = useState(null);
  const [token, setToken] = useState(null);
  const [tether, setTether] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // To be reviewed
  // usemetamask provider is probably not needed
  const { provider } = useMetamask();

  const { user } = useAuth();
  const { notify } = useUtils();

  const {
    state: { selected: profile },
    fetchOne: fetchProfile,
  } = useProfile();

  const {
    state: { financialInfo },
  } = useCompanies();

  const {
    tokens: {
      blockchainData: { tetherTokenAddress },
    },
  } = user || { tokens: { blockchainData: {} } };

  // useEffect(() => {
  //   if (financialInfo || !companyId) return;
  //   fetchFinancialInfo(companyId);
  //   return () => dispatch({ type: "set_financial_info", payload: null });
  // }, [financialInfo, fetchFinancialInfo, companyId, dispatch]);

  useEffect(() => {
    if (!financialInfo) return;
    const web3 = new Web3(window.ethereum);

    setCrowdsale(
      new web3.eth.Contract(CROWD_SALE_ABI, financialInfo?.crowdsaleAddress)
    );
    setToken(new web3.eth.Contract(TOKEN_ABI, financialInfo?.tokenAddress));
    setTether(new web3.eth.Contract(TETHER_ABI, tetherTokenAddress));
    setExchange(
      new web3.eth.Contract(EXCHANE_ABI, financialInfo?.exchangeAddress)
    );

    setIsInitialized(true);
  }, [financialInfo, tetherTokenAddress, provider, user]);

  useEffect(() => {
    if (profile) return;
    fetchProfile(user?.user?.id);
  }, [fetchProfile, profile, user?.user?.id]);

  const getTokenBalance = useCallback(async () => {
    if (!isInitialized) return -1;
    if (!profile?.walletAddress) {
      console.log("please connect to metamask first.");
      return -2;
    }
    return token.methods.balanceOf(profile.walletAddress).call();
  }, [isInitialized, profile, token]);

  const getUsdtBalance = useCallback(async () => {
    if (!isInitialized) return -1;
    if (!profile?.walletAddress) {
      console.log("please connect to metamask first.");
      return -2;
    }
    return tether.methods.balanceOf(profile.walletAddress).call();
  }, [isInitialized, profile, tether]);

  const buyToken = async (amount, tokens) => {
    if (!isInitialized) return;
    try {
      // approve crowdsale to spend usdt amount
      await tether.methods
        .approve(financialInfo?.crowdsaleAddress, amount)
        .send({
          from: profile.walletAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
      // buy token
      return crowdsale.methods.buyTokens(tokens).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      notify("An error has occured: " + e.message, "alert");
      console.log("error in BuyToken()", e);
      throw new Error(e.message);
    }
  };

  const buyTokensCash = async (buyerAddress, tokensAmount) => {
    if (!isInitialized) return;
    try {
      // buy token
      return crowdsale.methods.buyTokensCash(buyerAddress, tokensAmount).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      notify("An error has occured: " + e.message, "alert");
      console.log("error in BuyToken()", e);
      // throw new Error(e.message);
    }
  };

  const stake = async (amount) => {
    if (!isInitialized) return;
    // if (!profile?.walletAddress) {
    //   console.log("please connect to metamask first.");
    //   return -2;
    // }
    try {
      await token.methods.stake(amount).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      console.log("error in stake", e);
      throw new Error(e);
    }
  };

  const unstake = async (amount) => {
    if (!isInitialized) return;
    // if (!profile?.walletAddress) {
    //   console.log("please connect to metamask first.");
    //   return -2;
    // }
    try {
      await token.methods.unstake(amount).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      console.log("error in stake", e);
      throw new Error(e);
    }
  };

  const claim = async () => {
    return new Promise(async (resolve, reject) => {
      if (!isInitialized) reject("Unable to initialize the contract.");
      try {
        await token.methods.claim().send({
          from: profile.walletAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
        resolve();
      } catch (e) {
        console.log("error in stake", e);
        // throw new Error(e);
        reject(e);
      }
    });
  };

  const placeOrder = async (amount, price) => {
    if (!isInitialized) return;
    try {
      await token.methods.approve(financialInfo?.exchangeAddress, amount).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      await exchange.methods.placeOrder(amount, price).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      console.log("error in stake", e);
      throw new Error(e);
    }
  };

  async function buyOrder(orderId, amount, usdt) {
    if (!isInitialized) return;
    try {
      await tether.methods.approve(financialInfo?.exchangeAddress, usdt).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      await exchange.methods.buyOrder(orderId, amount).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      console.log("error in stake", e);
      throw new Error(e);
    }
  }

  async function removeOrder(orderId) {
    if (!isInitialized) return;
    try {
      await exchange.methods.removeOrder(orderId).send({
        from: profile.walletAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    } catch (e) {
      console.log("error in stake", e);
      throw new Error(e);
    }
  }

  return {
    getTokenBalance,
    buyToken,
    buyTokensCash,
    getUsdtBalance,
    stake,
    unstake,
    claim,
    placeOrder,
    buyOrder,
    removeOrder
  };
}

export default useWeb3;
