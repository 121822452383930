/* global BigInt */
import { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../../services/auth";
import { useUtils } from "../../../services/utilsProvider";
import { Box, Button, Flex, Icon, InputText, Link, Type } from "../../../ui";
import { delay, enforceMinMax } from "../../../utilities/functions";
import useMetamask from "../../../utilities/hooks/useMetamask";
import useWeb3 from "../../../utilities/hooks/useWeb3";
import { useCompanies } from "../provider";

const InvestBox = styled(Box)`
  text-align: center;
  background: rgb(102, 205, 245);
  background: linear-gradient(
    180deg,
    rgba(78, 191, 97, 1) 0%,
    rgba(173, 217, 185, .8) 100%
  );
  border-radius: ${(p) => p.theme.borderRadius}px;
  padding: 60px 40px;
  position: relative;
  button {
    margin: 20px auto;
  }
  .quantity {
    margin: 40px 0;
    font-size: 30px;
    font-weight: bold;
    span {
      background: ${(p) => p.theme.color.white};
      border-radius: 100px;
      width: 40px;
      height: 40px;
      color: ${(p) => p.theme.color.bgPrimary};
      font-size: 25px;
      font-weight: 600;
      line-height: 0;
      cursor: pointer;
      user-select: none;
      &:hover {
        background: ${(p) => p.theme.color.gray50};
      }
      &:active {
        transform: scale(0.95);
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .note {
    font-size: 16px;
    text-align: left;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 75%;
    span {
      width: 34px;
      height: 34px;
      background-color: ${(p) => p.theme.color.yellow};
      color: ${(p) => p.theme.color.white};
      border-radius: 100px;
      line-height: 0;
    }
  }
`;

function Stake({ financialInfo, isUnstake = false }) {
  const minInv = 1;
  const limit = isUnstake
    ? Number(financialInfo?.stakedBalance)
    : Number(financialInfo?.tokenBalance);

  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(minInv);

  const { stake, unstake } = useWeb3();
  const { connectMetamask } = useMetamask();
  const { user } = useAuth();

  const {
    state: { balance, usdt },
  } = useCompanies();

  const { notify } = useUtils();

  const checkAmount = (v) => {
    if (v < minInv) {
      notify(`Minimum tokens quantity is ${minInv} tokens`, "alert");
      return false;
    }
    if (v > limit) {
      notify(`Total tokens cannot exceed ${limit} tokens`, "alert");
      return false;
    }
    return true;
  };

  const handleQuantity = (type) => {
    let q = quantity;
    if (type === "dec") {
      if (q > minInv) q--;
      else {
        notify(`Minimum quantity is ${minInv} tokens`, "alert");
        q = 1;
      }
    } else {
      if (q < limit) q++;
      else {
        notify(`Total tokens cannot exceed ${limit} tokens`, "alert");
        q = limit;
      }
    }
    setQuantity(q);
  };

  const op = isUnstake ? unstake : stake;

  const handleStake = async () => {
    if (!checkAmount(Number(quantity))) return;
    try {
      setLoading(true);
      await connectMetamask();
      await op(BigInt(quantity) * BigInt(Math.pow(10, 6)));
      let timer = 4;
      notify(
        `${quantity} tokens has been successfully transfered. This page will reload in ${timer} seconds`
      );
      setTimeout(() => {
        window.location.reload();
      }, timer * 1000);
    } catch (e) {
      notify("an error has accured please try again later.", "alert");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <InvestBox>
      <Type var="h3" as="h3" sx={{ fw: "400" }}>
        Token Balance
      </Type>
      <Type var="h2" as="h2" sx={{c: "black"}}>
        {financialInfo.tokenBalance}
      </Type>
      <Flex className="quantity" gap={60}>
        <div>
          <Flex
            as="span"
            className={`btn ${quantity === minInv ? "disabled" : ""}`}
            onClick={() => handleQuantity("dec")}
          >
            -
          </Flex>
        </div>
        <InputText
          style={{ width: 120, minWidth: "52px", marginBottom: 0 }}
          size="small"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          inputProps={{
            onKeyUp: (e) => delay(enforceMinMax, 500)(e, setQuantity),
            min: minInv,
            max: limit,
          }}
        />
        <div>
          <Flex as="span" onClick={() => handleQuantity("inc")}>
            +
          </Flex>
        </div>
      </Flex>
      <Button
        loading={loading}
        onClick={handleStake}
        disabled={!user.user.isVerified || usdt === -2 || balance === -2}
      >
        {isUnstake ? "Unstake" : "Stake"} Now
      </Button>
      {(usdt === -2 || balance === -2) && (
        <Type as="div" sx={{ c: "red", fw: "700", mb: 15 }}>
          To invest, you need to be connected to metamask first.
        </Type>
      )}
      {!user.user.isVerified && (
        <Type as="div" sx={{ c: "red", fw: "700", pb: [10, 10, 10, 40] }}>
          You must verify identity before investing.{" "}
          <Link gap={6} to="/verify-identity">
            <Type sx={{ c: "primary", fs: 16, fw: 600 }} as="span">
              Scan ID
            </Type>{" "}
            <Icon name="arrowRight" c="primary" w={15} />
          </Link>
        </Type>
      )}

      <Type var="p" as="p" sx={{ mt: 30 }}>
        Go back to <Link to="/dashboard">Dashboard</Link>
      </Type>
    </InvestBox>
  );
}

export default Stake;
