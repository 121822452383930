import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

const NavControl = styled.span`
  cursor: pointer;
  &:first-child {
    padding-right: 20px;
  }
`;

function Nav({ nav, handleNext, handlePrev }) {
  const {next, prev, ...props} = nav;
  return (
    <Wrap className="slider-nav" {...props}>
      <NavControl style={{cursor: 'pointer'}} onClick={handlePrev}>{prev}</NavControl>
      <NavControl style={{cursor: 'pointer'}} onClick={handleNext}>{next}</NavControl>
    </Wrap>
  );
}

export default Nav;
