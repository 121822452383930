import { useRef } from "react";
import styled from "styled-components";
import { Flex, InputText } from "../ui";

const SFlex = styled(Flex)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

function InputPincode({ pincode, setPincode }) {
  const itemsRef = useRef([]);

  return (
    <SFlex gap={20}>
      {new Array(6).fill("").map((_, i) => (
        <InputText
          key={i}
          type="number"
          value={pincode[i]}
          onClick={() => itemsRef.current[i].select() }
          onChange={(e) => {
            setPincode((pincode) => {
              let newPCode = [...pincode];
              if (e.target.value.length === 6 && i === 0)
                newPCode = e.target.value.split("").map((n) => Number(n));
              else newPCode[i] = e.target.value.toString().split("")[0];
              return newPCode;
            });
            if (i < itemsRef.current.length - 1) {
              itemsRef.current[i + 1].focus();
              itemsRef.current[i + 1].select();
            }
          }}
          inputProps={{
            autoFocus: !i,
            ref: (el) => {
              itemsRef.current[i] = el;
            },
            style: {
              textAlign: "center",
              padding: 0,
              maxWidth: 80
            }
          }}
        />
      ))}
    </SFlex>
  );
}

export default InputPincode;
