import { useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import { useUtils } from "../../services/utilsProvider";
import ForgotPassForm from "./ForgotPassForm";

function ForgotPass() {
  const navigate = useNavigate();
  const {notify} = useUtils();

  const handleSuccess = () => {
    navigate("/reset-password");
    notify("A pincode has been sent to your email.");
  };

  return (
    <LayoutMasked
      title="Forgot your Password?"
      subtitle="Please Enter your Email Address to Reset your Password"
      onClose={() => navigate("/login")}
    >
      <ForgotPassForm onSuccess={handleSuccess} />
    </LayoutMasked>
  );
}

export default ForgotPass;
