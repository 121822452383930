import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import { useAuth } from "../../services/auth";
import LoginForm from "./LoginForm";

function Login() {
  const navigate = useNavigate();
  let location = useLocation();
  const { user, isVerified } = useAuth();

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (isVerified()) navigate(from, { replace: true });
  }, [isVerified, from, navigate]);

  const handleSuccess = () => {
    navigate(from, { replace: true });
  };

  return (
    <LayoutMasked
      title={`Welcome Back ${user ? user?.firstName : ""}`}
      onClose={() => navigate("/")}
    >
      <LoginForm onSuccess={handleSuccess} />
    </LayoutMasked>
  );
}

export default Login;
