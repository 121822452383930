import { Box, Flex, Type } from "../../../ui";
import ProgressBar from "../../../ui/ProgressBar";
import useMedia from "../../../utilities/hooks/useMedia";

function TokenLeft({ maxToken = 1, totalSold = 0, ...props }) {
  const media = useMedia();
  return (
    <Box sx={{ mw: 1150, px: 30 }} center {...props}>
      <ProgressBar total={maxToken} progress={totalSold} />
      <Flex jc="space-between">
        <Type sx={{mt: 10}}>
          {parseFloat((100 * totalSold) / maxToken).toFixed(2)}% {(media === 'sm' || media === 'xs') && <br />} Token Purchased
        </Type>
        <Type sx={{mt: 10}}>
          {parseFloat(((maxToken - totalSold) * 100) / maxToken).toFixed(2)}% {(media === 'sm' || media === 'xs') && <br />}
          Tokens Left
        </Type>
      </Flex>
    </Box>
  );
}

export default TokenLeft;
