import styled, { css } from "styled-components";
import { Card, Flex, Icon } from "..";
import Dropdown from "../core/Dropdown";

const OptionsCard = styled(Card)`
  margin: 0;
  padding: 0;
  overflow: hidden;
  ${(props) =>
    props.light &&
    css`
      box-shadow: none;
      font-size: 14px;
    `}
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${(props) => props.theme.color.white};
      font-weight: 600;
      padding: 15px 20px;
      border-bottom: ${(props) => props.theme.color.gray600} 1px solid;
      svg {
        margin-right: 8px;
        fill: ${(props) => props.theme.color.white};
      }
      &.delete {
        color: ${(props) => props.theme.color.red};
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
      &:hover {
        background-color: ${(props) => props.theme.color.primary};
        color: white;
        svg {
          fill: white;
        }
        &.delete {
          background-color: ${(props) => props.theme.color.red};
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const buildOption = function (data, opt) {
  return <span key={opt.name}>{opt.render(data)}</span>;
};

function MoreOptions({ data, moreOptions }) {
  const renderOptions = (data, opts) => {
    if (!data) return null;
    const list = opts.map((opt) => buildOption(data, opt));
    return list;
  };

  // if (Object.keys(moreOptions).length <= 2)
  //   return <Flex light></Flex>;

  return (
    <Flex gap={20} jc="flex-end">
      {renderOptions(data, moreOptions.slice(0, 2))}
      {Boolean(moreOptions.length > 2) && (
        <Dropdown
          content={
            <OptionsCard>
              {renderOptions(data, moreOptions.slice(2, moreOptions.length))}
            </OptionsCard>
          }
        >
          <span>
            <Icon
              name="ellipsisVertical"
              cricle
              c="black"
              hover="primary"
              w={10}
            />
          </span>
        </Dropdown>
      )}
    </Flex>
  );
}

export default MoreOptions;
