import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "../ui";
import Close from "../ui/Close";
import Type from "../ui/Type";
import Logo from "./header/Logo";
import sphere from "../assets/blured-sphere.png";
import cube from "../assets/pink-cube.png";
import cylinder from "../assets/pink-cylinder.png";

const Wrap = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 250px;
    height: 250px;
    background-image: url(${sphere});
    background-size: cover;
    top: 50px;
    left: 100px;
    @media only screen and (max-width: 992px) {
      content: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100px;
    height: 100px;
    background-image: url(${cube});
    background-size: contain;
    background-repeat: no-repeat;
    top: 180px;
    right: 100px;
    @media only screen and (max-width: 992px) {
      content: none;
    }
  }
  > div {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 140px;
      height: 140px;
      background-image: url(${cylinder});
      background-size: contain;
      background-repeat: no-repeat;
      bottom: -50px;
      right: 24%;
      @media only screen and (max-width: 992px) {
        content: none;
      }
    }
  }
`;

function LayoutMasked({ children, title, subtitle = " ", noLogo, onClose }) {
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) onClose();
    else navigate("/");
  };

  return (
    <Wrap>
      <Flex dir="column" sx={{ mh: "100vh", py: 100 }}>
        {/* <Close onClick={handleClose} /> */}
        {!noLogo && <Logo onClick={() => navigate("/")}>Stitvest</Logo>}
        <Type var="h2" sx={{ mt: 30, mb: 0, px: [0, 0, 0, 30] }}>
          {title}
        </Type>
        <Type sx={{ mb: 70, mx: [0, 0, 0, 30] }} var="p" as="p">{subtitle}</Type>
        {children}
      </Flex>
    </Wrap>
  );
}

export default LayoutMasked;
