import styled from "styled-components";
import { Flex, Type } from "../../../ui";
import { dateFromNow } from "../../../utilities/functions";

const SFlex = styled(Flex)`
  width: 300px;
  font-size: 16px;
  text-align: left;
  span {
    width: 34px;
    height: 34px;
    background-color: ${(props) => props.theme.color.yellow};
    color: ${(props) => props.theme.color.white};
    border-radius: 100px;
    line-height: 0;
  }
`;

const Note = ({ data }) => {
  function displayTimeLeft() {
    const days = Number(dateFromNow(data?.closingTime * 1000)[0]);
    const hours = Number(dateFromNow(data?.closingTime * 1000)[1]);

    if (days < 0 || hours < 0) {
      return "0 days";
    }

    if (days > 0) {
      return days + " days";
    }
    if (hours > 0) {
      return hours + " hours";
    }
    return "less than an hour";
  }

  return (
    <SFlex gap={10} className="note">
      <div>
        <Flex as="span">!</Flex>
      </div>
      <Type sx={{ fs: 16, lh: 20 }}>
        Tokens are not transferable until the investment is closed after{" "}
        {displayTimeLeft()}.
      </Type>
    </SFlex>
  );
};

export default Note;
