import styled from "styled-components";
import { Box, Type } from "../ui";
import Footer from "./Footer";
import Header from "./Header";
import cube from '../assets/slide-1.png'

const SBox = styled(Box)`
  background-color: #ddc6ed;
  background: url(${cube}) no-repeat border-box, radial-gradient(
        circle,
        rgba(221, 198, 237, 1) 0%,
        rgba(221, 221, 221, 0) 70%
      )
      no-repeat border-box,
    radial-gradient(
        circle,
        rgba(221, 198, 237, 1) 0%,
        rgba(221, 221, 221, 0) 70%
      )
      no-repeat border-box;
  background-position: 110% -160px, -200px -250px, 125% -550px;
  background-size: auto, 800px 800px, 1200px 1200px;
  background-origin: padding-box, padding-box, padding-box;
`;

function Layout({ children, title = false, breadcrumbs=[], footerGap }) {
  return (
    <div style={{ overflow: "hidden" }}>
      <Header />
      <main>
        {title && (
          <SBox sx={{ ta: "center", py: 48 }}>
            <Type sx={{ fs: 42, lh: 42, fw: 400, text: "uppercase", mb: 0 }}>
              {title}
            </Type>
            <Type sx={{ text: "uppercase" }}>{breadcrumbs.map((t, i) => <span key={i}>{t} {Boolean(i < breadcrumbs.length - 1) && "›"} </span>)}</Type>
          </SBox>
        )}
        {children}
      </main>
      <Footer gap={footerGap} />
    </div>
  );
}

export default Layout;
