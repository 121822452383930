import { useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import { useAuth } from "../../services/auth";
import { useUtils } from "../../services/utilsProvider";
import ChangePassForm from "./ChangePassForm";

function ResetPass() {
  const navigate = useNavigate();
  const { notify } = useUtils();
  const { signout } = useAuth();

  const handleSuccess = () => {
    signout(false);
    navigate("/login");
    notify("Password has been changed successfully.");
  };

  return (
    <LayoutMasked
      title="Change Password"
      subtitle="Create a new password of at least 6 characters"
      onClose={() => navigate(-1)}
    >
      <ChangePassForm onSuccess={handleSuccess} />
    </LayoutMasked>
  );
}

export default ResetPass;
