import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Type } from "../../../ui";

const SBox = styled(Box)`
  padding: 40px 120px;
  background-color: ${(props) => props.theme.color.white};
  margin-top: -100px;
  transform: translate(0, -82px);
  z-index: 9999;
  position: relative;
  display: inline-block;
  border-radius: 0 3px 0 0;
  @media only screen and (max-width: 768px) {
    padding: 40px 30px;
    margin-top: 0;
    transform: translate(0, 0);
    margin-bottom: 30px;
  }
  .timebox {
    display: inline-block;
    @media only screen and (max-width: 768px) {
      margin-right: 30px;
      p.digits {
        font-size: 24px;
      }
    }
  }
  .timebox:not(:last-child) {
    position: relative;
    margin-right: 60px;
    &::before {
      content: "";
      position: absolute;
      top: 12px;
      right: -33px;
      height: 25px;
      border-left: ${(props) => props.theme.color.black} 3px solid;
    }
    @media only screen and (max-width: 768px) {
      margin-right: 20px;
      &::before {
        right: -15px;
      }
    }
  }
`;

const TimeDigit = ({ n, name }) => {
  return (
    <Box sx={{ ta: "center" }} className="timebox">
      <Type className="digits" var="h2" sx={{ lh: 50 }}>
        {n || 0}
      </Type>
      <Type as="span">{name}</Type>
    </Box>
  );
};

function Timer({ endDate = new Date(), ...props }) {
  const [digits, setDigits] = useState([0, 0, 0, 0]);

  useEffect(() => {
    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = new Date(endDate).getTime() - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in an element with id="demo"
      //   document.getElementById("demo").innerHTML =
      //     days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      setDigits([days, hours, minutes, seconds]);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setDigits([0, 0, 0, 0]);
      }
    }, 1000);
    return () => clearInterval(x);
  }, [endDate]);

  return (
    <SBox {...props}>
      <div>
        <Type var="titleWisp" sx={{ mb: 10 }}>
          Ends in
        </Type>
        <Flex jc="flex-start">
          <TimeDigit n={digits[0]} name="days" />
          <TimeDigit n={digits[1]} name="hours" />
          <TimeDigit n={digits[2]} name="minutes" />
          <TimeDigit n={digits[3]} name="seconds" />
        </Flex>
      </div>
    </SBox>
  );
}

export default Timer;
