import { emptyStringToNull } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "companyName",
    type: "text",
    label: "Company Name",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "website",
    type: "text",
    label: "Website",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "categoryID",
    type: "number",
    resourceName: "categories",
    data: [],
    mapData: { value: "id", label: "categoryName" },
    label: "Category",
    required: true,
    validationType: "number",
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "description",
    type: "textarea",
    label: "Description",
    validationType: "string",
  },
];

export default fields;
