import { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../layout/Layout";
import { useAuth } from "../../services/auth";
import {
  Box,
  Divider,
  Flex,
  Type,
  Loading,
  Link,
  TableContainer,
  Card,
  Button,
} from "../../ui";
import { useCompanies } from "./provider";
import heroImg from "../../assets/earn-hero.jpg";
import BgImg from "../../ui/BgImg";
import img1 from "../../assets/shape-color-3.png";
import img2 from "../../assets/shape-color-4.png";
import ConditionalWrapper from "../../components/ConditionalWrapper";
import { useNavigate } from "react-router-dom";

const cols = [
  { name: "companyName", label: "Company Name" },
  {
    name: "minStakingPeriod",
    label: "Min. Staking Period",
    append: () => " Days",
    render: ({ minStakingPeriod }) => {
      const minutes = minStakingPeriod / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      return (
        <Type var="p" as="p">
          {days < 0
            ? days.toLocaleString("en-US") + " Days"
            : hours < 0
            ? hours.toLocaleString("en-US") + " Hours"
            : minutes.toLocaleString("en-US") + " Minutes"}
        </Type>
      );
    },
  },
  {
    name: "rewardRate",
    label: "Staking Rate",
    render: ({ rewardRate }) => (
      <Type var="p" as="p" sx={{ c: "primary", fw: "700" }}>
        {rewardRate} %
      </Type>
    ),
  },
];

const SBox = styled(Box)`
  width: 100%;
  height: calc(100vh - 114px);
  max-height: 900px;
  background-image: url(${heroImg});
  background-size: cover;
  position: relative;
  z-index: 100;
  div {
    height: 100%;
  }
  .content {
    color: ${(p) => p.theme.color.white};
    max-width: 450px;
    padding-top: 10%;
    padding: 10% 30px 0 30px;
    @media only screen and (max-width: 1350px) {
      padding-left: 10%;
      max-width: calc(450px + 10%);
    }
  }
`;

function Slide() {
  const { user } = useAuth();
  return (
    <SBox>
      <Box w={1350} center sx={{ h: "100%" }}>
        <Flex jc="flex-start" ai="flex-start" className="content">
          <span>
            <Type var="titleWisp" as="p" sx={{ fs: 21, c: "white" }}>
              STITVEST STAKING
            </Type>
            <Type
              var="h1"
              as="h1"
              sx={{ fs: 36, mt: 10, fw: "700", lh: 50, c: "white" }}
            >
              Increased Income.
              <br /> Secure Earnings
            </Type>
            <Type
              var="subTitle"
              as="h4"
              sx={{
                fs: 22,
                fstyle: "italic",
                fw: 400,
                mt: 15,
                mb: 30,
                c: "white",
              }}
            >
              Earn rewards based on the ratio of your staked tokens to the total
              staked amount.
            </Type>
            {!user && (
              <Flex jc="flex-start" gap={10}>
                <Button bg="white" variant="outlined" size="small" to="/login">
                  Sign In
                </Button>
                -
                <Button
                  sx={{ c: "black" }}
                  bg="white"
                  size="small"
                  to="/register"
                >
                  Register
                </Button>
              </Flex>
            )}
          </span>
        </Flex>
      </Box>
    </SBox>
  );
}

function InvestTable({ data, title, cols, status, options = [] }) {
  return (
    Boolean(data) && (
      <Card padding="0" bg="gray50" rad={20} elevation={-1}>
        <Type var="h3" as="h3" sx={{ px: 30, py: 20 }}>
          {title}
        </Type>
        <Divider border="gray300" h={1} />
        <TableContainer
          empty={data?.length === -1}
          status={status}
          aria-label={`${title} table`}
          cols={cols}
          data={data}
          moreOptions={options}
        />
      </Card>
    )
  );
}

function Rewards() {
  const [data, setData] = useState([]);
  const { user } = useAuth([]);

  const navigate = useNavigate();

  const {
    state: { status },
    fetchRewards,
  } = useCompanies();

  useEffect(() => {
    (async () => {
      const res = await fetchRewards();
      setData(res);
    })();
  }, [fetchRewards]);

  const options = [
    {
      name: "stack",
      render: ({ id }) => (
        <Button
          onClick={() => navigate(`/company/rewards/${id}`)}
          variant="small"
          sx={{ c: "white", bgc: "secondary" }}
        >
          Stake Now
        </Button>
      ),
    },
  ];

  const displayData = function () {
    if (!user) return null;
    // return (
    //   <Type sx={{ ta: "center", lh: 26, my: 100 }}>
    //     Welcome to Stitvest Staking. <br /> Please{" "}
    //     <Link to="/login?from=/company/rewards">Sign In</Link> first to get
    //     started. <br />
    //     Don't have an account? <Link to="/register">Register Here</Link>.
    //   </Type>
    // );
    else if (status === "fetching")
      return (
        <Flex center sx={{ my: 100 }}>
          <Loading />
        </Flex>
      );
    else if (status === "idle" && !data.length)
      return (
        <Box sx={{ ta: "center", my: 100, mxw: 540, mx: "auto" }}>
          <Type>
            Looks like you haven't made any investment yet!{" "}
            <Link to="/company/opportunities">Invest Now</Link>
          </Type>
          <Type sx={{ my: 20 }}>Or,</Type>
          <Type>
            Your token purchase is recent and the opportunity in which you
            invested in is still open.
          </Type>
          <Type var="p" as="p" sx={{ mt: 30 }}>
            Visit <Link to="/dashboard">Dashboard</Link>
          </Type>
        </Box>
      );

    return (
      <>
        <Type
          as="h3"
          sx={{ fs: 26, c: "black", fw: 600, mb: 12, ta: "center" }}
        >
          Check
        </Type>
        <Type var="h1" as="h2" sx={{ mb: 40, ta: "center" }}>
          My Tokens
        </Type>
        {data.map((company, i) => (
          <Box key={company.id} sx={{ mb: 40 }}>
            <InvestTable
              data={[company]}
              title={`My Token ${i + 1}`}
              cols={cols}
              status={status}
              options={options}
            />
          </Box>
        ))}
      </>
    );
  };

  return (
    <Layout footerGap="0">
      <Slide />
      {user && (
        <ConditionalWrapper
          condition={user && data.length && status === "idle"}
          wrapper={(children) => (
            // <BgSlops sx={{ pt: 40, pb: 120 }}>
            <BgImg
              img1={img1}
              img2={img2}
              pos1={{
                w: 600,
                h: 600,
                t: 200,
                r: [-250, -250, -300, -350],
              }}
              pos2={{
                w: 600,
                h: 600,
                t: 80,
                l: [0, 0, -50, -100],
              }}
            >
              {children}
            </BgImg>
            // </BgSlops>
          )}
        >
          <Box w={1350} center sx={{ my: 40, px: 40 }}>
            {displayData()}
          </Box>
        </ConditionalWrapper>
      )}
    </Layout>
  );
}

export default Rewards;
