import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputPincode from "../../components/InputPincode";
import { Box, Button, InputText } from "../../ui";
import { useAuth } from "../../services/auth";

const schema = Yup.object().shape({
  newPassword: Yup.string().required('required.').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  confirmPass: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});

function ResetPassForm({ onSuccess }) {
  const [pincode, setPincode] = useState(new Array(6).fill(""));
  // const [error, setError] = useState(null);
  const { changePass } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      await changePass({ newPassword: values.newPassword, pinCode: pincode.join('') });
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      // handleError(e);
      console.log("e", e);
    }
  };

  return (
    <Box sx={{ w: [740, 740, 740, '100%'], mx: 30 }} as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputPincode {...{ pincode, setPincode }} />
      <InputText
        placeholder="New Password"
        type="password"
        error={!!errors.newPassword}
        msg={errors.newPassword?.message}
        inputProps={{
          ...register("newPassword"),
          autoFocus: true,
        }}
      />
      <InputText
        placeholder="Confirm New Password"
        type="password"
        error={!!errors.confirmPass}
        msg={errors.confirmPass?.message}
        inputProps={{
          ...register("confirmPass"),
        }}
      />
      <Button
        className="large"
        type="submit"
        loading={isSubmitting}
        style={{ width: "100%" }}
      >
        Reset
      </Button>
    </Box>
  );
}

export default ResetPassForm;
