import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import CompanyProvider from "../views/company/provider";
import ProfileProvider from "../views/profile/provider";
import EventProvider from "../views/events/provider";
import DashboardProvider from "../views/dashboard/provider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OrdersBookProvider from "views/orders-book/provider";

export default function Providers({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <UtilsProvider>
          <AuthProvider>
            <CompanyProvider>
              <ProfileProvider>
                <EventProvider>
                  <DashboardProvider>
                    <OrdersBookProvider>{children}</OrdersBookProvider>
                  </DashboardProvider>
                </EventProvider>
              </ProfileProvider>
            </CompanyProvider>
          </AuthProvider>
        </UtilsProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
