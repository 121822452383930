import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCompanies } from "views/company/provider";
import styled from "styled-components";
import { useAuth } from "services/auth";
import { useProfile } from "views/profile/provider";
import { Box, Button, Flex, Grid, Icon, Link, Slider, Tooltip, Type } from "ui";
import Layout from "layout/Layout";
import theme from "theme";
import Overview from "views/company/companyView/Overview";
import OrdersBookList from "views/orders-book/OrdersBookList";
import BuyToken from "./components/BuyToken";
import SellToken from "./components/SellToken";
import Web3 from "web3";
import { useOrdersBook } from "views/orders-book/provider";
import useWeb3 from "utilities/hooks/useWeb3";
import Dropdown from "ui/core/Dropdown";
import CheckNetwork from "components/CheckNetwork";

const SliderBg = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.color.gray200};
  img {
    width: 100%;
    max-height: calc(100vw * 0.7);
    height: calc(100vh - 120px);
    object-fit: cover;
    object-position: center;
  }
`;

function View() {
  const [sell, setSell] = useState(false);
  const { user } = useAuth();
  const { id } = useParams();

  let [searchParams] = useSearchParams();

  const { getTokenBalance } = useWeb3();

  const {
    state: { selected, financialInfo, balance },
    fetchOne,
    fetchFinancialInfo,
    dispatch: companyDispatch,
  } = useCompanies();

  const {
    state: { selected: orderBook },
    dispatch: orderBookDispatch,
  } = useOrdersBook();

  const {
    state: { selected: profile },
    fetchOne: fetchProfile,
  } = useProfile();

  useEffect(() => {
    (async () => {
      const res = await getTokenBalance();
      companyDispatch({ type: "set_balance", payload: res });
    })();
  }, [companyDispatch, getTokenBalance]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (profile) return;
    fetchProfile(Number(user.user.id));
  }, [fetchProfile, profile, user]);

  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id]);

  useEffect(() => {
    if (!profile) return;
    fetchFinancialInfo(id, profile.walletAddress);
  }, [fetchFinancialInfo, id, user, profile]);

  const Slide = ({ data, slideRef }) => {
    return (
      <SliderBg sx={{ w: "100%" }} ref={slideRef}>
        <img src={`${user.tokens.awsData.s3PublicURI}${data}`} alt="" />
      </SliderBg>
    );
  };

  if (!financialInfo) return null;

  const DefaultSlide = ({ data, slideRef }) => {
    return (
      <Box
        sx={{ w: "100%", bgc: "gray800", ta: "center", py: 120 }}
        ref={slideRef}
      >
        <Type var="h1" as="h1" sx={{ c: "white" }}>
          {data.companyName}
        </Type>
        <Type sx={{ c: "white", mt: 15 }}>
          <Link to="/dashboard">Dashboard</Link>
          <Box as="span" sx={{ px: 8 }}>
            <Icon name="arrowRight" w={10} c="white" />
          </Box>
          <Type as="strong" sx={{ fw: 600, c: "white" }}>
            {searchParams.get("unstake") ? "Unstake" : "Stake"}
          </Type>
        </Type>
      </Box>
    );
  };

  return (
    <Layout>
      {Boolean(financialInfo) && (
        <>
          {financialInfo.imageSlider?.length ? (
            <Slider data={financialInfo.imageSlider} Slide={Slide} />
          ) : (
            <Slider data={[financialInfo]} Slide={DefaultSlide} />
          )}
        </>
      )}

      {/* <BgSlops sx={{ pt: 20, pb: 100 }}> */}
      {/* <BgImg
        img1={img1}
        img2={img2}
        pos1={{
          w: 600,
          h: 600,
          t: 80,
          r: [-140, -140, -300, -350],
        }}
        pos2={{
          w: 600,
          h: 600,
          t: -80,
          l: [70, 70, -50, -100],
        }}
      > */}

      <CheckNetwork />

      <Flex
        jc="flex-end"
        gap={30}
        sx={{ my: 60, w: theme.pageWidth, mx: "auto", px: 30 }}
      >
        <Type sx={{ fs: 26 }}>Token Balance:</Type>{" "}
        <Type sx={{ fs: 38, fw: 700 }}>
          {Web3.utils.fromWei((balance < 0 ? 0 : balance).toString(), "mwei")}
          <Dropdown
            click={false}
            content={<Tooltip>{financialInfo.tokenName}</Tooltip>}
          >
            <Type
              sx={{
                fs: 20,
                fw: 700,
                text: "uppercase",
                pl: 3,
                textDeco: "underline dotted #999",
              }}
              as="span"
            >
              {financialInfo.tokenSymbol}
            </Type>
          </Dropdown>
        </Type>
        <Button
          size="large"
          bg="secondary"
          disabled={balance <= 0}
          onClick={() => {
            setSell(true);
            orderBookDispatch({ type: "set_selected", payload: null });
          }}
        >
          Place Order
        </Button>
      </Flex>
      <Grid
        cols={["3fr 2fr", "3fr 2fr", "3fr 2fr", "100%"]}
        gap={40}
        w={theme.pageWidth}
        center
        sx={{ mb: 80, mt: 60, mx: "auto", px: 30 }}
      >
        <OrdersBookList id={financialInfo.companyID} />

        {Boolean(orderBook) && (
          <BuyToken order={orderBook} financialInfo={financialInfo} />
        )}
        {Boolean(sell) && !Boolean(orderBook) && (
          <SellToken
            tokenBalance={Web3.utils.fromWei(balance.toString(), "mwei")}
            financialInfo={financialInfo}
          />
        )}
      </Grid>

      {Boolean(financialInfo) && (
        <Overview company={{ ...financialInfo, ...selected }} />
      )}
      {/* </BgImg> */}
      {/* </BgSlops> */}
    </Layout>
  );
}

export default View;
