import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Box, Button, Divider, Flex, Link, Type } from "../../../ui";
import List from "../../../ui/List";
import { truncatWork } from "../../../utilities/functions";

const SFlex = styled(Flex)`
  margin-bottom: 120px;
  max-width: 1350px;
  margin: 0 auto;
  padding: 30px;
  margin-bottom: 60px;
  button {
    margin: 20px 0;
  }
`;

const InfoBox = styled(Box)`
  background-color: ${(props) => props.theme.color.gray50};
  border-radius: ${(props) => props.theme.borderRadius}px;
  padding: 20px 0;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

function Overview({ company }) {
  const maxDesc = 140;
  const descLen = company?.description?.length;
  const [desc, setDesc] = useState(company?.description);

  useEffect(() => {
    setDesc(truncatWork(company?.description, maxDesc));
  }, [company]);

  return (
    <SFlex
      jc={["space-between"]}
      dir={["row", "row", "row", "column"]}
      gap={40}
    >
      <Box sx={{ mw: 400 }}>
        <Type var="display" as="h1" sx={{ mb: 20 }}>
          Company <br /> Overview
        </Type>
        <Type var="p" sx={{ mw: 900 }} center>
          {desc}
        </Type>
        {descLen > maxDesc && (
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              desc.length <= maxDesc
                ? setDesc(company?.description)
                : setDesc(truncatWork(company?.description, maxDesc))
            }
          >
            {desc?.length <= maxDesc ? "Read More" : "read Less"}
          </Button>
        )}
      </Box>
      <InfoBox sx={{ w: [700, 700, 600, 320] }}>
        <Box sx={{ px: 20 }}>
          <Type sx={{ m: 0, pt: 10, fs: 32, fw: "700" }}>
            {company?.companyName}
          </Type>
          <Type var="p" sx={{ m: 0, py: 10, fstyle: "italic", c: "gray800" }}>
            Overview
          </Type>
        </Box>
        <Divider border="gray300" h={1} />
        <Box sx={{ px: 20, py: 10 }}>
          <List as="ul">
            <li>
              <span>Industry</span>
              <span>{company?.industry}</span>
            </li>
            <li>
              <span>Geographic Market</span>
              <span>{company?.geographicMarket}</span>
            </li>
            <li>
              <span>Headquarter</span>
              <span>{company?.headquarter}</span>
            </li>
            <li>
              <span>Website</span>
              <span>
                <Link as="a" href={'https://'+company.website} target="_blank" rel="noreferrer">
                  {company.website}
                </Link>
              </span>
            </li>
          </List>
        </Box>
      </InfoBox>
    </SFlex>
  );
}

export default Overview;
