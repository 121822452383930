import { Grid, Type } from "../../../ui";
import FutureItem from "./FutureItem";

const data = [
  {
    icon: "search",
    title: "Transparency",
    desc: (
      <>
        Our platform provides a transparent and open ecosystem for fundraising
        activities, allowing investors to track their investments and
        entrepreneurs to showcase their ideas.
      </>
    ),
  },
  {
    icon: "gears",
    title: "Efficiency",
    desc: (
      <>
        With our smart contract technology and automated transaction execution,
        fundraising activities are more efficient and cost-effective than
        traditional methods
      </>
    ),
  },
  {
    icon: "globe",
    title: "Global Reach",
    desc: (
      <>
        Our platform provides a global network of investors and entrepreneurs,
        allowing you to connect with people from all over the world and access a
        diverse range of investment opportunities.
      </>
    ),
  },
  {
    icon: "lightBulb",
    title: "Innovation",
    desc: (
      <>
        Our platform is at the forefront of technological innovation, providing
        entrepreneurs and investors with cutting-edge tools and resources to
        achieve their fundraising goals.
      </>
    ),
  },
  {
    icon: "shield",
    title: "Security",
    desc: (
      <>
        Our platform leverages advanced blockchain technology to create a secure
        and tamper-proof ecosystem for fundraising activities, providing
        entrepreneurs and investors with peace of mind.
      </>
    ),
  },
  {
    icon: "clipboardList",
    title: "Compliance",
    desc: (
      <>
        We comply with all relevant regulatory requirements, providing a safe
        and compliant environment for fundraising activities.
      </>
    ),
  },
  {
    icon: "universalAccess",
    title: "Accessibility",
    desc: (
      <>
        Our platform is designed to be accessible to everyone, regardless of
        their location or financial status, democratizing access to capital and
        investment opportunities.
      </>
    ),
  },
  {
    icon: "cloudSun",
    title: "Accountability",
    desc: (
      <>
        Our platform provides an accountability framework, with transparent
        reporting and audit trails, to ensure that all parties involved in the
        fundraising process are held accountable for their actions.
      </>
    ),
  },
  {
    icon: "seedling",
    title: "Scalability",
    desc: (
      <>
        Our platform is designed to be scalable, allowing it to grow and adapt
        to the changing needs of entrepreneurs and investors, providing a
        long-term solution for fundraising activities.
      </>
    ),
  },
  {
    icon: "peopleGroup",
    title: "Community",
    desc: (
      <>
        Our platform fosters a community of entrepreneurs, investors, and
        supporters, providing a collaborative and supportive ecosystem that
        promotes innovation and growth.
      </>
    ),
  },
];

function Future() {
  return (
    <div className="test">
      <Type var="h2" as="h2" sx={{ ta: "center", mb: 10 }}>
        The Future of Fundraising
      </Type>
      <Type
        var="subTitle"
        as="h3"
        sx={{ ta: "center", mxw: 700, mx: "auto", c: "gray600" }}
      >
        Experience the future of fundraising with our secure and efficient
        blockchain-based platform for entrepreneurs and investors
      </Type>
      <Grid cols={["1fr 1fr 1fr 1fr", "1fr 1fr 1fr", "1fr 1fr", "1fr"]} gap={40} sx={{ mt: 60 }}>
        {data.map((item, i) => (
          <FutureItem key={i} {...item} />
        ))}
      </Grid>
    </div>
  );
}

export default Future;
