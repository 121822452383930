import styled from "styled-components";
import Box from "./Box";
import { buildRule } from "./core/stylesBuilder";

/* Usage:
<BgImg
    img1={img1}
    img2={img2}
    pos1={{
    t: [1,2,3,4], <-responsive
    b: 0,
    }}
    pos2={{
    w: "100%",
    h: "100%",
    t: 0,
    b: 0,
    }}
> */
// prettier-ignore
export default styled(Box)`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: block;
    background-image: url(${(p) => p.img1});
    background-size: contain;
    background-repeat: no-repeat;
    ${p => buildRule('width', Boolean(typeof p.pos1?.w !== "undefined") ? p.pos1.w : null, '400px')}
    ${p => buildRule('height', Boolean(typeof p.pos1?.h !== "undefined") ? p.pos1.h : null, "400px")}
    ${p => buildRule('top', Boolean(typeof p.pos1?.t !== "undefined") ? p.pos1.t : null, 'auto')}
    ${p => buildRule('bottom', Boolean(typeof p.pos1?.b !== "undefined") ? p.pos1.b : null, 'auto')}
    ${p => buildRule('left', Boolean(typeof p.pos1?.l !== "undefined") ? p.pos1.l : null, 'auto')}
    ${p => buildRule('right', Boolean(typeof p.pos1?.r !== "undefined") ? p.pos1.r : null, 'auto')}
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    background-image: url(${(p) => p.img2});
    background-size: contain;
    background-repeat: no-repeat;
    ${p => buildRule('width', Boolean(typeof p.pos2?.w !== "undefined") ? p.pos2.w : null, "400px")}
    ${p => buildRule('height', Boolean(typeof p.pos2?.h !== "undefined") ? p.pos2.h : null, "400px")}
    ${p => buildRule('top', Boolean(typeof p.pos2?.t !== "undefined") ? p.pos2.t : null, 'auto')}
    ${p => buildRule('bottom', Boolean(typeof p.pos2?.b !== "undefined") ? p.pos2.b : null, 'auto')}
    ${p => buildRule('left', Boolean(typeof p.pos2?.l !== "undefined") ? p.pos2.l : null, 'auto')}
    ${p => buildRule('right', Boolean(typeof p.pos2?.r !== "undefined") ? p.pos2.r : null, 'auto')}
    z-index: -1;
  }
`;
