import { useEffect, useState } from "react";

function useMedia() {
  const [media, setMedia] = useState("desk");

  function checkWidth() {
    if (window.innerWidth <= 520) {
      setMedia("xs");
    } else if (window.innerWidth <= 768) {
      setMedia("sm");
    } else if (window.innerWidth <= 992) {
      setMedia("md");
    } else if (window.innerWidth <= 1300) {
      setMedia("lg");
    } else if (window.innerWidth > 1300) {
      setMedia("xlg");
    }
  }

  useEffect(() => {
    checkWidth();
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);

  return media;
}

export default useMedia;
