import styled, { css } from "styled-components";

export const inputStyles = css`
  outline: ${(p) => p.theme.color.gray300} 1px solid;
  background-color: ${(p) => p.theme.color.gray100};
  border-radius: ${(p) => (p.as === "textarea" ? "20px" : "9999px")};
  height: 52px;
  padding: 0 26px 0 26px;
  ${(p) => {
    if (p.size === "medium")
      return css`
        height: 48px;
        padding: 0 20px 0 20px;
      `;
    else if (p.size === "small")
      return css`
        height: 36px;
        padding: 0 20px 0 14px;
      `;
  }}
  ::placeholder {
    color: ${(p) => p.theme.color.gray400};
  }
  width: 100%;
  border: none;
  font-size: 16px;
  color: ${(p) => p.theme.color.gray800};
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  &:focus,
  &:active {
    outline: ${(p) => p.theme.color.secondary} 1px solid;
    background-color: ${(p) => p.theme.color.gray50};
  }
  &:read-only,
  &:disabled {
    outline: ${(p) => p.theme.color.gray200} 1px solid;
    color: ${(p) => p.theme.color.gray500};
    background-color: ${(p) => p.theme.color.gray50};
  }
  ${(p) =>
    p.type === "textarea" &&
    css`
      height: 120px;
      padding: 26px;
    `}
`;

const Input = styled.input`
  ${inputStyles}
`;

export default Input;
