import { Box, Card, Flex, Type } from "../../../ui";
import Close from "../../../ui/Close";
import { currency } from "../../../utilities/functions";
import useMedia from "../../../utilities/hooks/useMedia";
import img from "../../../assets/invest-welcome.svg";

function Banner({ user, totalInvest, handleClose }) {
  const media = useMedia();

  return (
    <Card>
      <Flex jc="space-between" sx={{ h: 130 }}>
        <Box sx={{ mr: [0, 0, 0, 30] }}>
          <Type var="p" as="p" sx={{ fs: 25, fw: 600, c: "gray700", mb: 10 }}>
            Welcome Back {user?.user?.firstName} {user?.user?.lastName}!
          </Type>
          <Type var="p" as="p" sx={{ mb: -8 }}>
            Since your last login, there were:
          </Type>
          <ul>
            <li>
              <Type as="span" sx={{ fw: 600 }}>
                Your Total Investment is{" "}
              </Type>
              <Type as="span" sx={{ c: "primary", fw: 600 }}>
                USDT {totalInvest ? currency(totalInvest, null, null) : 0}
              </Type>
            </li>
          </ul>
        </Box>
        {media !== "xs" && media !== "sm" && (
          <Box sx={{ mr: 100, mt: -10 }}>
            <img src={img} width="140" alt="" />
          </Box>
        )}
      </Flex>
      <Close size="medium" onClick={handleClose} x={20} y={20} />
    </Card>
  );
}

export default Banner;
