import { useState } from "react";
import { useAuth } from "../../../services/auth";
import { useUtils } from "../../../services/utilsProvider";
import { Box, Button, Flex, Icon, Loading, Type } from "../../../ui";
import Dropdown from "../../../ui/core/Dropdown";
import Tooltip from "../../../ui/Tooltip";
import useMetamask from "../../../utilities/hooks/useMetamask";
import { useProfile } from "../provider";

const DisplayWallet = () => {
  const [loading, setLoading] = useState(false);

  const { notify } = useUtils();

  const { user } = useAuth();
  const {
    state: { selected: profile },
    setWallet,
    resetWallet,
  } = useProfile();

  const { connectToChain } = useMetamask({
    setWallet,
    network: user.tokens.blockchainData,
  });

  const handleResetWallet = async () => {
    try {
      setLoading(true);
      await resetWallet();
    } catch (e) {
      if(e.errors.message === "WALLET_CAN_NOT_BE_DELETED") {
        notify("Wallet with a total investment more than 0 can not be deleted", 'alert')
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Flex
        sx={{ mt: [0, 0, 0, 10] }}
        jc="flex-start"
        ai={["center", "center", "center", "flex-start"]}
        dir={["row", "row", "row", "column"]}
        gap={["20px", "20px", "20px", "10px"]}
      >
        <Type
          var="p"
          sx={{ fw: 300 }}
          onClick={() => {
            navigator.clipboard.writeText(profile.walletAddress);
            notify("Content copied");
          }}
        >
          <Type as="span" sx={{ mr: 6 }}>
            {profile.walletAddress}
          </Type>
          <span className="h-sm">
            <Dropdown
              click={false}
              placement="bottom"
              content={<Tooltip>Copy Address</Tooltip>}
            >
              <Icon name="copy" w={14} c="gray400" />
            </Dropdown>
          </span>
        </Type>

        <Dropdown
          click={false}
          placement="bottom"
          content={<Tooltip>Reset Wallet</Tooltip>}
        >
          {loading ? (
            <Loading size={24} />
          ) : (
            <Icon
              onClick={handleResetWallet}
              hover
              circle
              name="trash"
              w={10}
              c="red"
            />
          )}
        </Dropdown>
      </Flex>
      {window.ethereum.networkVersion !==
        user.tokens.blockchainData.chainID && (
        <Box>
          <Type var="p" sx={{ my: 10 }}>
            You must be connected to {user.tokens.blockchainData.networkName} network
          </Type>
          <Button className="small" variant="outlined" onClick={connectToChain}>
            Connect
          </Button>
        </Box>
      )}
    </>
  );
};

export default DisplayWallet;
