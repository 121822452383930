import { useEffect, useState } from "react";
import { Divider, Flex } from "../../ui";
import Box from "../../ui/Box";
import Carousel from "../../ui/carousel/Carousel";
import Type from "../../ui/Type";
import useMedia from "../../utilities/hooks/useMedia";
import CompanyBox from "../company/companyBox/CompanyBox";
import { useCompanies } from "../company/provider";
import cylinderShape from "../../assets/cylinder-shape.png";
import upcommingShape from "../../assets/upcomming-shape.png";
import shapeColor from "../../assets/shape-color-3.png";
import styled from "styled-components";

const SBox = styled(Box)`
  position: relative;
  padding-bottom: 180px;
  margin-top: 140px;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 300px;
    height: 300px;
    background-image: url(${cylinderShape});
    background-size: contain;
    background-repeat: no-repeat;
    top: -180px;
    left: 80px;
    /* z-index: -50; */
    @media only screen and (max-width: 1200px) {
      left: auto;
      right: -40px;
    }
    @media only screen and (max-width: 992px) {
      width: 250px;
      height: 250px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 370px;
    height: 370px;
    background-image: url(${upcommingShape});
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -140px;
    right: 40px;
    z-index: 10;
    /* z-index: -50; */
    @media only screen and (max-width: 768px) {
      width: 320px;
      height: 320px;
      bottom: -160px;
    }
  }
  > div {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 400px;
      height: 400px;
      background-image: url(${shapeColor});
      background-size: contain;
      background-repeat: no-repeat;
      bottom: -60px;
      left: 0;
      z-index: -50;
    }
  }
  .carousel {
    .items {
      .item:nth-child(even) {
        > div {
          margin-top: 140px;
        }
      }
    }
  }
`;

const Next = () => {
  return <Type sx={{ fs: 36, m: 0 }}>&rarr;</Type>;
};

const Prev = () => {
  return <Type sx={{ fs: 36, m: 0 }}>&larr;</Type>;
};

function UpcomingOportunities() {
  const media = useMedia();

  const [data, setData] = useState([]);

  const { fetchList } = useCompanies();

  useEffect(() => {
    (async () => {
      const data = await fetchList({ statusID: 2 });
      setData(data);
    })();
  }, [fetchList]);

  if (!data.length) return;

  return (
    <SBox className="opportunity" center sx={{ mw: 1370 }}>
      <div>
        <Divider h={100} border="gray300" />
        <Flex
          ai="flex-start"
          dir={["row", "column-reverse", "column-reverse", "column-reverse"]}
        >
          <Box
            sx={{
              mw: [
                [1400, 870],
                [950, "none"],
              ],
              w:
                media !== "xs" && media !== "sm" && media !== "md"
                  ? "auto"
                  : "100%",
              ta: "center",
            }}
            center
          >
            {data.length ? (
              <>
                {media !== "xs" && media !== "sm" ? (
                  <Carousel
                    options={{
                      slides: [...data],
                      slide: CompanyBox,
                      itemW: 360,
                      gap: 0,
                      nav: {
                        style: {
                          textAlign: "center",
                          marginTop: "0px",
                        },
                        next: <Next />,
                        prev: <Prev />,
                      },
                    }}
                  />
                ) : (
                  <>
                    {data.map((item) => (
                      <Box key={item.id} sx={{ mb: 40 }}>
                        <CompanyBox data={item} />
                      </Box>
                    ))}
                  </>
                )}
              </>
            ) : (
              "No Upcoming Opportunities are available at this moment."
            )}
          </Box>
          <Box>
            <Type var="display" sx={{ mt: 10, mb: 15, px: 30 }}>
              Upcoming <br /> Opportunities
            </Type>
            {/* <Type var="p" sx={{ mb: 12, mw: 480 }} center as="p">
              The sky was cloudless and of a deep dark blue. The spectacle
              before us was indeed sublime. Then came the night of the first
              falling star.
            </Type> */}
          </Box>
        </Flex>
      </div>
    </SBox>
  );
}

export default UpcomingOportunities;
