import styled, { css } from "styled-components";
import Label from "./Label";
import { DatePicker } from "@mui/x-date-pickers";

const Wrapper = styled.div`
  margin-bottom: 40px;
  ${(props) =>
    props.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrap {
    position: relative;
    > span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      fill: ${(props) => props.theme.color.dark};
      line-height: 0;
    }
  }

  p.msg {
    color: ${(props) => props.theme.color.grayText};
    padding-left: 9px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &.error p.msg {
    color: ${(props) => props.theme.color.red};
  }
  .MuiInputBase-root {
    border-radius: 999px;
    height: 48px;
    background-color: ${(p) => p.theme.color.gray50};
    fieldset {
      border-color: ${(p) => p.theme.color.gray300};
      &:active,
      &:focus {
        border-color: ${(p) => p.theme.color.primary};
      }
    }
    &.Mui-focused {
      fieldset {
        border-color: ${(p) => p.theme.color.secondary};
      }
    }
  }
`;

function InputDate({
  label,
  name,
  value,
  id,
  placeholder = "",
  type,
  msg = null,
  error = false,
  icon = null,
  size = "large",
  onDateChange,
  pickerProps,
  ...props
}) {
  const onChange = (date) => {
    onDateChange(date);
  };
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      {label && <Label>{label}</Label>}
      <div className="inputWrap">
        <DatePicker
          value={value}
          onChange={onChange}
          {...pickerProps}
          slotProps={{
            textField: { size: "medium", style: { width: "100%" } },
          }}
        />
      </div>
      {msg && <p className="msg">{msg}</p>}
    </Wrapper>
  );
}

export default InputDate;
