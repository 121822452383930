import { useEffect, useState } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { useCallback } from "react";
import { useUtils } from "../../services/utilsProvider";

function useMetamask(props) {
  const [provider, setProvider] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const { notify } = useUtils();

  useEffect(() => {
    (async () => {
      const provider = await detectEthereumProvider();
      if (provider) {
        if (provider !== window.ethereum) {
          console.error("Do you have multiple wallets installed?");
        }
        setProvider(provider);
        provider.on("chainChanged", handleChainChanged);
      }
    })();
  });

  // For now, 'eth_accounts' will continue to always return an array
  const handleAccountsChanged = useCallback(
    async (accounts) => {
      if (accounts?.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log("Please connect to MetaMask.");
      } else if (accounts?.[0] !== currentAccount) {
        setCurrentAccount(accounts?.[0]);
        if (props?.setWallet) await props.setWallet(accounts[0]);
        setConnecting(false);
      }
    },
    [currentAccount, props]
  );

  const connectMetamask = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (provider) {
        setConnecting(true);
        provider
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            handleAccountsChanged(account);
            resolve();
          })
          .catch((err) => {
            notify(
              "Error while trying to connect to Metamask: " + err.message,
              "alert"
            );
            console.error(err);
            reject(err);
          });

        // Note that this event is emitted on page load.
        // If the array of accounts is non-empty, you're already
        // connected.
        provider.on("accountsChanged", handleAccountsChanged);
      } else {
        console.log("Please install MetaMask!");
        reject("Please install MetaMask!");
      }
    });
  }, [handleAccountsChanged, provider, notify]);

  function handleChainChanged(_chainId) {
    // We recommend reloading the page, unless you must do otherwise
    window.location.reload();
  }

  const connectToChain = async () => {
    // const chainId = 137; // Polygon Mainnet
    const chainId = Number(props.network.chainID); // Test network

    if (window.ethereum.networkVersion !== chainId) {
      if (provider) {
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x" + chainId.toString(16) }],
          });
        } catch (err) {
          console.log(err.code)
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902 || err.code === -32603) {
            await provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: props.network.networkName,
                  chainId: "0x" + chainId.toString(16),
                  nativeCurrency: {
                    name: props.network.native_currency,
                    decimals: Number(props.network.native_currency_decimals),
                    symbol: props.network.native_currency_symbol,
                  },
                  rpcUrls: [props.network.rpc_url],
                  blockExplorerUrls: [props.network.block_explorer_url],
                },
              ],
            });
          }
        }
      }
    }
  };

  return {
    provider,
    connectMetamask,
    connectToChain,
    connecting,
  };
}

export default useMetamask;
