import styled from "styled-components";
import { useAuth } from "../../../services/auth";
import { Box, Divider, Flex, Type } from "../../../ui";
import List from "../../../ui/List";

const InfoBox = styled(Box)`
  background-color: ${(props) => props.theme.color.gray50};
  border-radius: ${(props) => props.theme.borderRadius}px;
  padding: 20px 0;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.1);
  ul {
    padding: 0 40px;
    @media only screen and (max-width: 768px) {
      padding: 0 30px;
    }
    @media only screen and (max-width: 400px) {
      padding: 0 20px;
    }
  }
`;

function InfoRewards({ financialInfo, labels }) {
  const { user } = useAuth();

  function displayTimeLeft() {
    const minutes = financialInfo?.minStakingPeriod / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    return days < 0
      ? days.toLocaleString("en-US") + " Days"
      : hours < 0
      ? hours.toLocaleString("en-US") + " Hours"
      : minutes.toLocaleString("en-US") + " Minutes";
  }

  return (
    <InfoBox>
      <Flex
        jc="space-between"
        sx={{ px: 40 }}
        dir={["row", "row", "row", "column"]}
        gap={[0, 0, 0, "20px"]}
      >
        <Box>
          <Type
            var="h3"
            as="h3"
            sx={{ mb: 0, ta: ["left", "left", "left", "center"] }}
          >
            {financialInfo?.companyName}
          </Type>
          {/* <Type var="titleWisp" sx={{ mt: 8, fstyle: "italic" }}>
            Days left &bull;{" "}
            {dateFromNow(financialInfo?.closingTime * 1000)[0]}
          </Type> */}
        </Box>
      </Flex>
      <Divider border="gray300" />
      <List as="ul">
      <li>
          <span>Staked Balance</span>
          <span>{financialInfo?.stakedBalance || 0}</span>
        </li>
        {/* <li>
          <span>Tokens Balance</span>
          <span>{financialInfo?.tokenBalance || 0}</span>
        </li> */}
        <li className="primary">
          <span>Staking Rate</span>
          <span>{financialInfo?.rewardRate} %</span>
        </li>
        <li>
          <span>Min Staking Period</span>
          <span>{displayTimeLeft()}</span>
        </li>
        <li>
          <span>Blockchain Details</span>
          <span>{user?.tokens?.blockchainData?.networkName}</span>
        </li>
        <li className="primary">
          <span>Currencies</span>
          <span>Tether (USDT)</span>
        </li>
      </List>
    </InfoBox>
  );
}

export default InfoRewards;
