import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useDashboard() {
  return useContext(Context);
}

const initState = {
  liveInvestments: [],
  closedInvestments: [],
  marketplaceInvestments: [],
  earnings: [],
  totalInvest: null,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_live_investments":
      return { ...state, liveInvestments: [...action.payload] };
    case "set_closed_investments":
      return { ...state, closedInvestments: [...action.payload] };
    case "set_marketplace_investments":
      return { ...state, marketplaceInvestments: [...action.payload] };
    case "set_total_invest":
      return { ...state, totalInvest: action.payload };
    case "set_earnings":
      return { ...state, earnings: action.payload };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function DashboardProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchTotalInvest = useCallback(
    (address, force = false) => {
      return new Promise(async (resolve, reject) => {
        if (typeof state.totalInvest === "number" && !force) {
          resolve(state.totalInvest);
          return;
        }
        dispatch({ type: "status", payload: `fetching total invest` });

        try {
          const resData = await req(
            `statistics/investment/total/${address}`,
            null,
            {},
            true
          );
          dispatch({
            type: "set_total_invest",
            payload: resData.data.totalInvestment,
          });
          resolve(resData.data.totalInvestment);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, state.totalInvest]
  );

  const fetchLiveInvestments = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching wallets` });

        try {
          const resData = await req(
            `statistics/investment/live/${address}`,
            null,
            {},
            true
          );
          dispatch({ type: "set_live_investments", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchEarnings = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching Earnings` });

        try {
          const resData = await req(
            `statistics/investment/staked/${address}`,
            null,
            {},
            true
          );
          dispatch({ type: "set_earnings", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchClosedInvestments = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching wallets` });

        try {
          const resData = await req(
            `statistics/investment/closed/${address}`,
            null,
            {},
            true
          );
          dispatch({ type: "set_closed_investments", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchMarketplaceInvestments = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching wallets` });

        try {
          const resData = await req(
            `statistics/investment/marketplace/${address}`,
            null,
            {},
            true
          );
          dispatch({
            type: "set_marketplace_investments",
            payload: resData.data,
          });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchLiveInvestments,
        fetchClosedInvestments,
        fetchTotalInvest,
        fetchEarnings,
        fetchMarketplaceInvestments,
      }}
    >
      {children}
    </Context.Provider>
  );
}
