import LayoutMasked from "../../../layout/LayoutMasked";
import VerifyEmailForm from "./VerifyEmailForm";

function VerifyEmail() {
  return (
    <LayoutMasked
      title="Verification Code"
      subtitle="Enter the code to verify and activate your account"
    >
      <VerifyEmailForm />
    </LayoutMasked>
  );
}

export default VerifyEmail;
