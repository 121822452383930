import { Box, Divider, Flex, Type } from "../../../../ui";
import { useState } from "react";

function Question({ q, a }) {
  const [open, setOpen] = useState(0);
  return (
    <Box>
      <Flex
        jc="space-between"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpen((o) => !o)}
      >
        <Type sx={{ mt: 25, fs: 18 }}>{q}</Type>
        <Flex
          sx={{
            sqr: 30,
            border: ["gray300", 1, "solid", "50%"],
            lh: 0,
          }}
        >
          +
        </Flex>
      </Flex>

      <Type
        sx={{
          c: "gray500",
          mr: 100,
          maxh: open ? 500 : 1,
          of: "hidden",
          tr: `max-height .2s ${open ? "ease-in" : "ease-out"}`,
        }}
        as="div"
      >
        {a}
      </Type>
      <Divider border="gray300" h={20} />
    </Box>
  );
}

export default Question;
