import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import fields from "./fields";
import { useCompanies } from "./provider";
import { Box, Button, InputText, Select, Type } from "../../ui";

const yupSchema = fields.reduce(createYupSchema, {});
const schema = Yup.object().shape(yupSchema);

function CompanyForm({ onSuccess }) {
  const [error, setError] = useState(null);

  const {
    state: { categories },
    create,
    fetchCategories,
  } = useCompanies();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const onSubmit = async (values) => {
    try {
      await create(values);
      onSuccess(values);
      reset();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors.message);
    //   else if (e.status === 409)
    //     setError({
    //       title: `The Name "${values.fullName}" is already in use for this company.`,
    //     });
      console.log("e", e);
    }
  };

  const onChange = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );

  const resources = {
    categories,
  };

  return (
    <Box sx={{p:[0,0,0,30], w: [700, 700, 700, 'auto']}} center as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <Type sx={{ c: "red", mb: 40, mt: 60 }}>{error}</Type>}

      {fields.map((f, i) => {
        if (f.viewOnly) return null;

        const inputProps = { ...f.inputProps };
        if (i === 0) inputProps.autoFocus = true;
        if (f.required) inputProps.required = true;

        if (f.data) {
          const data = f.resourceName ? resources[f.resourceName] : f.data;

          if (!data?.length) return null;

          return (
            <div key={i}>
              <Select
                style={{ width: "100%", marginBottom: "40px" }}
                name={f.label}
                data={data}
                mapData={f.mapData}
                required={f.required}
                defaultValue={getValues(f.name)}
                onChange={onChange}
                error={!!errors[f.name]}
                msg={errors[f.name]?.message || f.msg}
                inputProps={{
                  ...register(f.name),
                  "aria-label": f.label,
                  required: true,
                }}
              />
            </div>
          );
        } else
          return (
            <InputText
              key={i}
              placeholder={f.label}
              name={f.name}
              error={!!errors[f.name]}
              msg={errors[f.name]?.message}
              type={f.type}
              inputProps={{
                ...register(f.name),
                ...inputProps,
                "aria-label": f.label
              }}
            />
          );
      })}
      <Button
        type="submit"
        className="large"
        style={{ width: "100%", marginTop: "40px" }}
        loading={isSubmitting}
      >
        Submit Information
      </Button>
    </Box>
  );
}

export default CompanyForm;
