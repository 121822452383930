import { useCallback, useEffect, useState } from "react";
import Layout from "layout/Layout";
import { Box, Button, Card, Divider, Icon, TableContainer, Tooltip, Type } from "ui";
import { useDashboard } from "./provider";
import { useProfile } from "../profile/provider";
import { useAuth } from "services/auth";
import Banner from "./dashboard/Banner";
import Note from "./dashboard/Note";
import { useNavigate } from "react-router-dom";
import useWeb3 from "utilities/hooks/useWeb3";
import useMetamask from "utilities/hooks/useMetamask";
import { useCompanies } from "../company/provider";
import { useUtils } from "services/utilsProvider";
import Dropdown from "ui/core/Dropdown";

const liveCols = [
  { name: "companyName", label: "Company Name" },
  { name: "tokenBalance", label: "Number of tokens" },
  { name: "tokenPrice", fn: "currency", label: "Token Price" },
  { name: "tokenValue", fn: "currency", label: "Token Value" },
  {
    name: "note",
    label: " ",
    render: (data) => <Note data={data} />,
  },
];

const marketplaceCols = [
  { name: "companyName", label: "Company Name" },
  { name: "tokenAmount", label: "Tokens for sale" },
  { name: "timeStamp", fn: "datetime", label: "Release Date" },
  { name: "tokenPrice", fn: "currency", label: "Token Price" },
  { name: "tokenValue", fn: "currency", label: "Token Value" },
  // {
  //   name: "note",
  //   label: " ",
  //   render: (data) => <Note data={data} />,
  // },
];

const myWalletCols = [
  { name: "companyName", label: "Company Name" },
  { name: "tokenBalance", label: "Number of tokens" },
  { name: "tokenPrice", fn: "currency", label: "Token Price" },
  { name: "tokenValue", fn: "currency", label: "Token Value" },
];

const stakingCols = [
  { name: "companyName", label: "Company Name" },
  { name: "stakedTokens", label: "Staked Tokens" },
  { name: "stakingDate", fn: "datetime", label: "Staking Date" },
  {
    name: "minStakingPeriod",
    label: "Min. Staking Period",
    append: () => " Days",
    render: ({ minStakingPeriod }) => {
      const minutes = minStakingPeriod / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      return (
        <Type var="p" as="p">
          {days < 0
            ? days.toLocaleString("en-US") + " Days"
            : hours < 0
            ? hours.toLocaleString("en-US") + " Hours"
            : minutes.toLocaleString("en-US") + " Minutes"}
        </Type>
      );
    },
  },
  // { name: "rewardDate", label: "Rewarding Date", render: ({stakingDate, minStakingPeriod}) =>  {
  //   const minStakeDays = Number(minStakingPeriod) / 60 / 60 / 24;
  //   const daysStaked = Number(dateFromNow(stakingDate * 1000)[0]);
  //   const daysLeft = minStakeDays - daysStaked;
  //   return daysLeft + " Days";
  // }},
  { name: "tokenPrice", fn: "currency", label: "Token Price" },
  // { name: "tokenValue", fn: "currency", label: "Token Value" },
  { name: "totalRewards", fn: "currency", label: "Total Rewards" },
];

function Dashboard() {
  const [banner, setBanner] = useState(true);
  const [loading, setLoading] = useState(0);

  const navigate = useNavigate();
  const { claim } = useWeb3();
  const { connectMetamask } = useMetamask();
  const { fetchFinancialInfo } = useCompanies();
  const { notify } = useUtils();

  const myWalletOptions = [
    {
      name: "sell",
      render: ({id}) => (
        <Button
          // disabled={true}
          onClick={() => navigate(`/marketplace/company/${id}`)}
          title="Unavailable yet."
          bg="gray600"
          variant="outlined"
          size="small"
        >
          Sell Token
        </Button>
      ),
    },
    {
      name: "stake",
      render: ({ id }) => (
        <Button
          onClick={() => navigate(`/company/rewards/${id}`)}
          size="small"
          bg="secondary"
        >
          Stake
        </Button>
      ),
    },
  ];

  const stakingOptions = [
    {
      name: "redeem",
      render: ({ id, totalRewards }) => (
        <Button
          loading={loading === id}
          disabled={!Number(totalRewards)}
          onClick={async () => {
            try {
              setLoading(id);
              await connectMetamask();
              await claim();
              await fetchFinancialInfo(id);
              notify("Reward has been redeemed successfully");
            } catch (e) {
              notify("an error has accured please try again later.", "alert");
              console.log(e);
            } finally {
              setLoading(0);
            }
          }}
          size="small"
          variant="outlined"
        >
          Redeem
        </Button>
      ),
    },
    {
      name: "unstake",
      render: ({ id, stakedTokens }) => (
        <Button
          variant="outlined"
          size="small"
          disabled={!Number(stakedTokens)}
          onClick={() => navigate(`/company/rewards/${id}?unstake=1`)}
        >
          Unstake
        </Button>
      ),
    },
  ];

  const {
    state: {
      earnings,
      liveInvestments,
      closedInvestments,
      marketplaceInvestments,
      totalInvest,
      status,
    },
    fetchLiveInvestments,
    fetchClosedInvestments,
    fetchEarnings,
    fetchTotalInvest,
    fetchMarketplaceInvestments
  } = useDashboard();

  const { user } = useAuth();

  const {
    state: { selected: profile },
    fetchOne: fetchProfile,
  } = useProfile();

  useEffect(() => {
    if (profile) return;
    fetchProfile(user.user.id);
  }, [fetchProfile, profile, user.user.id]);

  const hasWallet = useCallback(() => {
    if (!profile?.walletAddress) {
      console.log("please connect to metamask first.");
      return false;
    }
    return true;
  }, [profile]);

  useEffect(() => {
    if (hasWallet()) fetchLiveInvestments(profile.walletAddress);
  }, [fetchLiveInvestments, profile, hasWallet]);

  useEffect(() => {
    if (hasWallet()) fetchClosedInvestments(profile.walletAddress);
  }, [fetchClosedInvestments, profile, hasWallet]);

  useEffect(() => {
    if (hasWallet()) fetchEarnings(profile.walletAddress);
  }, [fetchEarnings, profile, hasWallet]);

  useEffect(() => {
    if (hasWallet()) fetchTotalInvest(profile.walletAddress);
  }, [fetchTotalInvest, profile, hasWallet]);

  useEffect(() => {
    if (hasWallet()) fetchMarketplaceInvestments(profile.walletAddress);
  }, [fetchMarketplaceInvestments, profile, hasWallet]);


  const handleBannerClose = () => {
    setBanner(false);
  };

  function InvestTable({ resource, title, cols, options = [] }) {
    return (
      Boolean(resource.length) && (
        <Card padding="0" bg="gray50" elevation={-1}>
          <Type var="h3" as="h3" sx={{ px: 30, py: 20 }}>
            {title}
          </Type>
          <Divider border="gray300" h={1} />
          <TableContainer
            empty={resource?.length === -1}
            status={status}
            aria-label={`${title} table`}
            cols={cols}
            data={resource}
            moreOptions={options}
          />
        </Card>
      )
    );
  }

  return (
    <Layout>
      <Box w={1370} center sx={{ my: 60, mx: ["auto", "auto", 30, 30] }}>
        {banner && (
          <Banner
            handleClose={handleBannerClose}
            user={user}
            totalInvest={totalInvest}
          />
        )}

        {banner && <Divider h={60} />}

        <InvestTable
          resource={liveInvestments.map((t) => ({
            id: t.companyID,
            ...t,
          }))}
          title="Live"
          cols={liveCols}
        />
        {Boolean(liveInvestments.length) && <Divider h={40} />}
        <InvestTable
          resource={closedInvestments.map((t) => ({
            id: t.companyID,
            ...t,
          }))}
          title="My Wallet"
          cols={myWalletCols}
          options={myWalletOptions}
        />
        {Boolean(closedInvestments.length) && <Divider h={40} />}
        <InvestTable
          resource={earnings.map((t) => ({
            id: t.companyID,
            ...t,
          }))}
          title="Earnings"
          cols={stakingCols}
          options={stakingOptions}
        />
        {Boolean(marketplaceInvestments.length) && <Divider h={40} />}
        <InvestTable
          resource={marketplaceInvestments.map((t) => ({
            id: t.companyID,
            ...t,
          }))}
          title="Marketplace"
          cols={marketplaceCols}
          options={[{name: "viewDetails", render: (row) => <Dropdown click={false} delay={.4} content={<Tooltip size="small">View Details</Tooltip>}>
            <span>
              <Icon name="eye" c="secondary" hover="gray300" w={28} onClick={() => navigate(`/marketplace/company/${row.companyID}`)} />
            </span>
          </Dropdown>}]}
          // options={stakingOptions}
        />
      </Box>
    </Layout>
  );
}

export default Dashboard;
