import { useEffect } from "react";
import { Box, Button, Icon, Type } from "../../../ui";
import Carousel from "../../../ui/carousel/Carousel";
import { downloadFile } from "../../../utilities/functions";
import useImageLink from "../../../utilities/hooks/useImageLink";
import useMedia from "../../../utilities/hooks/useMedia";
import { useCompanies } from "../provider";

const Slide = ({ data }) => {
  const file = useImageLink(data.documentKey);

  return (
    <Box sx={{ ta: "center" }}>
      <Icon name="pdf" w="50" style={{ fill: "#e2574c" }} />
      <Type sx={{ my: 20 }}>{data.documentName}</Type>
      <Button
        onClick={() => downloadFile(file, data.documentName)}
        size="small"
        style={{ margin: "0 auto" }}
        iconName="download"
      >
        Download
      </Button>
    </Box>
  );
};

function Materials({ id, ...props }) {
  const media = useMedia();

  const {
    state: { docs },
    fetchDocs,
  } = useCompanies();

  useEffect(() => {
    fetchDocs(id);
  }, [fetchDocs, id]);

  const visibleSlides = () => {
    switch (media) {
      case "xs":
        return 1;
      case "sm":
        return 2;
      case "md":
        return 3;
      default:
        return 5;
    }
  };

  if (!docs.length) return;

  return (
    <Box
      sx={{
        my: 200,
        // w: [],
        ta: "center",
      }}
      center
      {...props}
    >
      <Type var="p" as="p" sx={{ fs: 20, mb: 6, fw: 600 }}>
        Stitvest
      </Type>
      <Type var="h1" as="h2" sx={{ mb: 20 }}>
        Materials
      </Type>
      <Type var="p" as="p" sx={{ mb: 60 }}>
        Terms and Information Investors Should Know
      </Type>
      <Carousel
        style={{ margin: "0 auto" }}
        options={{
          totalSlides: visibleSlides(),
          slides: docs,
          slide: Slide,
          itemW: 250,
          nav: {
            next: <Icon name="circleArrowRight" c="primary" w={32} />,
            prev: <Icon name="circleArrowLeft" c="primary" w={32} />,
          },
        }}
      />
    </Box>
  );
}

export default Materials;
