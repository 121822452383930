import styled from "styled-components";

export default styled.div`
    width: 100%;
    height: 20px;
    border-radius: 100px;
    background-color: ${props => props.theme.color.gray300};
    position: relative;
    overflow: hidden;
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: ${props => (props.progress * 100) / props.total}%;
        background-color: ${props => props.theme.color.secondary};
        border-radius: 100px;
        transition: width .3s cubic-bezier(0.4, 0, 1, 1);
    }
`;