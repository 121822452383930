/* global BigInt */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "services/auth";
import { useUtils } from "services/utilsProvider";
import { Box, Button, Divider, Flex, Icon, InputText, Link, Type } from "ui";
import {
  currency,
  delay,
  enforceMinMax,
  toThousand,
} from "utilities/functions";
import useMetamask from "utilities/hooks/useMetamask";
import useWeb3 from "utilities/hooks/useWeb3";
import { useCompanies } from "views/company/provider";
import Web3 from "web3";

const InvestBox = styled(Box)`
  /* text-align: center; */
  background: rgb(102, 205, 245);
  background: linear-gradient(
    180deg,
    rgba(78, 191, 97, 1) 0%,
    rgba(173, 217, 185, 0.8) 100%
  );
  border-radius: ${(p) => p.theme.borderRadius}px;
  /* padding: 60px 40px; */
  position: relative;
  button {
    margin: 20px auto;
  }
  .quantity {
    margin: 40px 0;
    font-size: 30px;
    font-weight: bold;
    span {
      background: ${(p) => p.theme.color.white};
      border-radius: 100px;
      width: 40px;
      height: 40px;
      color: ${(p) => p.theme.color.bgPrimary};
      font-size: 25px;
      font-weight: 600;
      line-height: 0;
      cursor: pointer;
      user-select: none;
      &:hover {
        background: ${(p) => p.theme.color.gray50};
      }
      &:active {
        transform: scale(0.95);
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  .note {
    font-size: 16px;
    text-align: left;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 75%;
    span {
      width: 34px;
      height: 34px;
      background-color: ${(p) => p.theme.color.yellow};
      color: ${(p) => p.theme.color.white};
      border-radius: 100px;
      line-height: 0;
    }
  }
`;

function BuyToken({ order, financialInfo }) {
  const { buyOrder } = useWeb3();
  const { connectMetamask } = useMetamask();
  const { user } = useAuth();

  const {
    state: { balance, usdt },
  } = useCompanies();

  const { notify } = useUtils();

  const minInv = 1;
  const limit = Web3.utils.fromWei((order?.amount || 0).toString(), "mwei");

  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(limit);

  useEffect(() => {
    console.log(limit);
    setQuantity(limit);
  }, [limit]);

  const checkAmount = (v) => {
    if (v < minInv) {
      notify(`Minimum tokens quantity is ${minInv} tokens`, "alert");
      return false;
    }
    if (v > limit) {
      notify(`Total tokens cannot exceed ${limit} tokens`, "alert");
      return false;
    }
    return true;
  };

  const handleQuantity = (type) => {
    let q = quantity;
    if (type === "dec") {
      if (q > minInv) q--;
      else {
        notify(`Minimum quantity is ${minInv} tokens`, "alert");
        q = 1;
      }
    } else {
      if (q < limit) q++;
      else {
        notify(`Total tokens cannot exceed ${limit} tokens`, "alert");
        q = limit;
      }
    }
    setQuantity(q);
  };

  const handleStake = async () => {
    if (!checkAmount(Number(quantity))) return;
    try {
      setLoading(true);
      await connectMetamask();
      const bQuantity = BigInt(quantity) * BigInt(Math.pow(10, 6));
      const bCost = BigInt(order.price * quantity * Math.pow(10, 6));
      const bRate =
        (bCost * BigInt(financialInfo.stitvestRate * 100)) / BigInt(10000);
      const finalPrice = bCost + bRate;
      await buyOrder(order.id, bQuantity, finalPrice);
      let timer = 4;
      notify(
        `${quantity} tokens has been successfully transfered. This page will reload in ${timer} seconds`
      );
      setTimeout(() => {
        window.location.reload();
      }, timer * 1000);
    } catch (e) {
      notify("an error has accured please try again later.", "alert");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <InvestBox>
      <Type var="h4" as="h4" sx={{ c: "white", px: 20, pt: 20, pb: 10 }}>
        Buy Tokens
      </Type>

      <Divider border="gray300" />

      <Type sx={{ fs: 30, fw: 500, ta: "center", mb: 40, mt: 60 }}>
        Token Price
      </Type>
      <Type sx={{ fs: 38, fw: 700, ta: "center", mb: 50 }}>
        {currency(order.price)}
      </Type>

      <Flex className="quantity" gap={60}>
        <div>
          <Flex
            as="span"
            className={`btn ${quantity === minInv ? "disabled" : ""}`}
            onClick={() => handleQuantity("dec")}
          >
            -
          </Flex>
        </div>
        <InputText
          style={{ width: 120, minWidth: "52px", marginBottom: 0 }}
          size="small"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          inputProps={{
            onKeyUp: (e) => delay(enforceMinMax, 500)(e, setQuantity),
            min: minInv,
            max: limit,
            style: {
              backgroundColor: "transparent",
              fontWeight: 700,
              textAlign: "center",
            },
          }}
        />
        <div>
          <Flex as="span" onClick={() => handleQuantity("inc")}>
            +
          </Flex>
        </div>
      </Flex>
      <Button
        loading={loading}
        onClick={handleStake}
        disabled={!user.user.isVerified || usdt === -2 || balance === -2}
      >
        Buy Now
      </Button>
      {(usdt === -2 || balance === -2) && (
        <Type as="div" sx={{ c: "red", fw: "700", mb: 15 }}>
          To buy tokens, you need to be connected to metamask first.
        </Type>
      )}
      {!user.user.isVerified && (
        <Type as="div" sx={{ c: "red", fw: "700", pb: [10, 10, 10, 40] }}>
          You must verify identity before buying tokens.{" "}
          <Link gap={6} to="/verify-identity">
            <Type sx={{ c: "primary", fs: 16, fw: 600 }} as="span">
              Scan ID
            </Type>{" "}
            <Icon name="arrowRight" c="primary" w={15} />
          </Link>
        </Type>
      )}

      {/* <Type var="p" as="p" sx={{ mt: 30 }}>
        Go back to <Link to="/marketplace">Marketplace</Link>
      </Type> */}
    </InvestBox>
  );
}

export default BuyToken;
