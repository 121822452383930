import { useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import img from "../../assets/legal-entity-account-creation.png";
import { Box, Type } from "../../ui";
import CompanyForm from "./CompanyForm";
import { useUtils } from "../../services/utilsProvider";
import styled from "styled-components";

const SBox = styled(Box)`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 340px;
    height: 340px;
    background-image: url(${img});
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 100px;
    left: -320px;
  }
`;

function AddCompany() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("Company has been saved successfully");
    navigate("/verify-identity");
  };

  return (
    <LayoutMasked onClose={() => navigate(-1)}>
      <SBox sx={{ mw: 750, ta: "center", mt: -60 }}>
        {/* <img src={img} alt="Legal entity" /> */}
        <Type sx={{ fs: 32, fw: 300, my: 40 }}>Register a Legal Entity</Type>
        <Type var="p" sx={{ pb: 40, mw: 550, m: "auto" }} as="p">
          Please Enter your Details Below to Complete your Business Setup. You
          will be Contacted by our Compliance Legal Team.
        </Type>

        <CompanyForm onSuccess={handleSuccess} />
      </SBox>
    </LayoutMasked>
  );
}

export default AddCompany;
