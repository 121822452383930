import { Box, Grid, Type } from "../ui";

function ImportantFacts() {
  return (
    <>
      <Type
        var="h2"
        as="h2"
        sx={{ mb: 60, mw: 800, mx: "auto", fw: 400, ta: "center", c: "black" }}
      >
        AN INTEGRATED PLATFORM OF{" "}
        <Type var="0" as="span" sx={{ c: "primary" }}>
          MYRIAD
        </Type>{" "}
        INVESTMENT{" "}
        <Type var="0" as="span" sx={{ c: "primary" }}>
          OPPORTUNITIES
        </Type>{" "}
        AND RETURNS.
      </Type>

      <Grid
        cols={["1fr 1fr 1fr 1fr", "1fr 1fr 1fr 1fr", "1fr 1fr", "1fr"]}
        gap={60}
        sx={{ px: 80 }}
      >
        <Box sx={{ ta: "center" }}>
          <Type var="h1" sx={{ fw: 500, fs: 46, lh: 50, mb: 16, c: "secondary" }}>
            +1M
          </Type>
          <Type sx={{text: 'uppercase', fs: 19}}>Shares Value</Type>
        </Box>
        <Box sx={{ ta: "center" }}>
          <Type var="h1" sx={{ fw: 500, fs: 46, lh: 50, mb: 16, c: "secondary" }}>
            +2k
          </Type>
          <Type sx={{text: 'uppercase', fs: 19}}>Security Tokens</Type>
        </Box>
        <Box sx={{ ta: "center" }}>
          <Type var="h1" sx={{ fw: 500, fs: 46, lh: 50, mb: 16, c: "secondary" }}>
            +15k
          </Type>
          <Type sx={{text: 'uppercase', fs: 19}}>Total Investments</Type>
        </Box>
        <Box sx={{ ta: "center" }}>
          <Type var="h1" sx={{ fw: 500, fs: 46, lh: 50, mb: 16, c: "secondary" }}>
            +47
          </Type>
          <Type sx={{text: 'uppercase', fs: 19}}>Brands & Artist</Type>
        </Box>
      </Grid>
    </>
  );
}

export default ImportantFacts;
