import styled, { css } from "styled-components";
import Card from "./Card";

export default styled(Card).attrs((p) => ({
  bg: p.c || "black",
  size: p.size || "medium",
}))`
  border-radius: 6px;
  opacity: 0.8;
  font-size: 14px;
  color: ${(p) => p.theme.color.gray100};
  > div {
    padding: 10px 15px;
  }
  ${(p) =>
    p.size === "small" &&
    css`
    opacity: 0.6;
      font-size: 12px;
      > div {
        padding: 6px 10px;
      }
    `}
`;
