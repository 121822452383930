import { useEffect } from "react";
import Web3 from "web3";
import { useAuth } from "../../../services/auth";
import { Box, Card, Flex, Icon, Link, Type } from "../../../ui";
import useWeb3 from "../../../utilities/hooks/useWeb3";
import { useCompanies } from "../provider";
import { currency } from "../../../utilities/functions";
import { useProfile } from "../../profile/provider";

function Wrapper({ loading, children, ...props }) {
  return (
    <Card rad={15} padding="50px 0" elevation={-1} loading={loading} {...props}>
      {children}
    </Card>
  );
}

function DisplayBalance({ hide, ...props }) {
  const { user } = useAuth();

  const { getTokenBalance, getUsdtBalance } = useWeb3();

  const {
    state: { balance, usdt },
    dispatch,
  } = useCompanies();

  const {
    state: { networkBalance, networks, status },
    fetchNetworkBalance,
  } = useProfile();

  useEffect(() => {
    (async () => {
      const res = await getTokenBalance();
      dispatch({ type: "set_balance", payload: res });
    })();
  }, [dispatch, getTokenBalance]);

  useEffect(() => {
    (async () => {
      const res = await getUsdtBalance();
      if (res) dispatch({ type: "set_usdt", payload: res });
    })();
  }, [dispatch, getUsdtBalance]);

  const loading = balance === -1 || usdt === -1;

  if (balance === -2 || usdt === -2)
    return (
      <Wrapper
        loading={
          (loading || status === "fetching network balance") &&
          user.user.isVerified
        }
        {...props}
      >
        <Flex gap={15}>
          <Icon name="circleExclamation" w="24" c="red" /> Please make sure you
          are connected to MetaMask.
        </Flex>
      </Wrapper>
    );

  return (
    <Wrapper
      as={Flex}
      loading={
        (loading || status === "fetching network balance") &&
        user.user.isVerified
      }
      {...props}
    >
      {user.user.isVerified ? (
        <>
          {!Boolean(hide?.includes("token")) && (
            <Type sx={{ ta: "center", mb: 28, fs: 20 }}>
              Token Balance:{" "}
              <Type as="span" sx={{ pl: 5, fs: 20, fw: 600 }}>
                {balance !== -1 && Web3.utils.fromWei(balance, "mwei")}
              </Type>
            </Type>
          )}
          {!Boolean(hide?.includes("usdt")) && (
            <Flex gap={5}>
              <Type sx={{ ta: "center", fs: 20 }}>USDT Balance: </Type>
              <Type as="span" sx={{ pl: 5, fs: 20, fw: 600 }}>
                {Boolean(usdt !== -1 && networkBalance)
                  ? networkBalance.balance
                    ? currency(networkBalance.balance.toString(), null, null)
                    : "N/A"
                  : currency(
                      Web3.utils.fromWei(usdt.toString(), "mwei"),
                      null,
                      null
                    )}
              </Type>
              {Boolean(networkBalance) && (
                <Box sx={{ pl: 4 }}>
                  <Icon
                    onClick={() =>
                      fetchNetworkBalance(
                        networks.find(
                          (n) => n.chainID === Number(networkBalance.chainID)
                        )
                      )
                    }
                    hover="gray300"
                    name="refresh"
                    c="secondary"
                    w={20}
                  />
                </Box>
              )}
            </Flex>
          )}
        </>
      ) : (
        <>
          <Type sx={{ ta: "center", c: "red", mb: 0 }}>
            You must be verified in order to display total Tokens and USDT in
            your account.
          </Type>
          <Flex as={Link} gap={6} to="/verify-identity">
            <Type sx={{ fs: 18, c: "gray800" }}>Scan ID</Type>{" "}
            <Icon name="arrowRight" c="gray800" w={15} />
          </Flex>
        </>
      )}
    </Wrapper>
  );
}

export default DisplayBalance;
