import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useAuth } from "../../services/auth";
import { Box, Button, Flex, Type } from "../../ui";

const spin = keyframes`
  from {
    transform: rotateX(40deg) rotateY(40deg);
  }
  to {
    transform: rotateX(400deg) rotateY(400deg);
  }
`;

const Sflex = styled(Flex)`
  max-width: 1350px;
  padding: 0 30px;
  margin: 0 auto;
  height: calc(100vh - 110px);
  position: relative;
  transform-style: preserve-3d;
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    min-height: 650px;
  }

  > div {
    &.content {
      position: relative;
      z-index: 99999;
      .title {
        text-transform: uppercase;
        display: block;
        transition: all 0.25s 0.2s cubic-bezier(0.4, 0, 1, 1);
      }
      .sub-title {
        display: block;
        transition: all 0.3s 0.1s cubic-bezier(0.4, 0, 1, 1);
      }
      span {
        transform: ${(p) => (p.show ? "translateY(0px)" : "translateY(-20px)")};
        opacity: ${(p) => (p.show ? "1" : "0")};
      }
      button {
        transform: ${(p) => (p.show ? "translateY(0px)" : "translateY(10px)")};
        transition: transform 0.3s 0.5s cubic-bezier(0.4, 0, 1, 1),
          background-color 0.1s cubic-bezier(0.4, 0, 1, 1),
          color 0.1s cubic-bezier(0.4, 0, 1, 1);
        opacity: ${(p) => (p.show ? "1" : "0")};
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }
    }
    &.cube {
      width: 300px;
      height: 300px;
      perspective: 1000px;
      z-index: 9999;
      .dice {
        transform-style: preserve-3d;
        width: 100%;
        height: 100%;
        animation: ${spin} 220s infinite linear;
        position: relative;
        .wall {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 6rem;
          color: #fff;
          position: absolute;
          top: calc(50% - 100px);
          left: calc(50% - 100px);
          width: 260px;
          height: 260px;
          background: linear-gradient(
            45deg,
            ${(p) => p.theme.color.primary}bb,
            ${(p) => p.theme.color.primary}ff
          );
          @media only screen and (max-width: 920px) {
            width: 200px;
            height: 200px;
          }
        }
        .top {
          transform: rotateX(90deg) translateZ(130px);
          @media only screen and (max-width: 920px) {
            transform: rotateX(90deg) translateZ(100px);
          }
          background: linear-gradient(45deg, #f27679bb, #f27679);
        }
        .bottom {
          transform: rotateX(-90deg) translateZ(130px);
          @media only screen and (max-width: 920px) {
            transform: rotateX(-90deg) translateZ(100px);
          }
          background: linear-gradient(45deg, #ef6361bb, #ef6361);
        }

        .right {
          transform: rotateY(90deg) translateZ(130px);
          @media only screen and (max-width: 920px) {
            transform: rotateY(90deg) translateZ(100px);
          }
          background: linear-gradient(45deg, #ec3c49bb, #ec3c49);
        }
        .left {
          transform: rotateY(-90deg) translateZ(130px);
          @media only screen and (max-width: 920px) {
            transform: rotateY(-90deg) translateZ(100px);
          }
        }

        .front {
          transform: rotateX(0deg) translateZ(130px);
          @media only screen and (max-width: 920px) {
            transform: rotateX(0deg) translateZ(100px);
          }
        }
        .back {
          transform: rotateX(-180deg) translateZ(130px);
          background-color: #222;
          color: #fff;
          font-size: 36px;
          @media only screen and (max-width: 920px) {
            transform: rotateX(-180deg) translateZ(100px);
          }
        }
      }
      img {
        width: 100%;
        margin-right: -200px;
        @media only screen and (max-width: 768px) {
          width: 70%;
        }
      }
    }
  }
`;

function Slide({ data, show, slideRef, ...props }) {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <Sflex
      jc={["space-between", "space-between", "center", "center"]}
      gap={["40px", "40px", "80px", "60px"]}
      dir={["row", "row", "column-reverse", "column-reverse"]}
      show={show}
      ref={slideRef}
      {...props}
    >
      <Box className="content" sx={{ mw: 600 }}>
        <Type
          sx={{
            fs: [46, 46, 32, 28],
            lh: [54, 54, 44, 40],
            ff: "primary",
            c: "black",
            fw: 500,
            mb: 18,
          }}
          className="title"
          as="span"
        >
          {data.title}
        </Type>
        <Type
          sx={{
            fs: [22, 22, 21, 21],
            lh: [26, 26, 24, 24],
            ff: "primary",
            fw: 300,
            mb: 35,
            c: "black",
            mw: 500,
          }}
          className="sub-title"
          as="span"
        >
          {data.subTitle}
        </Type>
        <Type var="p">{data.body}</Type>

        <Button
          size="large"
          onClick={() =>
            navigate(auth?.user ? "/company/opportunities" : "/register")
          }
        >
          {auth?.user ? "Find Oppotunities" : "SIGN UP TO JOIN"}
        </Button>
      </Box>
      <Box className="cube" sx={{ mw: [600, 600, 600, 300] }}>
        <div className="dice">
          <div className="wall front" />
          <div className="wall back" />
          <div className="wall right" />
          <div className="wall left" />
          <div className="wall top" />
          <div className="wall bottom" />
        </div>
      </Box>
    </Sflex>
  );
}

export default Slide;
