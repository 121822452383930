import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Flex } from "../../ui";
import Box from "../../ui/Box";
import Carousel from "../../ui/carousel/Carousel";
import Type from "../../ui/Type";
import useMedia from "../../utilities/hooks/useMedia";
import CompanyBox from "../company/companyBox/CompanyBox";
import { useCompanies } from "../company/provider";
import shapeColor from "../../assets/shape-color-1.png";
import cylinderShape from "../../assets/cylinder-shape.png";
import coneShape from "../../assets/cone-shape.png";

const SBox = styled(Box)`
  position: relative;
  padding-bottom: 0px;
  margin-top: 120px;
  /* z-index: 50; */
  /* &::before {
    content: "";
    position: absolute;
    display: block;
    width: 250px;
    height: 250px;
    background-image: url(${shapeColor});
    background-size: cover;
    top: 200px;
    right: 0px;
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 200px;
    background-image: url(${coneShape});
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -100px;
    right: 200px;
  } */
  /* @media only screen and (max-width: 992px) {
    padding-bottom: 200px;
    &::after {
      width: 180px;
      height: 180px;
      bottom: 0px;
    }
  } */
  > div {
    /* &::before {
      content: "";
      position: absolute;
      display: block;
      width: 280px;
      height: 280px;
      background-image: url(${cylinderShape});
      background-size: contain;
      background-repeat: no-repeat;
      bottom: -160px;
      right: 100px;
    }
    @media only screen and (max-width: 992px) {
      &::before {
        width: 220px;
        height: 220px;
        bottom: 0px;
      }
    } */
  }
  .carousel {
    position: relative;
    z-index: 100;
    .items {
      .item:nth-child(even) {
        > div {
          margin-top: 140px;
        }
      }
    }
  }
`;

const Next = () => {
  return <Type sx={{ fs: 36, m: 0 }}>&rarr;</Type>;
};

const Prev = () => {
  return <Type sx={{ fs: 36, m: 0 }}>&larr;</Type>;
};

function Opportunities() {
  const [data, setData] = useState([]);
  const media = useMedia();

  const { fetchList } = useCompanies();

  useEffect(() => {
    (async () => {
      const data = await fetchList({ statusID: 3 });
      setData(data);
    })();
  }, [fetchList]);

  if (!data.length) return null;

  return (
    <>
      <SBox className="opportunity" center sx={{ mw: 1370 }}>
        <div>
          <Flex
            ai="flex-start"
            dir={["row", "column-reverse", "column-reverse", "column-reverse"]}
          >
            <Box
              sx={{
                mw: [
                  [1400, 870],
                  [950, "none"],
                ],
                w:
                  media !== "xs" && media !== "sm" && media !== "md"
                    ? "auto"
                    : "100%",
                ta: "center",
                mh: 500,
              }}
              center
            >
              {data.length ? (
                <>
                  {media !== "xs" && media !== "sm" ? (
                    <Carousel
                      options={{
                        slides: data,
                        slide: CompanyBox,
                        itemW: 360,
                        gap: 0,
                        nav: {
                          style: {
                            textAlign: "center",
                            marginTop: "0",
                          },
                          next: <Next />,
                          prev: <Prev />,
                        },
                      }}
                    />
                  ) : (
                    <>
                      {data.map((item) => (
                        <Box key={item.id} sx={{ mb: 40 }}>
                          <CompanyBox data={item} />
                        </Box>
                      ))}
                    </>
                  )}
                </>
              ) : (
                  <Type sx={{mt: 100}}>No Opportunities are available at this moment.</Type>
              )}
            </Box>
            <Box>
              <Type var="display" sx={{ mt: 40, mb: 15, px: 30 }}>
                Investment
                <br /> Opportunities
              </Type>
              {/* <Type var="p" sx={{ mb: 12, mw: 480 }} center as="p">
                The sky was cloudless and of a deep dark blue. The spectacle
                before us was indeed sublime. Then came the night of the first
                falling star.
              </Type> */}
            </Box>
          </Flex>
        </div>
      </SBox>
    </>
  );
}

export default Opportunities;
