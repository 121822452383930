import { useEffect } from "react";
import styled from "styled-components";
import { useAuth } from "../../services/auth";
import { Box, Button, Flex, Link, Type } from "../../ui";
import Avatar from "../../ui/Avatar";
import Card from "../../ui/Card";
import Chip from "../../ui/Chip";
import Dropdown from "../../ui/core/Dropdown";
import { currency, getInitials } from "../../utilities/functions";
import useImageLink from "../../utilities/hooks/useImageLink";
import { useDashboard } from "../../views/dashboard/provider";
import { useProfile } from "../../views/profile/provider";

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  li {
    margin: 10px 0;
    font-size: 15px;
  }
`;

const Sflex = styled(Flex)`
  width: auto;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  transition: all .1s cubic-bezier(0.4, 0, 1, 1);
  outline: transparent 1px solid;

  @media (min-width: 1300px) {
    min-width: 285px;
    &:hover, &.active {
      /* outline: ${(props) => props.theme.color.gray300} 1px solid; */
      box-shadow: 0px 0px 3px 0px rgba(0,0,0, .05), 1px 3px 5px 0px rgba(0,0,0, .1);
    }
  }
  @media (max-width: 1300px) {
    .avatar {
      transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
      outline: transparent 1px solid;
      &:hover {
        box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.15);
        outline: rgba(255, 255, 255, 0.15) 1px solid;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: none;
    display: inline-block;
    padding-left: 30px;
  }
`;

function UserInfo(props) {
  const { user } = useAuth();

  return (
    <Box sx={{ fs: 13, c: "gray300" }} {...props}>
      <Box sx={{ m: 5 }}>
        <Type sx={{ mb: 8, mt: 0, pr: 6 }} as="span">
          Welcome
        </Type>
        <Type as="strong">{user?.tokens?.user?.firstName} {user?.tokens?.user?.lastName}</Type>
      </Box>
      <Box>
        <Chip>Total Investment (USDT) {currency(props.totalInvest, null, null)}</Chip>
      </Box>
    </Box>
  );
}

function ProfileOpts() {
  const { signout, user } = useAuth();
  const {
    state: { totalInvest },
  } = useDashboard();
  
  return (
    <Card rad={14} sx={{minw: 180}}>
      <UserInfo className="h-lg" sx={{ mb: 30 }} totalInvest={totalInvest} />

      <List>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/profile">Profile</Link>
        </li>
        <li>
          <Link
            to={
              user.user?.companyID
                ? `/company/own`
                : "/company/new"
            }
          >
            Legal Entity
          </Link>
        </li>
        {!user.user?.isVerified && <li>
          <Link to="/verify-identity">Verify Identity</Link>
        </li>}
      </List>
      <Button size="small" variant="outlined" bg="secondary" sx={{w: '100%'}} onClick={signout}>
        Logout
      </Button>
    </Card>
  );
}

function UserAccount() {
  const { user } = useAuth();
  const imgUrl = useImageLink(user?.user?.avatarURL);

  const {
    state: { totalInvest },
    fetchTotalInvest,
  } = useDashboard();

  const {
    state: { selected: profile },
    fetchOne: fetchProfile,
  } = useProfile();

  useEffect(() => {
    if (profile) return;
    fetchProfile(user.user.id);
  }, [fetchProfile, profile, user.user?.id]);

  useEffect(() => {
    if (!profile) return;
    if (!profile.walletAddress) {
      console.log("please connect to metamask first.");
      return;
    }
    fetchTotalInvest(profile.walletAddress);
  }, [fetchTotalInvest, profile]);

  return (
    <Dropdown content={<ProfileOpts />}>
      <Sflex>
        <Avatar className="avatar">
          {imgUrl ? (
            <img src={imgUrl} alt="user profile avatar" />
          ) : (
            getInitials(user?.user?.firstName + " " + user?.user?.lastName)
          )}
        </Avatar>


        <UserInfo className="h-md" sx={{ ml: 10 }} totalInvest={totalInvest} />

      </Sflex>
    </Dropdown>
  );
}

export default UserAccount;
