import { memo } from "react";
import styled, { css } from "styled-components";
import { Box, Icon, Loading, Type } from "../ui";
import Label from "../ui/Label";

const Wrap = styled.div`
  ${(props) =>
    !props.document &&
    css`
      margin-left: 20px;
    `}
  .preview {
    width: 255px;
    min-height: 180px;
    background-color: ${(props) => props.theme.color.gray200};
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      margin: 0;
      display: block;
    }
    .loading-wrap {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ${(props) =>
    props.document &&
    css`
      .wrap2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
      }
    `}
`;

const FileLabel = styled.label`
  ${(props) =>
    !props.document &&
    css`
      max-width: 255px;
    `}
  padding: 14px;
  border-radius: 10px;
  border: ${(props) => props.theme.color.gray500} 1px dashed;
  background-color: ${(props) =>
    props.theme.color[props.edit ? "gray100" : "gray50"]};
  display: flex;
  align-items: center;
  line-height: 0;
  &:hover {
    border-color: ${(props) => props.theme.color.gray200};
  }
  &:active {
    border-width: 2px;
    background-color: ${(props) => props.theme.color.gray100};
  }
  ${(props) =>
    props.edit
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          line-height: 0;
          p {
            margin-bottom: 0 !important;
          }
          &:hover,
          &:active {
            background-color: ${(props) => props.theme.color.gray300};
          }
        `
      : css`
          border: 1px dashed ${(props) => props.theme.color.gray1k};
        `}
  .preview {
    width: 225px;
    height: 307px;
    background-color: ${(props) => props.theme.color.gray200};
  }
  img {
    height: 54px;
  }
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 10px;
      svg {
        width: 18px;
      }
    }
  }
`;

function FileField({
  label,
  preview,
  inputProps,
  msg = null,
  error = false,
  buttonLabel,
  document,
  documentFile,
  disabled = false,
  readOnly,
  edit,
  onClose,
  loading,
  ...props
}) {
  return (
    <Wrap document={document} preview={preview} {...props}>
      <Label>{label}</Label>
      {!document && (
        <div className={`preview`}>
          {Boolean(preview) && (
            <img src={preview} alt="preview uploaded file" />
          )}
          {loading && (
            <div class="loading-wrap">
              <Loading />
            </div>
          )}
        </div>
      )}
      <div className="wrap2">
        {!disabled && (
          <FileLabel
            document={document}
            htmlFor={inputProps?.name + "file"}
            edit={edit}
          >
            <Icon name="cloud" w={32} c="black" />
            <Box sx={{ ml: 15 }}>
              <Type var="p" sx={{ pt: 0, pb: 0, lh: 24, fs: 16 }}>
                {buttonLabel}
              </Type>
              {/* {!edit && ( */}
              <Type as="p" sx={{ m: 0, fs: 13, c: "gray500" }}>
                {document ? "PDF, DOC, DOCX, PPT" : "JPG, PNG"}
              </Type>
              {/* )} */}
            </Box>
            {
              <input
                className="custom-file-input"
                id={inputProps?.name + "file"}
                type="file"
                {...inputProps}
              />
            }
          </FileLabel>
        )}
        {documentFile && (
          <FileLabel document={document} className="doc-preview">
            {!readOnly && (
              <span className="close" onClick={onClose}>
                <Icon name="circleXMark" />
              </span>
            )}
            <a
              className="icon"
              href={documentFile.url}
              download={documentFile.url}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="document" />
            </a>
            <a
              className="info"
              href={documentFile.url}
              download={documentFile.url}
              target="_blank"
              rel="noreferrer"
            >
              <p>{documentFile.name}</p>
              {documentFile.size ? (
                <small>{documentFile.size} KB</small>
              ) : (
                <small>Click to download</small>
              )}
            </a>
          </FileLabel>
        )}
      </div>
    </Wrap>
  );
}

export default memo(FileField);
