import styled from "styled-components";
import Type from "../../ui/Type";
import img1 from "../../assets/investor-account2.png";
import img2 from "../../assets/shareholder-account2.png";
import { Box, Button, Card } from "../../ui";
import { useNavigate } from "react-router-dom";
import cube from "../../assets/our-feature-cube.png";

const Wrap = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto 180px auto;
  gap: 0px;
  padding: 0 40px 40px 40px;
  justify-content: space-around;
  position: relative;
  /* &::before {
    content: "";
    position: absolute;
    display: block;
    width: 320px;
    height: 320px;
    background-image: url(${cube});
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -200px;
    left: -60px;
  } */
  @media only screen and (max-width: 920px) {
    display: block;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0px 0px 0px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      margin-bottom: 160px;
    }
    @media only screen and (max-width: 920px) {
      margin-bottom: 100px;
      margin-left: auto;
      margin-right: auto;
      &:last-child {
        margin-bottom: 0;
      }
    }
    img {
      margin-top: -100px;
      height: 260px;
      @media only screen and (max-width: 768px) {
        height: 200px;
      }
    }
    button {
      margin: 0 auto;
      margin-bottom: 20px;
      min-width: 200px;
    }
    &:first-child {
      button {
        background-color: ${(p) => p.theme.color.secondary};
      }
    }
  }
`;

const AccountCard = ({ subTitle, title, children, handleClick, img }) => {
  return (
    <Box sx={{ w: [420, 420, 420, 320], ta: "center" }}>
      <Type var="h3" as="h3">
        {title}
      </Type>
      <Type var="subTitle" sx={{ mb: 60 }}>
        {subTitle}
      </Type>
      <Card elevation={6} sx={{ mt: 80 }}>
        <img src={img} alt="investor account" />
        <Type var="p" sx={{ mb: 30, mt: 20, mw: 560 }} center as="p">
          {children}
        </Type>
        <Button size="large" onClick={handleClick}>
          Join The Community
        </Button>
      </Card>
    </Box>
  );
};

function AccountType() {
  const navigate = useNavigate();

  const handleInvestorClick = () => {
    localStorage.setItem("accType", "investor");
    navigate("/register");
  };

  const handleStackholderClick = () => {
    localStorage.setItem("accType", "legal entity");
    navigate("/register");
  };

  return (
    <Wrap>
      <AccountCard
        img={img1}
        title="Are you an Investor?"
        subTitle={<>Create your account. No hidden fees.</>}
        handleClick={handleInvestorClick}
      >
        Your ultimate destination for profitable investments in leading
        companies, promising startups, and innovative enterprises around the
        world.
      </AccountCard>

      <AccountCard
        img={img2}
        title="Are you a company?"
        subTitle={<>Build your company profile in minutes</>}
        handleClick={handleStackholderClick}
      >
        Tokenize the shares you hold in a private company or any kind of asset,
        raise your capital, and unlock a new era of investments.
      </AccountCard>
    </Wrap>
  );
}

export default AccountType;
