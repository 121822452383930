import styled from "styled-components";
import { Icon, Type } from "../../../ui";
import { truncatWork } from "../../../utilities/functions";
import useSubscribe from "../../../utilities/hooks/useSubscribe";

const Styles = styled.div`
  .notify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0;
    margin: 5px 0 10px 0;
    font-size: 17px;
    svg {
      fill: ${p => p.theme.color.black};
      width: 18px;
      margin-right: 10px;
    }
    .checkbox-wrap {
      flex-grow: 1;
      text-align: right;

      span.checkbox {
        border-radius: 10px;
        width: 40px;
        height: 15px;
        position: relative;
        display: inline-block;
        background-color: ${(props) => props.theme.color.gray500}ee;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          top: -3px;
          left: 0px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: ${(props) => props.theme.color.white};
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          opacity: 1;
          transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
        }
        &:active {
          &::before {
            transform: scale(0.89);
          }
        }
      }
      &.active {
        span.checkbox {
          background-color: ${(props) => props.theme.color.primary}66;
          &::before {
            left: 20px;
            background-color: ${(props) => props.theme.color.primary};
          }
        }
      }
    }
  }
`;

function ComingSoon({ data }) {
  const { handleSub, sub } = useSubscribe(data);

  return (
    <Styles>
      <div className="notify">
        <Icon name="bell" /> <span>Notify me</span>
        <div
          className={`checkbox-wrap ${sub ? "active" : ""}`}
          onClick={handleSub}
        >
          <span className="checkbox" />
        </div>
      </div>
      <Type sx={{ h: [48, 48, 48, "auto"] }}>{truncatWork(data.description, 70)}</Type>
    </Styles>
  );
}

export default ComingSoon;
