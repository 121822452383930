import { useContext, useReducer, createContext, useCallback } from "react";
import { useAuth } from "../../services/auth";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useCompanies() {
  return useContext(Context);
}

const initState = {
  list: [],
  categories: [],
  docs: [],
  selected: null,
  financialInfo: null,
  subscribed: [],
  balance: -1,
  usdt: -1,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_categories":
      return { ...state, categories: [...action.payload] };
    case "set_docs":
      return { ...state, docs: [...action.payload] };
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
    case "set_financial_info":
      return { ...state, financialInfo: { ...action.payload } };
    case "set_balance":
      return { ...state, balance: action.payload };
    case "set_subscribed":
      let subscribed = [...state.subscribed];
      if (action.payload.intrested) {
        if (!subscribed.includes(action.payload.id))
          subscribed.push(action.payload.id);
      } else subscribed = subscribed.filter((id) => id !== action.payload.id);
      return { ...state, subscribed };
    case "set_usdt":
      return { ...state, usdt: action.payload };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { setCompany } = useAuth();

  const fetchList = useCallback(
    (params) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const searchParams = new URLSearchParams(params);
          const resData = await req(
            `company/?${searchParams.toString()}`,
            null,
            {},
            false
          );
          dispatch({ type: "set_list", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchRewards = useCallback(
    () => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(`company/rewards`, null, {}, true);
          // dispatch({ type: "set_list", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id, force = false) => {
      return new Promise(async (resolve, reject) => {
        if (!id) throw new Error("Error, company not found.");

        if (
          state.selected &&
          Number(state.selected.id) === Number(id) &&
          !force
        ) {
          resolve(state.selected);
          return;
        }

        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(`company/${id}`, null, {}, false);
          dispatch({ type: "set_selected", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, state.selected]
  );

  const fetchFinancialInfo = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(
            `company/${id}/financialInfo`,
            null,
            {},
            true
          );
          dispatch({ type: "set_financial_info", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchStakedFinancialInfo = useCallback(
    (id, wallet) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(
            `company/${id}/stakedFinancialInfo/${wallet}/`,
            null,
            {},
            true
          );
          dispatch({ type: "set_financial_info", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchDocs = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(`company/${id}/document`, null, {}, true);
          dispatch({ type: "set_docs", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData.data);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchCategories = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: "status", payload: "fetching categories" });
        const res = await req(`category/`, null, {}, true);
        dispatch({
          type: "set_categories",
          payload: res.data.categoryList,
        });
        resolve(res.data);
      } catch (e) {
        console.log(e);
        reject(e);
      } finally {
        dispatch({ type: "status", payload: `idle` });
      }
    });
  }, [req]);

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "company/add",
            data,
            { method: "POST" },
            true
          );

          dispatch({ type: "set_selected", payload: resData.data });

          setCompany(resData.data.id);

          resolve(resData.data);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, setCompany]
  );

  const isSubscribed = useCallback(
    async (cid) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: "fetching" });
          const res = await req(`company/${cid}/interested/`, null, {}, true);
          dispatch({
            type: "set_subscribed",
            payload: { id: cid, intrested: res.data.isInterested },
          });
          resolve(res.data.isInterested);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const subscribe = useCallback(
    async (cid, sub) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: "subscribing" });
          const res = await req(
            `company/${cid}/interested/`,
            {
              isInterested: sub,
            },
            { method: "POST" },
            true
          );
          dispatch({
            type: "set_subscribed",
            payload: { id: cid, intrested: sub },
          });
          resolve(res.data);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const buyTokensInterWallet = useCallback(
    async (cid, internalWallet, chainID, tokenCount) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: "buying tokens" });
          const res = await req(
            `company/${cid}/buyTokenUsingInternalWallet/`,
            {
              internalWallet,
              chainID: Number(chainID),
              tokenCount,
            },
            { method: "POST" },
            true
          );
          resolve(res.data);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchOne,
        create,
        fetchCategories,
        fetchList,
        fetchFinancialInfo,
        fetchStakedFinancialInfo,
        fetchDocs,
        isSubscribed,
        subscribe,
        fetchRewards,
        buyTokensInterWallet,
      }}
    >
      {children}
    </Context.Provider>
  );
}
