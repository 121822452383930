import styled from "styled-components";
import { Type } from "../../../ui";
import { getStatus } from "../../../utilities/functions";
import useImageLink from "../../../utilities/hooks/useImageLink";
import Closed from "./Closed";
import ComingSoon from "./ComingSoon";
import Live from "./Live";
import Upcoming from "./Upcoming";

const OportunitiesWrap = styled.div`
  width: calc(100% - 60);
  border-radius: ${(p) => p.theme.borderRadius}px;
  text-align: left;
  overflow: hidden;
  box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.06);
  margin: 30px;
  .content {
    padding: 10px 20px;
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  p {
    font-size: 16px;
    margin-bottom: 18px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 12px;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      span:first-child {
        font-weight: 700;
      }
      span:last-child {
        font-size: 15px;
      }
    }
  }
`;

function displayByStatus(status, data, to) {
  switch (status.toLowerCase()) {
    case "upcoming":
      return <Upcoming data={data} to={to} />;
    case "open":
      return <Live data={data} to={to} />;
    case "closed":
      return <Closed data={data} to={to} />;
    default:
      return <ComingSoon data={data} to={to} />;
  }
}

const CompanyBox = ({ data, to }) => {
  const img = useImageLink(data.imageURL);

  return (
    <OportunitiesWrap>
      <img src={img} alt="" style={{backgroundColor: '#999'}} />
      <div className="content">
        <Type
          var="h3"
          sx={{ fs: 20, m: 0, fw: 900, text: "uppercase" }}
          as="h3"
        >
          {data.companyName}
        </Type>

        {displayByStatus(getStatus(data.status), data, to)}
      </div>
    </OportunitiesWrap>
  );
};

export default CompanyBox;
