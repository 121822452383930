import styled from "styled-components";
import { Box, Type } from "../../ui";

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  @media only screen and (max-width: 992px) {
    margin-left: 60px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 30px;
  }
  li {
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: -90px;
      top: 14px;
      width: 0px;
      height: 1px;
      background-color: ${(p) => p.theme.color.gray400};
      transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
    }
    p {
      font-family: ${(p) => p.theme.fonts.secondary};
      color: ${(p) => p.theme.color.gray400};
    }
    &:hover {
      p {
        color: ${(p) => p.theme.color.gray500};
      }
    }
    &.active {
      cursor: none;
      pointer-events: none;
      p {
        color: ${(p) => p.theme.color.gray700};
        transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
      }
      &::before {
        width: 70px;
      }
    }
  }
`;

function CarouselNav({ data, page, setPage }) {
  return (
    <List className="sliderNav">
      {data.map(({ title }, i) => (
        <li
          key={title}
          onClick={() => setPage(i)}
          className={page === i ? "active" : ""}
        >
          <Type var="titleWisp">0{i + 1}</Type>
          <Type var="h5">{title}</Type>
        </li>
      ))}
    </List>
  );
}

const Item = styled(Box)`
  img {
    height: 160px;
    margin-left: 16px;
  }
`;

export const Slide = ({ data }) => {
  return (
    <Item sx={{ ta: "left", mw: "100%" }}>
      <img src={data.img} alt={data.title} />
      <Type var="h5" as="h5" sx={{ c: "black", my: 10 }}>
        {data.title}
      </Type>
      <Type var="p" as="p">
        {data.text}
      </Type>
    </Item>
  );
};

export const Next = () => {
  return <Type sx={{ fs: 28 }}>&rarr;</Type>;
};

export const Prev = () => {
  return <Type sx={{ fs: 28 }}>&larr;</Type>;
};

export default CarouselNav;
