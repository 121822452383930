import styled from "styled-components";

const Pager = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  section {
    display: flex;
    strong {
      padding: 0 5px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      border: #ccc 1px solid;
      border-radius: 8px;
      margin: 0 10px;
      &.pages {
        margin: 0;
      }
      li {
        display: inline;
        color: ${props => props.theme.color.black};
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 8px;
        .angle-left {
          border-top: ${props => props.theme.color.black} 2px solid;
          border-left: ${props => props.theme.color.black} 2px solid;
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
        }
        .angle-right {
          border-top: ${props => props.theme.color.black} 2px solid;
          border-right: ${props => props.theme.color.black} 2px solid;
          width: 8px;
          height: 8px;
          transform: rotate(45deg);
        }
        &:hover {
          background-color: ${props => props.theme.color.gray300};
        }
        &.active {
          background-color: ${props => props.theme.color.black};
          color: ${props => props.theme.color.white};
          span {
            border-color: white;
          }
        }
      }
    }
  }
`;

function Pagination({ count = 0, page = 0, rowsPerPage = 10, onPageChange, info=false, ...props }) {
  const availableRows = count - page * rowsPerPage;

  const handlePageClick = (p) => {
    const totalPages = Math.ceil(count / rowsPerPage);
    if (p < 0) onPageChange(0);
    else if (p > totalPages - 1) onPageChange(totalPages - 1);
    else onPageChange(p)
  };

  if (count === 0) return;
  return (
    <Pager {...props}>
      {info && <section className="left">
        Showing{" "}
        <strong>
          {page * rowsPerPage + 1}-
          {page * rowsPerPage +
            (availableRows < rowsPerPage ? availableRows : rowsPerPage)}
        </strong>{" "}
        from <strong>{count}</strong> total row{count > 1 && "s"}
      </section>}
      <section className="right">
        <ul>
          <li onClick={() => handlePageClick(page - 1)}>
            <span className="angle-left" />
          </li>
        </ul>
        <ul className="pages">
          {Array.from(Array(Math.ceil(count / rowsPerPage)).keys()).map((n) => (
            <li
              key={n}
              className={n === page ? "active" : ""}
              onClick={() => handlePageClick(n)}
            >
              {n + 1}
            </li>
          ))}
        </ul>
        <ul>
          <li onClick={() => handlePageClick(page + 1)}>
            <span className="angle-right" />
          </li>
        </ul>
      </section>
    </Pager>
  );
}

export default Pagination;
