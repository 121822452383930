import { useContext, useReducer, createContext, useCallback } from "react";
import { useAuth } from "../../services/auth";
import { useRequest } from "../../services/request";
import { createThumb } from "../../utilities/functions";
import { useRef } from "react";

const Context = createContext();

export function useProfile() {
  return useContext(Context);
}

const initState = {
  selected: null,
  networks: [],
  // network: null,
  networkBalance: null,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
    case "set_wallet":
      return {
        ...state,
        selected: {
          ...state.selected,
          walletAddress: action.payload,
        },
      };
    case "set_networks":
      return {
        ...state,
        networks: [...action.payload],
      };
    case "set_network_balance":
      return {
        ...state,
        networkBalance: action.payload,
      };
    case "set_has_private_key":
      return {
        ...state,
        selected: {
          ...state.selected,
          hasPrivateKey: action.payload,
        },
      };
    case "set_avatar":
      return {
        ...state,
        selected: {
          ...state.selected,
          user: { ...state.selected.user, avatarURL: action.payload },
        },
      };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProfileProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { setVerified, signout } = useAuth();

  let isFetchingOne = useRef(false);

  const fetchOne = useCallback(
    (id, force = false) => {
      return new Promise(async (resolve, reject) => {
        if (
          isFetchingOne.current ||
          (state.selected &&
            Number(state.selected?.id) === Number(id) &&
            !force)
        ) {
          resolve(state.selected);
          return;
        }

        isFetchingOne.current = true;
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(`account/profile/${id}/`, null, {}, true);
          resData.data.dateOfBirth = resData.data.dateOfBirth?.split("T")[0];
          dispatch({ type: "set_selected", payload: resData.data });
          setVerified(resData.data.isVerified);
          resolve(resData.data);
        } catch (e) {
          reject(e);
          signout();
          if (e.errors.message === "INVALID_USER")
            window.location.href = "/login";
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, setVerified, signout, state]
  );

  const fetchByWallet = useCallback(
    (wallet) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching by wallet` });
        try {
          const resData = await req(
            `account/profile/wallet/${wallet}/`,
            null,
            {},
            true
          );
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const update = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `updating` });
        try {
          const resData = await req(
            `account/update/`,
            data,
            { method: "PATCH" },
            true
          );
          resData.data.dateOfBirth = resData.data.dateOfBirth?.split("T")[0];
          dispatch({ type: "set_selected", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const saveAvatar = useCallback(
    (file) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });
          const thumb = await createThumb(file, 500, 500);

          let formData = new FormData();
          formData.append("avatar", thumb);

          const resData = await req(
            "account/setAvatar/",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "set_avatar", payload: resData.data.avatarURL });
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  let setWallet = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `account/setWallet/`,
            { walletAddress: address },
            {
              method: "PATCH",
            },
            true
          );
          dispatch({ type: "set_wallet", payload: address });
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let resetWallet = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await req(
          `account/resetWallet/`,
          null,
          {
            method: "PATCH",
          },
          true
        );
        dispatch({ type: "set_wallet", payload: null });
        resolve(res.data);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }, [req]);

  let setPrivateKey = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `account/setPrivateKey/`,
            { privateKey: address },
            {
              method: "PATCH",
            },
            true
          );
          dispatch({
            type: "set_has_private_key",
            payload: res.data.hasPrivateKey,
          });
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let resetPrivateKey = useCallback(() => {
    dispatch({ type: "status", payload: `resetting private key` });
    return new Promise(async (resolve, reject) => {
      try {
        const res = await req(
          `account/resetPrivatekey/`,
          null,
          {
            method: "PATCH",
          },
          true
        );
        dispatch({ type: "set_has_private_key", payload: null });
        resolve(res.data);
      } catch (e) {
        reject(e);
        console.log(e);
      } finally {
        dispatch({ type: "status", payload: `idle` });
      }
    });
  }, [req]);

  let fetchNetworks = useCallback((id) => {
    dispatch({ type: "status", payload: `fetching networks` });
    return new Promise(async (resolve, reject) => {
      try {
        const res = await req(`company/${id}/DirectTransferOptions/`, null, {}, true);
        dispatch({ type: "set_networks", payload: res.data });
        // dispatch({
        //   type: "set_internal_wallet",
        //   payload: res.data.InternalWallet,
        // });
        resolve(res.data);
      } catch (e) {
        reject(e);
        console.log(e);
      } finally {
        dispatch({ type: "status", payload: `idle` });
      }
    });
  }, [req]);

  let fetchNetworkBalance = useCallback(
    (network) => {
      dispatch({ type: "status", payload: `fetching network balance` });
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `account/USDTBalance/?wallet=${
              network.internalWallet
            }&chainID=${Number(network.chainID)}`,
            null,
            {},
            true
          );
          dispatch({
            type: "set_network_balance",
            payload: { chainID: network.chainID, balance: res.data },
          });
          resolve(res.data);
        } catch (e) {
          dispatch({
            type: "set_network_balance",
            payload: { chainID: network.chainID, balance: null },
          });
          reject(e);
          console.log(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  let resetNetworkBalance = useCallback(() => {
    dispatch({
      type: "set_network_balance",
      payload: null,
    });
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchOne,
        fetchByWallet,
        setWallet,
        update,
        saveAvatar,
        resetWallet,
        setPrivateKey,
        resetPrivateKey,
        fetchNetworks,
        fetchNetworkBalance,
        resetNetworkBalance,
      }}
    >
      {children}
    </Context.Provider>
  );
}
