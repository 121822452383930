import { Button, Card, Divider, TableContainer, Type } from "ui";
import { currency } from "utilities/functions";
import { useOrdersBook } from "./provider";
import { useEffect } from "react";
import Web3 from "web3";
import CancelOrderBtn from "./CancelOrderBtn";

const cols = [
  {
    label: "Amount",
    name: "amount",
    render: (row) => Web3.utils.fromWei(row.amount.toString(), "mwei"),
  },
  {
    label: "Price",
    name: "price",
    render: (row) => <Type sx={{c: 'secondary', fw: 500}}>{currency(row.price, "currency", "USD", 1, 1)}</Type>,
  },
  {
    label: "Total",
    name: "total",
    render: (row) =>
    <Type sx={{c: 'secondary', fw: 600}}>{currency(
        (Web3.utils.fromWei(row.amount.toString(), "mwei") * row.price),
        "currency",
        "USD",
        1,
        1
      )}</Type>,
  },
];

function OrdersBookList({ id }) {
  const {
    state: { list, status, selected },
    fetchList,
    dispatch,
  } = useOrdersBook();

  useEffect(() => {
    fetchList(id);
  }, [fetchList, id]);

  return (
    <Card padding="0px">
      <Type var="h4" as="h4" sx={{ p: 20, c: "gray700" }}>
        Orders Book
      </Type>
      <Divider border="gray300" h={1} />
      <TableContainer
        empty={list.length === 0 && status !== "fetching"}
        cols={cols}
        data={list}
        moreOptions={[
          {
            name: "Buy",
            render: (row) =>
              row.isMine ? (
                <CancelOrderBtn id={row.id} />
              ) : (
                <Button
                  variant="outlined"
                  bg="secondary"
                  size="small"
                  disabled={Boolean(selected?.id === row.id)}
                  onClick={() =>
                    dispatch({ type: "set_selected", payload: row })
                  }
                  iconName={
                    Boolean(selected?.id === row.id) ? "arrowRight" : null
                  }
                >
                  Buy
                </Button>
              ),
          },
        ]}
      />
    </Card>
  );
}

export default OrdersBookList;
