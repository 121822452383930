import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { Box } from "../../ui";

// const Logo = styled.div`
//   font-weight: 900;
//   font-size: 42px;
//   color: ${(p) => p.theme.color.black};
//   text-decoration: none;
//   cursor: pointer;
// `;

function Logo(props) {
  return (
    <Box
      as={Link}
      to="/"
      {...props}
      sx={{ w: [160, 160, 160, 135], d: "block" }}
    >
      <img src={logo} alt="" style={{ width: "100%" }} />
    </Box>
  );
}

export default Logo;
