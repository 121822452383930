import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../services/auth";
import { Button, Flex, Link } from "../ui";
import Close from "../ui/Close";
import useMedia from "../utilities/hooks/useMedia";
import Logo from "./header/Logo";
import Nav from "./header/Nav";
import UserAccount from "./header/UserAccount";

const Wrap = styled.header`
  position: relative;
  z-index: 9999px;
  padding: 21px 95px;
  border-bottom: ${(p) => p.theme.color.gray200} 1px solid;
  @media only screen and (max-width: 1200px) {
    padding: 21px 30px;
  }

  @media only screen and (max-width: 920px) {
    nav {
      margin: 30px 0;
      ul {
        li:first-child {
          padding-left: 0;
        }
      }
    }
  }
`;

const Drawer = styled.div`
  .toggle {
    cursor: pointer;
    padding: 10px 6px;
    width: 20px;
    .bars {
      position: relative;
      height: 2px;
      width: 13px;
      background-color: ${(props) => props.theme.color.black};
      &::after {
        content: "";
        position: absolute;
        top: -6px;
        right: 0;
        height: 2px;
        width: 20px;
        background-color: ${(props) => props.theme.color.black};
      }
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 6px;
        height: 2px;
        width: 16px;
        background-color: ${(props) => props.theme.color.black};
      }
    }
  }
  .content {
    position: fixed;
    top: 0;
    right: 0;
    width: 280px;
    height: 100vh;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    border-left: rgba(255, 255, 255, 0.15) 1px solid;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.15);
    z-index: 9999;
    transform: translate(280px, 0);
    transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1);
  }
  &.active {
    .content {
      transform: translate(0, 0);
    }
  }
`;

function Header() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const media = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const { isVerified } = useAuth();

  return (
    <Wrap>
      <Flex jc="space-between" gap={[20, 20, 20, 0]}>
        <div style={{ flexGrow: 1 }}>
          <Logo as={Link} to="/" />
        </div>
        {media === "xs" || media === "sm" || media === "md" ? (
          <Drawer className={openDrawer ? "active" : ""}>
            <div className="toggle" onClick={() => setOpenDrawer((v) => !v)}>
              <div className="bars" />
            </div>
            <div className="content">
              <Close size="medium" onClick={() => setOpenDrawer((v) => !v)} />
              <Nav className="md" />
            </div>
          </Drawer>
        ) : (
          <>
            <Nav />
            {/* <Divider vertical border="gray500" w={50} className="h-md" /> */}
            {/* <Icon name="search" c="gray50" /> */}
            {/* <Divider
              vertical
              w={Boolean(isVerified()) ? 35 : 50}
              className="h-sm"
            /> */}
          </>
        )}
        {Boolean(isVerified()) ? (
          <UserAccount />
        ) : (
          <Button
            size="small"
            bg="secondary"
            onClick={() => navigate(`/login?from=${location.pathname}`)}
            sx={{ml: 10}}
          >
            Sign in
          </Button>
        )}
      </Flex>
    </Wrap>
  );
}

export default Header;
