import styled from "styled-components";
import { Box, Divider, Flex, Grid, Icon, Link } from "../ui";
import Type from "../ui/Type";
import logo from "../assets/logo.png";

const Wrap = styled.div`
  background-color: #f8f7fd;
  padding: 100px 0;
  margin-top: ${p => p.gap || '180px'};
  footer {
    max-width: 1350px;
    margin: 0 auto;
    @media only screen and (max-width: 1400px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    .socials {
      > a {
        display: inline-block;
        border: ${(p) => p.theme.color.black} 1px solid;
        border-radius: 99999px;
        height: 24px;
        width: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 4px;
        svg {
          fill: ${(p) => p.theme.color.dark};
          height: 14px;
          width: 14px;
        }
        &:hover {
          border-color: ${(p) => p.theme.color.primary};
          fill: ${(p) => p.theme.color.primary};
        }
      }
    }
  }
`;

function Footer({gap}) {
  return (
    <Wrap gap={gap}>
      <footer>
        <Grid cols={["1fr 2fr 1fr", "1fr 2fr 1fr", "1fr", "1fr"]}>
          <div className="first">
            <img src={logo} alt="" width="150" />
          </div>

          <Flex ai={["flex-start"]} jc={['center', 'center', 'left', 'left' ]} dir={['row', 'row', 'row', 'column']} gap={20} sx={{my: [0,0,30, 30]}}>
            <Link to="/about">About</Link>
            {/* <Divider className="h-sm" vertical border="gray400" />
            <Link to="/terms">Terms of Use</Link>
            <Divider className="h-sm" vertical border="gray400" />
            <Link to="/privacy">Privacy Policy</Link> */}
            <Divider className="h-sm" vertical border="gray400" />
            <Link to="/faq">FAQ</Link>
          </Flex>

          {/* <Box sx={{ ta: ["right", "right", "left", "left"] }} className="socials">
            <a
              href="https://www.facebook.com/profile.php?id=100090194446414"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/stitvestplatform/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="linkedin" />
            </a>
          </Box> */}

          <Box sx={{ gcs: [1, 1, 1, 1], gce: [4, 4, 1, 1] }}>
            <Box center w={1100}>
              <Divider border="gray400" h={60} />
            </Box>
          </Box>

          <Type
            sx={{
              fs: 16,
              c: "gray500",
              pt: 10,
              ta: "center",
              gcs: [2, 2, 1, 1],
              gce: [3, 3, 1, 1],
            }}
          >
            COPYRIGHT {new Date().getFullYear()} STITVEST. ALL RIGHTS RESERVED
          </Type>
        </Grid>
      </footer>
    </Wrap>
  );
}

export default Footer;
