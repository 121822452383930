import styled from "styled-components";

export default styled.div`
    width: 50px;
    height: 50px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-position: center;
    background-size: cover;
    background-image: url('${props => props.src}'); */
    background-color: ${props => props.theme.color[props.c || 'gray200']};
    img {
        width: 100%;
    }
`;