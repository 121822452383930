import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Link } from "../../ui";

const links = [
  { name: "About", path: "/about" },
  { name: "Opportunities", path: "/company/opportunities" },
  { name: "Marketplace", path: "/marketplace" },
  { name: "Earn", path: "/company/rewards" },
];

const StyledNav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 30px 0 0;
    li {
      padding: 0 25px;
      a {
        font-size: 16px;
        color: ${(props) => props.theme.color.black};
        text-decoration: none;
        text-transform: uppercase;
      }
      &:hover {
        a {
          color: ${(p) => p.theme.color.primary};
        }
      }
      &.selected {
        a {
          font-weight: 700;
        }
      }
      @media only screen and (max-width: 1200px) {
        padding: 0 20px;
      }
    }
  }
  &.md ul {
    display: block;
    text-align: right;
    margin-top: 80px;
    margin-right: 14px;
    li {
      padding: 10px 0;
    }
  }
`;

function Nav(props) {
  const location = useLocation();
  return (
    <StyledNav {...props}>
      <ul>
        <li className={location.pathname === "/" ? "selected" : ""}><Link to="/">Home</Link></li>
        {links.map((link) => (
          <li
            key={link.name}
            className={
              location.pathname.startsWith(`${link.path}`)
                ? "selected"
                : undefined
            }
          >
            <Link to={link.path}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </StyledNav>
  );
}

export default Nav;
