import { useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  InputText,
  Type,
} from "../../../ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useProfile } from "../provider";

const schema = Yup.object().shape({
  address: Yup.string().required("Required"),
});

function DirectUSDTTransfer({ onSuccess }) {
  const [trust, setTrust] = useState(false);

  const {
    state: { selected: profile, status },
    setPrivateKey,
    resetPrivateKey,
  } = useProfile();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = ({ status, errors: e }) => {
    console.log("error", errors);
    console.log("status", status);
  };

  const onSubmit = async (values) => {
    try {
      await setPrivateKey(values.address);
      reset();
      onSuccess();
    } catch (e) {
      if (e.status === 400) handleError(e);
    }
  };

  const handleResetPrivateKey = () => {
    try {
      resetPrivateKey();
      setTrust(false);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Box
      sx={{ w: [680, 680, 680, "100%"], py: 20 }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {!Boolean(profile.hasPrivateKey) ? (
        <Box sx={{ mb: 20 }}>
          <Checkbox
            label="Trust Stitvest (only for legal entity owners)"
            name="trust"
            id="trust"
            inputProps={{
              onChange: (e) => setTrust(e.target.checked),
              value: { trust },
            }}
          />
        </Box>
      ) : (
        <>
          <Flex jc="flex-start" gap={10}>
            <Type sx={{ fw: 300 }}>Allowed</Type>{" "}
            <Icon name="checkCircleSolid" c="secondary" w={22} />
          </Flex>
          <Button
            size="small"
            type="button"
            loading={status === "resetting private key"}
            // iconName="xmark"
            variant="outlined"
            onClick={handleResetPrivateKey}
            sx={{ mt: 20 }}
          >
            Revoke permission
          </Button>
        </>
      )}

      {Boolean(trust && !Boolean(profile.hasPrivateKey)) && (
        <>
          <InputText
            style={{ maxWidth: 600, marginBottom: 20 }}
            label="Private Wallet Address"
            id="private-wallet-address"
            placeholder="Enter private key"
            size="small"
            error={!!errors.address}
            msg={errors.address?.message}
            inputProps={{
              ...register("address"),
            }}
          />
          <Button
            size="small"
            type="submit"
            loading={isSubmitting}
            bg="secondary"
          >
            Send
          </Button>
        </>
      )}
    </Box>
  );
}

export default DirectUSDTTransfer;
