import styled from "styled-components";
import { Flex, Type } from "../ui";

const SFlex = styled(Flex)`
  img {
    border-radius: 5px;
  }
  .options & {
    padding: 10px 15px;
    border-top: #444 1px solid;
    span {
      font-size: 18px;
    }
    img {
      width: 30px;
      border-radius: 2px;
    }
  }
`;

const SelectCountryItem = ({ c }) => {
  return (
    <SFlex jc="flex-start">
      <img
        width={55}
        src={`https://www.countryflagicons.com/FLAT/64/${c.code}.png`}
        alt={c.name}
      />
      <Type sx={{ ml: 10, fs: 20, c: "gray400" }} as="span">
        {c.dial_code}
      </Type>
    </SFlex>
  );
};

export default SelectCountryItem;