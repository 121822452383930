import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useEvents() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  subscribed: [],
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
    case "set_subscribed":
      let subscribed = [...state.subscribed];
      if (action.payload.intrested && !subscribed.includes(action.payload.id))
        subscribed.push(action.payload.id);
      else subscribed = subscribed.filter((id) => id !== action.payload.id);
      return { ...state, subscribed };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function EventProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    (cid = null) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });

        let data = {};
        if (cid) data.companyID = cid;

        try {
          const resData = await req(
            "event/" + new URLSearchParams(data),
            null,
            {},
            true
          );
          dispatch({ type: "set_list", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const isSubscribed = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: "fetching" });
          const res = await req(`event/${id}/subscribe/`, null, {}, true);
          dispatch({ type: "set_subscribed", payload: {id, intrested: res.data.isSubscribed} });
          resolve(res.data);
          resolve();
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const subscribe = useCallback(
    async (id, sub) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: "subscribing" });
          const res = await req(
            `event/${id}/subscribe/`,
            null,
            { method: "POST" },
            true
          );
          dispatch({ type: "set_subscribed", payload: {id, intrested: true} });
          resolve(res.data);
        } catch (e) {
          console.log(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        subscribe,
        isSubscribed,
      }}
    >
      {children}
    </Context.Provider>
  );
}
