import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputPincode from "../../components/InputPincode";
import { Box, Button, InputText, Type } from "../../ui";
import { useAuth } from "../../services/auth";

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  newPassword: Yup.string()
    .required("required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPass: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});

function ResetPassForm({ onSuccess }) {
  const [error, setError] = useState(null);
  const { changePass2 } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "INCORRECT_OLD_PASSWORD")
        setError("Incorrect old password.");
    } else {
      setError("An error has occured, please try again.");
    }
  };

  const onSubmit = async (values) => {
    try {
      await changePass2({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      handleError(e);
      console.log("e", e);
    }
  };

  return (
    <Box
      sx={{ w: [740, 740, 740, "100%"], px: 30 }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Type sx={{ c: "red", ta: "center", mb: 40 }}>{error}</Type>
      <InputText
        placeholder="Old Password"
        type="password"
        error={!!errors.oldPassword}
        msg={errors.oldPassword?.message}
        inputProps={{
          ...register("oldPassword"),
          autoFocus: true,
        }}
      />
      <InputText
        placeholder="New Password"
        type="password"
        error={!!errors.newPassword}
        msg={errors.newPassword?.message}
        inputProps={{
          ...register("newPassword"),
        }}
      />
      <InputText
        placeholder="Confirm New Password"
        type="password"
        error={!!errors.confirmPass}
        msg={errors.confirmPass?.message}
        inputProps={{
          ...register("confirmPass"),
        }}
      />
      <Button
        className="large"
        type="submit"
        loading={isSubmitting}
        style={{ width: "100%" }}
      >
        Submit New Password
      </Button>
    </Box>
  );
}

export default ResetPassForm;
