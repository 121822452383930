import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { Box, Grid, Icon, Link, Slider, Type } from "../../ui";
import Timer from "./companyView/Timer";
import TokenLeft from "./companyView/TokenLeft";
import { useCompanies } from "./provider";
import theme from "../../theme";
import Info from "./companyView/Info";
import Invest from "./companyView/Invest";
import Overview from "./companyView/Overview";
import Materials from "./companyView/Materials";
import { useAuth } from "../../services/auth";
import DisplayBalance from "./companyView/DisplayBalance";
import ListEvents from "../events/ListEvents";
import { useEvents } from "../events/provider";
import styled from "styled-components";
import TransferType from "./companyView/TransferType";

const SliderBg = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.color.gray200};
  img {
    width: 100%;
    max-height: calc(100vw * 0.7);
    height: calc(100vh - 120px);
    object-fit: cover;
    object-position: center;
  }
`;

function CompanyView() {
  const { user } = useAuth();
  const { id } = useParams();

  const {
    state: { list },
    fetchList,
  } = useEvents();

  const {
    state: { selected, financialInfo },
    fetchOne,
    fetchFinancialInfo,
  } = useCompanies();

  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id, user]);

  useEffect(() => {
    if (user) fetchFinancialInfo(id);
  }, [fetchFinancialInfo, id, user]);

  useEffect(() => {
    if (user) fetchList();
  }, [fetchList, user]);

  const Slide = ({ data, slideRef }) => {
    return (
      <SliderBg sx={{ w: "100%" }} ref={slideRef}>
        <img src={`${user.tokens.awsData.s3PublicURI}${data}`} alt="" />
      </SliderBg>
    );
  };

  const DefaultSlide = ({ data, slideRef }) => {
    return (
      <Box
        sx={{
          w: "100%",
          bgc: "gray800",
          ta: "center",
          pt: 120,
          pb: [280, 280, 280, 120],
        }}
        ref={slideRef}
      >
        <Type var="h1" as="h1" sx={{ c: "white" }}>
          {data.companyName}
        </Type>
        <Type sx={{ c: "white", mt: 15 }}>
          <Link to="/company/opportunities">Opportunities</Link>
          <Box as="span" sx={{ px: 8 }}>
            <Icon name="arrowRight" w={10} c="white" />
          </Box>
          <Type as="strong" sx={{ fw: 600, c: "white" }}>
            Invest
          </Type>
        </Type>
      </Box>
    );
  };

  return (
    <Layout>
      {Boolean(financialInfo) && (
        <>
          {financialInfo.imageSlider?.length ? (
            <Slider data={financialInfo.imageSlider} Slide={Slide} />
          ) : (
            <Slider data={[financialInfo]} Slide={DefaultSlide} />
          )}
        </>
      )}

      <Timer endDate={new Date(Number(financialInfo?.closingTime * 1000))} />

      {user?.user && (
        <TokenLeft
          maxToken={financialInfo?.tokenLeft || 0}
          totalSold={financialInfo?.tokenSold}
        />
      )}

      <Grid
        cols={["3fr 2fr", "3fr 2fr", "3fr 2fr", "100%"]}
        rows="fit-content fot-content"
        gap={40}
        center
        w={theme.pageWidth}
        sx={{ my: 100, mx: "auto", px: 30 }}
      >
        {Boolean(financialInfo?.allowDirectTransfer) && <TransferType companyId={selected.id} />}
        {user?.user && (
          <DisplayBalance
            sx={{
              gcs: Boolean(financialInfo?.allowDirectTransfer) ? 2 : 1,
              gce: 3,
              d: "grid",
            }}
          />
        )}

        <Info financialInfo={financialInfo} />
        {Boolean(financialInfo) && <Invest financialInfo={financialInfo} />}
      </Grid>

      <Overview company={{ ...financialInfo, ...selected }} />

      {user && <Materials id={id} />}

      {Boolean(list.length) && <ListEvents list={list} />}
    </Layout>
  );
}

export default CompanyView;
