import { Box, Type } from "../../../ui";

const Section = ({ title, body, children }) => {
  return (
    <Box sx={{ my: 30, mw: 600 }}>
      {title && <Type var="h5" as="h5" sx={{ mb: 0 }}>
        {title}
      </Type>}
      {body && (
        <Type var="p" as="p" sx={{ my: 6 }}>
          {body}
        </Type>
      )}
      {children}
    </Box>
  );
};

export default Section;
