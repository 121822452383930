import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useAuth } from "../../services/auth";
import { Box, Button, Card, Flex, Grid, Icon, InputText, Type } from "../../ui";
import Carousel from "../../ui/carousel/Carousel";
import {
  currency,
  downloadFile,
  getStatus,
  transformDate,
} from "../../utilities/functions";
import useImageLink from "../../utilities/hooks/useImageLink";
import { useCompanies } from "./provider";
import useMedia from "../../utilities/hooks/useMedia";
import Section from "../profile/profile/Section";
import MintTokensForm from "./companyView/MintTokensForm";
import { useUtils } from "../../services/utilsProvider";

const fields = [
  { label: "Industry", value: "industry" },
  { label: "Website", value: "website" },
  { label: "Headquarter", value: "headquarter" },
  { label: "Geographic Market", value: "geographicMarket" },
  {
    label: "Overview",
    value: "description",
    type: "textarea",
    inputProps: { style: { height: "180px", outline: "#ddd 1px solid" } },
  },
];

const investFields = [
  { label: "Token Price", value: "tokenPrice", fn: currency },
  { label: "Target Amount", value: "target", fn: currency },
  { label: "Capital Raised", value: "tokenSold", fn: currency },
  { label: "Minimum Invest", value: "minPurchase", fn: currency },
  { label: "Start Date", value: "openingTime", type: "date" },
  { label: "End Date", value: "closingTime", type: "date" },
];

function DisplayStatus({ data }) {
  return (
    <Flex sx={{ my: 30 }}>
      <Type as="span" sx={{ fs: 18, fw: 600, pr: 10 }}>
        Status
      </Type>{" "}
      <Type
        sx={{
          fs: 18,
          c: data?.status === 0 ? "gray500" : "secondary",
          fw: 900,
        }}
        as="span"
      >
        {getStatus(data?.status)}
      </Type>
    </Flex>
  );
}

const Slide = ({ data }) => {
  const file = useImageLink(data.documentKey);

  return (
    <Flex gap={15} jc="flex-start">
      <Icon name="pdf" w="50" style={{ fill: "#e2574c" }} />
      <Box>
        <Type
          style={{ overflow: "hidden" }}
          sx={{ m: "0 0 10px 0", mw: "200px" }}
        >
          {data.documentName}
        </Type>
        <Button
          onClick={() => downloadFile(file, data.documentName)}
          size="small"
          variant="outlined"
          iconName="download"
        >
          Download
        </Button>
      </Box>
    </Flex>
  );
};

function OwnCompanyView() {
  const { user } = useAuth();
  const id = user.user.companyID;
  const navigate = useNavigate();
  const media = useMedia();
  const notify = useUtils();

  const {
    state: { selected, financialInfo, docs },
    fetchOne,
    fetchFinancialInfo,
    fetchDocs,
  } = useCompanies();

  const img = useImageLink(selected?.imageURL);

  const onMintTokenSuccess = () => {
    notify("Operation executed successfully");
  };

  useEffect(() => {
    if (!id) return;
    fetchOne(id);
  }, [fetchOne, id, user, selected]);

  useEffect(() => {
    fetchFinancialInfo(id);
  }, [fetchFinancialInfo, id]);

  useEffect(() => {
    fetchDocs(id);
  }, [fetchDocs, id]);

  const visibleSlides = () => {
    switch (media) {
      case "xs":
        return 1;
      case "sm":
        return 2;
      case "md":
        return 3;
      default:
        return 5;
    }
  };

  return (
    <Layout>
      <Box w={1350} center>
        <Card sx={{ my: 60 }} title="Legal Entity" onClose={() => navigate(-1)}>
          {/* section */}
          <DisplayStatus data={selected} />

          {/* section */}
          <Flex
            jc="none"
            ai="flex-top"
            dir={["row", "column-reverse", "column-reverse", "column-reverse"]}
            gap={["80px", "80px", "40px", "40px"]}
            sx={{ mb: 20, mt: 60 }}
          >
            <Box sx={{ w: [750, 750, 750, "auto"] }}>
              <Type var="h4" as="h4" sx={{ mb: 20 }}>
                Legal Entity Data
              </Type>
              <Grid cols={["1fr 1fr", "1fr 1fr", "1fr 1fr", "1fr"]} colGap={30}>
                {fields.map((f, i) => {
                  return (
                    <InputText
                      key={i}
                      {...f.props}
                      label={f.label}
                      size="medium"
                      type={f.type || "text"}
                      inputProps={{
                        value:
                          { ...financialInfo, ...selected }?.[f.value] || "",
                        readOnly: true,
                        style: { outline: "#ddd 1px solid" },
                        ...f.inputProps,
                      }}
                    />
                  );
                })}
              </Grid>
            </Box>

            {Boolean(img) && (
              <Box sx={{ mb: 20 }}>
                <img src={img} height="200" alt="" />
              </Box>
            )}
          </Flex>

          {/* section */}
          <Type var="h4" as="h4" sx={{ mt: 0 }}>
            Mint Puchased Tokens
          </Type>

          <Section body="This section allows you to register a purchase and mint tokens to investors for offline purchases.">
            <MintTokensForm onSuccess={onMintTokenSuccess} />
          </Section>

          {/* section */}
          {docs && (
            <>
              <Type var="h4" as="h4" sx={{ mt: 40 }}>
                Materials
              </Type>
              <Section body="Terms and Information Your Investors Should Know">
                {docs.length ? (
                  <Carousel
                    style={{ display: "block", marginTop: 40 }}
                    options={{
                      totalSlides: visibleSlides(),
                      slides: docs,
                      slide: Slide,
                      itemW: 220,
                      nav: {
                        next: (
                          <Icon name="circleArrowRight" c="primary" w={32} />
                        ),
                        prev: (
                          <Icon name="circleArrowLeft" c="primary" w={32} />
                        ),
                      },
                    }}
                  />
                ) : (
                  <Type
                    var="p"
                    as="p"
                    sx={{ mb: 30, fstyle: "italic", c: "gray500", mt: 10 }}
                  >
                    No document available.
                  </Type>
                )}
              </Section>
            </>
          )}

          {/* section */}
          <Box sx={{ w: [750, 750, 750, "auto"], mb: 30, mt: 40 }}>
            <Type var="h4" as="h4" sx={{ mb: 30 }}>
              Investment Details
            </Type>
            <Grid cols={["1fr 1fr", "1fr 1fr", "1fr 1fr", "1fr"]} colGap={30}>
              {investFields.map((f, i) => {
                if (f.type === "date")
                  f.fn = (v) =>
                    v ? transformDate(new Date(Number(v * 1000))) : "";
                const fn = f.fn || ((v) => v);

                return (
                  <InputText
                    key={i}
                    {...f.props}
                    label={f.label}
                    size="medium"
                    type={f.type || "text"}
                    inputProps={{
                      value: fn(
                        { ...financialInfo, ...selected }?.[f.value] || ""
                      ),
                      readOnly: true,
                      style: { outline: "#ddd 1px solid" },
                      ...f.inputProps,
                    }}
                  />
                );
              })}
            </Grid>
          </Box>
        </Card>
      </Box>
    </Layout>
  );
}

export default OwnCompanyView;
