import * as yup from "yup";

// Deprecated! 
// used before adding proper date validations, check parseDate
export function emptyDate(value, originalValue) {
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return originalValue?.split("T")[0];
}

// return null if date is not set, else parse the string date to js date
export function parseDate(value, originalValue) {
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return null;
  }
  return new Date(originalValue);
}

export function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return Number(value);
}

// used as a reducer of a config array
export function createYupSchema(schema, config) {
  const { name, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if(validationType === 'array' && type === 'of') {
      validator = validator[type](params(yup));
      return;
    }
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[name] = validator;
  return schema;
}