import { useAuth } from "services/auth";
import theme from "theme";
import { Flex, Icon, Link, Type } from "ui";

function CheckNetwork() {
  const { user } = useAuth();
  return (
    <>
      {window.ethereum.networkVersion !==
        user.tokens.blockchainData.chainID && (
        <Flex
          jc="space-between"
          sx={{
            my: 80,
            mw: 700,
            w: theme.pageWidth,
            mx: "auto",
            px: 30,
            bgc: "red",
            py: 5,
            br: 999,
            c: "white",
          }}
        >
          <Flex gap="10" sx={{ mr: 15 }}>
            <Icon name="circleExclamation" c="white" w={20} />{" "}
            <Type var="p" sx={{ my: 10, c: "white" }}>
              You must be connected to {user.tokens.blockchainData.networkName}{" "}
              network
            </Type>
          </Flex>
          <Link style={{ color: "white", fontWeight: 600, minWidth: 110 }} to={"/profile"}>
            Go to profile
          </Link>
        </Flex>
      )}
    </>
  );
}

export default CheckNetwork;
