import styled, { keyframes } from "styled-components";

const oscillate = keyframes`
  from {
    left: 0%;
  }

  50% {
    left: calc(100% - 40px);
  }

  to {
    left: 0%;
  }
`;

const Loader = styled.span`
  position: fixed;
  top: 0;
  height: 2px;
  width: 40px;
  background-color: ${(p) =>
    p.theme.color[p.color]};
  animation: ${oscillate} 3s linear infinite;
`;

function LoadingPage({ color = "gray700" }) {
  return <Loader className="loading" color={color} />;
}

export default LoadingPage;
