import { useRef } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputPincode from "../../../components/InputPincode";
import { useAuth } from "../../../services/auth";
import { useUtils } from "../../../services/utilsProvider";
import { Box, Button, Flex, Type } from "../../../ui";
// import Dialog from "../../ui/Dialog";

function VerifyEmailForm() {
  const [timer, setTimer] = useState(-1);
  let { search } = useLocation();
  const params = new URLSearchParams(search);

  const [error, setError] = useState(false);
  // const [show, setShow] = useState(0);
  // const [email, setEmail] = useState(params.get("email") || "");
  const [pincode, setPincode] = useState(new Array(6).fill(""));

  const navigate = useNavigate();

  const { notify } = useUtils();
  const { verifyEmail, addAccount } = useAuth();

  const myInterval = useRef();

  // useEffect(() => {
  //   if (isVerified()) navigate("/account-type");
  // });

  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "INCORRECT_PIN_CODE")
        setError(
          <Type sx={{ c: "red", ta: "center" }}>
            Incorrect or expired pincode.
          </Type>
        );
      else if (e.message === "INVALID_USER") {
        notify("Alrady verified. Please login.");
        navigate("/login");
      }
    }
  };

  async function onSubmit() {
    if (pincode.length < 6) setError("Pincode must be 6 letters");

    try {
      await verifyEmail({
        pinCode: pincode.join(""),
        email: params.get("email"),
      });

      // These data is used by resend pincode (to resend pincode you call register again)
      localStorage.removeItem('registerData');

      notify("Your email address has been verified.");
      const accType = localStorage.getItem('accType');
      localStorage.removeItem('accType');

      if(accType)
        if(accType === 'investor') {
          navigate("/verify-identity");
          return;
        } else if (accType === 'legal entity') {
          navigate("/company/new");
          return;
        }
      
      navigate("/account-type");
      
    } catch (e) {
      window.scrollTo(0, 0);
      handleError(e);
      console.log(e);
    }
  }

  return (
    <Box sx={{ w: [750, 750, 750, 320] }} className={error ? "error" : ""}>
      <Flex sx={{ mb: 20 }}>
        <Type
          var="p"
          sx={{ ta: "center", c: "gray800", lh: 36, fw: 300, mw: 420 }}
        >
          An email with a Verification Code was just sent to
          <br />
          {params.get("email")}{" "}
        </Type>
      </Flex>

      <InputPincode {...{ pincode, setPincode }} />

      {error && (
        <Type var="p" sx={{ mt: -15, mb: 40 }} className="msg">
          {error}
        </Type>
      )}
      <Flex sx={{ mt: 20, mb: 40 }}>
        {timer === -1 && (
          <Button
            disable={timer !== 0}
            variant="text"
            size="small"
            onClick={() => {
              addAccount(JSON.parse(localStorage.getItem('registerData')));
              setPincode(new Array(6).fill(""));
              setError(false);

              let i = 10;
              setTimer(i);

              myInterval.current = setInterval(() => {
                if (i === 0) {
                  setTimer(-1);

                  clearInterval(myInterval.current);
                } else {
                  i--;
                  setTimer((t) => t - 1);
                }
              }, 1000);
            }}
          >
            Resend code
          </Button>
        )}
        {timer !== -1 && (
          <Type sx={{ c: "secondary" }}>
            Code has been sent. you can resend after {timer}s
          </Type>
        )}
      </Flex>
      <Button className="large" style={{ margin: "0 auto" }} bg="secondary" onClick={onSubmit}>
        Verify
      </Button>
    </Box>
  );
}

export default VerifyEmailForm;
