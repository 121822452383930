import { Route } from "react-router-dom";
import CompanyView from "./View";
import Marketplace from "./Marketplace";
import List from "./List";
import { RequireAuth } from "services/auth";

export default (
  <Route path="marketplace" element={<Marketplace />}>
    <Route index element={<List />} />
    <Route
      path="company/:id"
      element={
        <RequireAuth>
          <CompanyView />
        </RequireAuth>
      }
    />
    {/* <Route
      path="new"
      element={
        <RequireAuth>
          <AddCompany />
        </RequireAuth>
      }
    />
    <Route
      path=":id/live-investment"
      element={
          <CompanyView />
      }
    />
    <Route
      path="opportunities"
      element={
          <Opportunities />
      }
    /> */}
  </Route>
);
