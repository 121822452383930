import styled, { css } from "styled-components";
import Box from "./Box";

const Block = styled(Box)`
  margin: ${(p) => p.theme.spaces.blockY}px auto;
  ${p => p.miniTop && css`
    margin-top: ${p.theme.spaces.blockY/2}px;
  `}
  max-width: 1350px;
  padding: 0 30px;
  ${p => p.mini && css`
    margin: ${p.theme.spaces.blockY/2}px auto;
  `}
`;

export default Block;
