import { useCallback, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  InputText,
  Select,
  Pagination,
  Type,
} from "../../ui";
import CompanyBox from "./companyBox/CompanyBox";
import { useCompanies } from "./provider";

function Opportunities() {
  const [status, setStatus] = useState(3);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const { fetchList } = useCompanies();

  useEffect(() => {
    (async () => {
      const res = await fetchList({ statusID: status });
      setData(res);
    })();
  }, [fetchList, status]);

  const handleStatusChange = useCallback(
    (s) => {
      if (status === s) return;
      setStatus(s);
    },
    [status]
  );

  return (
    <Layout>
      <Divider />
      <Flex
        jc={['center', null, null, "flex-start"]}
        ai={['center', null, null, "flex-start"]}
        dir={['row', null, null, "column"]}
        gap={["40px", "40px", "30px", "20px"]}
        sx={{ mt: 40, mb: 20, mx: 40 }}
      >
        <Button
          variant="outlined"
          bg="gray500"
          size="small"
          className={status === 3 ? "selected" : ""}
          onClick={() => handleStatusChange(3)}
        >
          Live Opportunities
        </Button>
        <Button
          variant="outlined"
          bg="gray500"
          size="small"
          className={status === 2 ? "selected" : ""}
          onClick={() => handleStatusChange(2)}
        >
          Upcoming Opportunities
        </Button>
        <Button
          variant="outlined"
          bg="gray500"
          size="small"
          className={status === 1 ? "selected" : ""}
          onClick={() => handleStatusChange(1)}
        >
          Coming Soon
        </Button>
      </Flex>
      <Box w={1430} sx={{ px: 40 }} center>
        <Divider border="gray300" />
        <Flex
          jc={["space-between", null, null, "flex-start"]}
          ai={["center", null, null, "flex-start"]}
          dir={["row", null, null, "column"]}
          gap={[0, 0, 0, "30px"]}
          sx={{ mt: 30 }}
        >
          <InputText
            size="small"
            style={{ marginBottom: 0 }}
            placeholder="Search here"
            icon={<Icon name="search" c="gray600" w={24} />}
          />
          <span>
            <Flex gap={30}>
              <span>
                <Flex gap={15}>
                  <Icon name="sliders" c="white" w={24} />
                  <span>Fliter</span>
                </Flex>
              </span>
              <Select
                style={{ width: "150px" }}
                data={["name", "date"]}
                name="Sort by"
                size="small"
                noSeperator
                inputProps={{
                  placeholder: "Sort by",
                }}
              />
            </Flex>
          </span>
        </Flex>
      </Box>

      <Box w={1430} center sx={{ my: 40 }}>
        {Boolean(data.length) ? (
          <Grid
            cols={["1fr 1fr 1fr 1fr", "1fr 1fr 1fr", "1fr 1fr", "1fr"]}
            gap={0}
          >
            {data.map((company) => (
              <CompanyBox key={company.id} data={company} />
            ))}
          </Grid>
        ) : (
          <Type sx={{ ta: "center", my: 100 }}>No data available</Type>
        )}
      </Box>
      <Pagination
        style={{ justifyContent: "center" }}
        noInfo
        count={30}
        page={page}
        onPageChange={(p) => setPage(p)}
      />

      {/* <Box w={1350} center sx={{ mt: 200, mb: 100 }}>
        <UpcomingOportunities />
      </Box> */}
    </Layout>
  );
}

export default Opportunities;
