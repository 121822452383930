import styled from "styled-components";
import { Flex, Grid, Type } from "../../ui";
import Box from "../../ui/Box";
import Image1 from "../../assets/hp-features-1.svg";
import Image2 from "../../assets/hp-features-2.svg";
import Image3 from "../../assets/hp-features-3.svg";
import shapeColor from "../../assets/shape-color-3.png";
import cube from "../../assets/our-feature-cube.png";
import Carousel from "../../ui/carousel/Carousel";
import { useState } from "react";
import shape from "../../assets/our-feature-shape.svg";
import CarouselNav, { Next, Prev, Slide } from "./CarouselNav";
import BgImg from "../../ui/BgImg";
import Block from "../../ui/Block";

const SBox = styled(Box)`
  div {
    margin-bottom: 15px;
    span:first-child {
      color: ${p => p.theme.color.primary};
      font-size: 80px;
      border-right: ${p => p.theme.color.primary} 2px solid;
      display: inline-block;
      line-height: 70px;
      margin-right: 10px;
      padding-right: 5px;
      padding-left: 0;
      font-weight: 700;
      height: 76px;
    }
  }
`;

// const CarouselWrap = styled(Box)`
//   position: relative;
//   margin: -60px 120px 0 120px;
//   @media only screen and (max-width: 992px) {
//     margin: 0 120px;
//   }
//   @media only screen and (max-width: 420px) {
//     margin: 0 60px;
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     display: block;
//     width: 175%;
//     height: 100%;
//     background-image: url(${shape});
//     background-size: contain;
//     background-position: top center;
//     background-repeat: no-repeat;
//     top: 20px;
//     left: -100px;
//   }
// `;

const data = [
  {
    img: Image3,
    title: "QUICK AND EASY SIGN UP PROCESS",
    text: "Register on Stitvest & share your interests.",
  },
  {
    img: Image2,
    title: "FIND EXCITING OPPORTUNITIES",
    text: "Find goods, assets, art, and other investment prospects.",
  },
  {
    img: Image1,
    title: "START YOUR INVESTMENT",
    text: "Invest and buy assets with security tokens.",
  },
];

function Features() {
  // const [page, setPage] = useState(0);

  return (
    // <BgImg
    //   center
    //   sx={{
    //     mt: [60, 60, 80, 80],
    //     mb: [40, 40, 80, 80],
    //     mw: 1350,
    //   }}
    //   img1={shapeColor}
    //   img2={cube}
    //   pos2={{
    //     w: 240,
    //     h: 240,
    //     b: -120,
    //   }}
    //   pos1={{
    //     w: 300,
    //     b: -170,
    //     l: 100,
    //   }}
    // >
    <Block miniTop>
      <Grid cols={["1fr 1fr 1fr", "1fr 1fr 1fr", "100%", "100%"]} gap={60} sx={{w: '100%'}}>
        {data.map((item, i) => (
          <SBox sx={{mxw: 400}} key={i}>
            <Flex jc="flex-start" ai="flex-top">
              <span>{i + 1}</span>
              <span>
                <Type var="h1" sx={{fs: [32,32,32,28], lh: 38}}>
                  {item.title}
                </Type>
                <Type sx={{mt: 15}}>{item.text}</Type>
              </span>
            </Flex>
          </SBox>
        ))}

        {/* <Type var="display" sx={{ px: [0, 60, 60, 30] }}>
            Our
            <br /> Features
          </Type> */}

        {/* <CarouselNav data={data} page={page} setPage={setPage} /> */}

        {/* <CarouselWrap>
            <Carousel
              className="carousel"
              options={{
                totalSlides: 1,
                slides: data,
                slide: Slide,
                gap: 1,
                page,
                setPage,
                itemW: 220,
                nav: {
                  style: {
                    textAlign: "center",
                    marginTop: "100px",
                  },
                  next: <Next />,
                  prev: <Prev />,
                },
              }}
            />
          </CarouselWrap> */}
      </Grid>
    </Block>
    // </BgImg>
  );
}

export default Features;
