import Layout from "../../layout/Layout";
import { useAuth } from "../../services/auth";
import { Box, Flex, Slider, Type } from "../../ui";
import AccountType from "./AcountType";
import ComingSoon from "./ComingSoon";
import Features from "./Features";
import Opportunities from "./Oportunities";
import Subscribe from "./Subscribe";
import UpcomingOportunities from "./UpcomingOportunities";
import slide1Img from "../../assets/slide-1.png";
import Slide from "./Slide";
import styled from "styled-components";
import colorShape2 from "../../assets/shape-color-2.png";
import colorShape3 from "../../assets/shape-color-3.png";
import ImportantFacts from "../../components/ImportantFacts";
import Block from "../../ui/Block";

const Wrap = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 500px;
    height: 100%;
    background-image: url(${colorShape2});
    background-size: contain;
    background-repeat: no-repeat;
    top: 100px;
    left: 0px;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 600px;
    height: 600px;
    background-image: url(${colorShape3});
    background-size: contain;
    background-repeat: no-repeat;
    top: 0px;
    right: -200px;
    z-index: -1;
  }
`;

const data = [
  {
    img: slide1Img,
    title: (
      <>
        <strong>INVESTMENT</strong> PLATFORM OF THE FUTURE!
      </>
    ),
    subTitle:
      "A new blockchain based fundraising platform for investors and traders alike to connect and trade digital goods and assets.",
    bgSize: "35%",
    bgPosition: "62% 10%",
  },
];

const solutions = [
  {
    title: "Asset and Fund Tokenization",
    desc: "Secure your traded and non-traded assets for increased liquidity.",
  },
  {
    title: "Staking Rewards Program",
    desc: "Earn rewards and generate sustainable income.",
  },
  {
    title: "A Global Marketplace",
    desc: "Connect with a wide range of trusted investors across the globe.",
  },
];

function Home() {
  const { user } = useAuth();

  return (
    <Layout>
      <Wrap>
        <Slider data={data} Slide={Slide} />
      </Wrap>

      <Features />
      <Opportunities />
      {/* <Companies /> */}
      {/* <BgSlops
        sx={{
          mt: [-160, -160, -320, -320],
          mb: -140,
          pt: [400, 400, 240, 240],
          pb: [140, 140, 100, 100],
        }}
      > */}
      {/* <Solutions /> */}

      <Block>
        <Flex dir={['row', 'row', 'row', 'column']} gap={60}>
          {solutions.map((item, i) => (
            <Box sx={{ta: "center"}} key={i}>
              <Type var="h4" as="h4" sx={{c: "secondary", mb: 15}}>
                {item.title}
              </Type>
              <Type sx={{mxw: 350}}>{item.desc}</Type>
            </Box>
          ))}
        </Flex>
      </Block>

      <UpcomingOportunities />
      {/* </BgSlops> */}
      {/* <OurMarketplace /> */}
      <Block>
        <ImportantFacts />
      </Block>
      {!user && <AccountType />}
      <ComingSoon />
      <Subscribe />
    </Layout>
  );
}

export default Home;
