import LayoutMasked from "../../layout/LayoutMasked";
import { Flex, Icon, Type } from "../../ui";

function ResetSuccess() {
    return <LayoutMasked noLogo>
        <Flex gap={200}>
            <div>
                <Type var="h2" sx={{mb: 25}}>PASSWORD CHANGED</Type>
                <Type var="h3">Your password has been reset successfully</Type>
            </div>
            <div>
                <Icon name="checkCircle" w={320} c="white" />
            </div>
        </Flex>
    </LayoutMasked>
}

export default ResetSuccess;