/* global BigInt */
import { useEffect, useState } from "react";
import styled from "styled-components";
import Web3 from "web3";
import { useAuth } from "services/auth";
import { useUtils } from "services/utilsProvider";
import {
  Box,
  Button,
  Flex,
  Icon,
  InputText,
  Link,
  LoadingMask,
  Type,
} from "ui";
import {
  currency,
  dateFromNow,
  delay,
  enforceMinMax,
} from "utilities/functions";
import useMetamask from "utilities/hooks/useMetamask";
import useWeb3 from "utilities/hooks/useWeb3";
import { useCompanies } from "../provider";
import { useProfile } from "../../profile/provider";

const InvestBox = styled(Box)`
  text-align: center;
  background: rgb(102, 205, 245);
  background: linear-gradient(
    180deg,
    rgba(78, 191, 97, 1) 0%,
    rgba(173, 217, 185, 0.8) 100%
  );
  border-radius: ${(p) => p.theme.borderRadius}px;
  padding: 80px 40px 100px 40px;
  position: relative;
  button {
    margin: 20px auto;
  }
  .quantity {
    margin: 40px 0;
    font-size: 30px;
    font-weight: bold;
    span {
      background: ${(p) => p.theme.color.white};
      border-radius: 999px;
      width: 40px;
      height: 40px;
      color: ${(p) => p.theme.color.bgPrimary};
      font-size: 25px;
      font-weight: 600;
      line-height: 0;
      cursor: pointer;
      user-select: none;
      &:hover {
        background: ${(p) => p.theme.color.gray50};
      }
      &:active {
        transform: scale(0.95);
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .note {
    font-size: 16px;
    text-align: left;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 75%;
    span {
      width: 34px;
      height: 34px;
      background-color: ${(p) => p.theme.color.yellow};
      color: ${(p) => p.theme.color.black};
      border-radius: 100px;
      line-height: 0;
    }
  }
`;

function Invest({ financialInfo }) {
  const minInv = Number(financialInfo?.minPurchase);
  const limit = Number(financialInfo?.purchaseLimit);

  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(minInv);
  const [activeUsdt, setActiveUsdt] = useState(null);

  const { buyToken, getTokenBalance } = useWeb3();
  const { connectMetamask } = useMetamask();
  const { user } = useAuth();

  const {
    state: { balance, usdt },
    buyTokensInterWallet,
    dispatch,
  } = useCompanies();

  const {
    state: { networkBalance, networks },
    fetchNetworkBalance,
  } = useProfile();

  const { notify } = useUtils();

  useEffect(() => {
    if (networkBalance) setActiveUsdt(networkBalance.balance);
    else setActiveUsdt(Web3.utils.fromWei(usdt.toString(), "mwei"));
  }, [usdt, networkBalance]);

  const checkAmount = (v) => {
    const { stitvestRate: rate, tokenPrice } = financialInfo;

    if (v < minInv) {
      notify(`Minimum purshase quantity is ${minInv} tokens`, "alert");
      return false;
    }

    if (v > limit - Web3.utils.fromWei(balance, "mwei")) {
      notify(
        `Total purshase amount for this company connot exceed ${limit} tokens`,
        "alert"
      );
      return false;
    } else {
      const cost = v * Number(tokenPrice);
      if (Math.floor(cost + (cost * Number(rate)) / 100) > activeUsdt) {
        notify(
          `You don't have enough USDT in your balance to buy ${v} tokens`,
          "alert"
        );
        return false;
      }
    }

    return true;
  };

  const handleQuantity = (type) => {
    const { stitvestRate: rate, tokenPrice } = financialInfo;

    let q = quantity;
    if (type === "dec") {
      if (q > minInv) q--;
      else {
        notify(`Minimum purshase quantity is ${minInv} tokens`, "alert");
        // q = 20;
        q = minInv;
      }
    } else {
      const lim = limit - Web3.utils.fromWei(balance.toString(), "mwei");
      const cost = (q + 1) * Number(tokenPrice);

      if (q < lim) {
        if (Math.floor(cost + (cost * Number(rate)) / 100) <= activeUsdt) q++;
        else {
          notify(`You don't have enough USDT to buy ${q + 1} tokens`, "alert");
          var afford = Math.floor(
            activeUsdt /
              (Number(tokenPrice) + (Number(tokenPrice) * Number(rate)) / 100)
          );
          q = afford < minInv ? minInv : afford;
        }
      } else {
        notify(
          `Total purshase amount for this company connot exceed ${limit} tokens`,
          "alert"
        );
        q = lim;
      }
    }
    setQuantity(q);
  };

  const handleBuyToken = async () => {
    const { stitvestRate: rate, tokenPrice } = financialInfo;

    if (!checkAmount(Number(quantity))) return;
    try {
      setLoading(true);
      await connectMetamask();

      if (networkBalance) {
        const network = networks.find(
          (n) => n.chainID === Number(networkBalance.chainID)
        );
        await buyTokensInterWallet(
          financialInfo.companyID,
          network.internalWallet,
          network.chainID,
          quantity
        );
        dispatch({
          type: "set_balance",
          payload: await getTokenBalance(network),
        });
        fetchNetworkBalance(network);
        notify(
          `${quantity} tokens has been added successfully to your account.`
        );
      } else {
        const usdt =
          BigInt(quantity) * BigInt(tokenPrice) * BigInt(Math.pow(10, 6));
        await buyToken(
          usdt + (usdt * BigInt(rate * 100)) / BigInt(10000),
          BigInt(quantity) * BigInt(Math.pow(10, 6))
        );
        let timer = 4;
        notify(
          `${quantity} tokens has been added successfully to your account. This page will reload in a couple of seconds`
        );
        setTimeout(() => {
          window.location.reload();
        }, timer * 1000);
      }
    } catch (e) {
      console.log(e);
      const msg = e.errors?.message || e.message;
      notify("An error has accured: " + msg, "alert");
    } finally {
      setLoading(false);
    }
  };

  function displayTimeLeft() {
    const days = Number(dateFromNow(financialInfo?.closingTime * 1000)[0]);
    const hours = Number(dateFromNow(financialInfo?.closingTime * 1000)[1]);

    if (days < 0 || hours < 0) {
      return "0 days";
    }

    if (days > 0) {
      return days + " days";
    }
    if (hours > 0) {
      return hours + " hours";
    }
    return "less than an hour";
  }

  const isVerfied = user?.user?.isVerified;
  const isConnected = usdt !== -2 && balance !== -2; // is connected to MetaMask

  return (
    <InvestBox>
      <Type var="h3" as="h3" sx={{ fw: "400" }}>
        Total Price
      </Type>
      <Type var="h2" as="h2" sx={{ c: "black" }}>
        USDT{" "}
        {currency(
          Number(financialInfo.tokenPrice) * quantity +
            (Number(financialInfo.tokenPrice) *
              quantity *
              Number(financialInfo.stitvestRate)) /
              100,
          null,
          null
        )}
      </Type>
      <Flex className="quantity" gap={60}>
        <div>
          <Flex
            as="span"
            className={`btn ${quantity === minInv ? "disabled" : ""}`}
            onClick={() => handleQuantity("dec")}
          >
            -
          </Flex>
        </div>
        <InputText
          style={{ width: 120, minWidth: "52px", marginBottom: 0 }}
          size="small"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          inputProps={{
            onKeyUp: (e) => delay(enforceMinMax, 500)(e, setQuantity),
            min: minInv,
            max: limit - Web3.utils.fromWei(balance.toString(), "mwei"),
          }}
        />
        <div>
          <Flex
            as="span"
            className={`${!isVerfied || !isConnected ? "disabled" : ""}`}
            onClick={() => handleQuantity("inc")}
          >
            +
          </Flex>
        </div>
      </Flex>
      <Button
        loading={loading}
        onClick={handleBuyToken}
        disabled={!isVerfied || !isConnected}
      >
        Invest Now
      </Button>
      {!isConnected && (
        <Type as="div" sx={{ c: "red", fw: "500", mb: 15 }}>
          To invest, you need to be connected to metamask first.
        </Type>
      )}
      {!isVerfied && (
        <Type as="div" sx={{ c: "red", fw: "700", pb: [10, 10, 10, 40] }}>
          You must verify identity before investing.{" "}
          <Link gap={6} to="/verify-identity">
            <Type sx={{ c: "primary", fs: 16, fw: 600 }} as="span">
              Scan ID
            </Type>{" "}
            <Icon name="arrowRight" c="primary" w={15} />
          </Link>
        </Type>
      )}
      <Flex gap={10} className="note">
        <div>
          <Flex as="span">!</Flex>
        </div>
        <Type sx={{ fs: 16, lh: 20 }}>
          Tokens are not transferable until the investment is closed after{" "}
          {displayTimeLeft()}.
        </Type>
      </Flex>
      <LoadingMask
        msg="This action may take a while."
        isLoading={loading && networkBalance}
      />
    </InvestBox>
  );
}

export default Invest;
