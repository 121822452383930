import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";

const Wrapper = styled.div`
  margin-bottom: 40px;
  ${(props) =>
    props.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrap {
    position: relative;
    line-height: 0;
    > span {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      fill: ${(props) => props.theme.color.dark};
      line-height: 0;
    }
  }

  p.msg {
    color: ${(props) => props.theme.color.grayText};
    padding-left: 9px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &.error p.msg {
    color: ${(props) => props.theme.color.red};
  }
`;

function InputText({
  label,
  value,
  name,
  id,
  onChange,
  placeholder = "",
  type,
  msg = null,
  error = false,
  icon = null,
  size = "large",
  inputProps,
  ...props
}) {
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      {label && <Label htmlFor={id || inputProps?.id}>{label}</Label>}
      <div className="inputWrap" >
        {icon && <span>{icon}</span>}
        <Input
          as={type === "textarea" ? "textarea" : "input"}
          id={id || inputProps?.id}
          {...{
            value,
            type,
            name,
            id,
            onChange,
            placeholder,
            size,
            ...inputProps,
          }}
        />
      </div>
      {msg && <p className="msg">{msg}</p>}
    </Wrapper>
  );
}

export default InputText;
