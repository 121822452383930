import styled from "styled-components";
import Type from "../../ui/Type";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../services/auth";
import Block from "../../ui/Block";

const SBlock = styled(Block)`
  max-width: 1000px;
  @media only screen and (max-width: 1200px) {
    max-width: 100%;
  }
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 100;
    /* @media only screen and (max-width: 1400px) {
      margin: 0 40px;
      width: calc(100% - 80px);
    } */
    @media only screen and (max-width: 960px) {
      display: block;
    }
  }
  div {
    border-radius: 100px;
    border: ${(p) => p.theme.color.secondary} 2px solid;
    @media only screen and (max-width: 960px) {
      text-align: center;
      border: none;
    }
    input {
      border: none;
      height: 52px;
      width: 280px;
      font-size: 15px;
      border-radius: 100px;
      padding-left: 20px;
      &:focus,
      &:active {
        outline: none;
      }
      @media only screen and (max-width: 960px) {
        border: ${(p) => p.theme.color.secondary} 2px solid;
      }
    }
    button {
      background-color: ${(p) => p.theme.color.primary};
      border: none;
      border-radius: 100px;
      color: white;
      height: 54px;
      width: 180px;
      font-size: 16px;
      float: right;
      cursor: pointer;
      @media only screen and (max-width: 960px) {
        width: 120px;
        font-size: 14px;
        float: none;
        margin: 0 10px;
        margin-top: 15px;
      }
    }
  }
`;

function Subscribe() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();

  if (user) return null;

  return (
    <SBlock sx={{ ta: ["left", "left", "center", "center"] }}>
      <section>
        <Type var="p" sx={{ fw: 700, mb: [0, 0, 30, 30] }} as="p">
          INVESTMENT OPPORTUNITIES
        </Type>
        <div>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email address"
          />
          <button onClick={() => navigate(`/register?email=${email}`)}>
            Sign up here
          </button>
        </div>
      </section>
    </SBlock>
  );
}

export default Subscribe;
