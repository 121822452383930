import { Box, Flex, Icon, Type } from "../../../ui";

function FutureItem({ icon, title, desc }) {
  return (
    <Box sx={{ta: 'center', mw: 400, mx: 'auto'}}>
      <Flex sx={{w: 40, h: 40, mx: 'auto'}}>
        <Icon name={icon} w={30} c="secondary" />
      </Flex>
      <Type var="h5" as="h5" sx={{my: 14}}>
        {title}
      </Type>
      <Type sx={{fs: 14}}>{desc}</Type>
    </Box>
  );
}

export default FutureItem;