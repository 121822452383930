import Layout from "../../layout/Layout";
import { Box, Card, Flex, Icon, Link, Type } from "../../ui";
import theme from "../../theme.js";
import { useNavigate } from "react-router-dom";
import ProfileForm from "./ProfileForm";
import AvatarUpload from "./AvatarUpload";
import { useAuth } from "../../services/auth";
import { useProfile } from "./provider";
import { useEffect } from "react";
import { useUtils } from "../../services/utilsProvider";
import ConnectMetamask from "../../components/ConnectMetamask";
import DisplayWallet from "./profile/DisplayWallet";
import Section from "./profile/Section";
import { useCompanies } from "../company/provider";
import DirectUSDTTransfer from "./profile/DirectUSDTTransfer";

function Profile() {
  const { user } = useAuth();

  const {
    state: { selected },
    fetchOne,
  } = useProfile();

  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompanies();

  const navigate = useNavigate();

  const { notify } = useUtils();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (selected) return;
    fetchOne(Number(user.user.id));
  }, [fetchOne, selected]);

  useEffect(() => {
    const id = user.user?.companyID;
    if (!id) return;
    fetchCompany(Number(id));
  }, [fetchCompany, company, user]);

  const handleSuccess = () => {
    notify("Profile information has been saved successfully.");
  };

  const handleDirectTransferSuccess = () => {
    notify("Operation executed successfully");
  };

  if (!selected) return;

  return (
    <Layout>
      <Box center sx={{ mb: 160, mt: 60, mw: theme.pageWidth }}>
        <Card title="My Profile" onClose={() => navigate(-1)}>
          <Flex
            jc="none"
            ai="flex-top"
            dir={["row", "column-reverse", "column-reverse", "column-reverse"]}
            gap={["80px", "80px", "40px", "40px"]}
            sx={{ mb: 60 }}
          >
            <Box>
              <Type var="h4" as="h4" sx={{ mb: 15, mt: 10 }}>
                Profile Data
              </Type>
              <ProfileForm
                profile={{
                  firstName: selected.firstName,
                  lastName: selected.lastName,
                  dateOfBirth: selected.dateOfBirth,
                  countryID: selected.country.id,
                  email: selected.user.email,
                  mobile: selected.user.mobileNumber,
                }}
                onSuccess={handleSuccess}
              />
            </Box>

            <AvatarUpload profile={selected} />
          </Flex>

          <Type var="h4" as="h4" sx={{ mt: 40 }}>
            Legal Entity
          </Type>
          {company ? (
            <Section
              body={
                company.status === 0
                  ? "You have successfully registered your legal entity. You will be contacted by our compliance legal team shortly"
                  : "See your company details, status, capital raised and more."
              }
            >
              <Type var="p" as="p" sx={{ fw: 300 }}>
                Go to <Link to="/company/own">Legal Entity</Link>
              </Type>
            </Section>
          ) : (
            <Section body="Are you a company shareholder or a start-up business? Connect to a new world of investors for a seamless capital raise through security token (STO.)">
              <Type var="p" as="p" sx={{ fw: 300 }}>
                Create your <Link to="/company/new">Legal Entity</Link>
              </Type>
            </Section>
          )}

          <Type var="h4" as="h4" sx={{ mt: 40 }}>
            Identity
          </Type>
          <Section
            title="Verify your Identity"
            body="Scan your ID to verify your identity and start your investment journey."
          >
            <Flex
              as={user.user.isVerified ? "span" : Link}
              to="/verify-identity"
              jc="flex-start"
              s={8}
              sx={{ fw: 300, pb: 5, pt: 7 }}
            >
              {user.user.isVerified ? (
                <>
                  <Type sx={{ fw: 300 }}>Already verified</Type>{" "}
                  <Icon name="checkCircleSolid" c="secondary" w={22} />
                </>
              ) : (
                <>
                  <span>Scan ID</span>{" "}
                  <Icon name="arrowRight" c="white" w={17} />
                </>
              )}
            </Flex>
          </Section>

          <Section
            title="Risk Review"
            body="Read our risk survey thoroughly to understand further the risks of investing."
          >
            <Type var="p" sx={{ fw: 300 }}>
              Go to <Link>Risk Review</Link>
            </Type>
          </Section>

          <Section
            title="Settings"
            body="Choose a strong password to better protect your account."
          >
            <Type var="p" sx={{ fw: 300 }}>
              Go to <Link to="/change-password">Change Password</Link>
            </Type>
          </Section>

          <Type var="h4" as="h4" sx={{ mt: 40 }}>
            Wallet
          </Type>

          <Section title="Metamask">
            {selected.walletAddress && window.ethereum?.networkVersion ? (
              <DisplayWallet />
            ) : (
              <>
                <Type var="p" sx={{ mb: 10 }}>
                  Connect to your Metamask Wallet
                </Type>
                <ConnectMetamask />
              </>
            )}
          </Section>

          {/* section */}
          <Box
            sx={{
              w: [750, 750, 750, "auto"],
              px: 20,
              pt: 0,
              mt: 40,
              bgc: "bgYellow",
              border: ["yellow", 1, "solid", 15],
            }}
          >
            <Section
              title="Direct USDT Transfer"
              body="If you would like to allow investors to buy tokens through direct USDT transfer, Please provide us with your wallet private key."
            >
              <DirectUSDTTransfer onSuccess={handleDirectTransferSuccess} />
            </Section>
          </Box>
        </Card>
      </Box>
    </Layout>
  );
}

export default Profile;
