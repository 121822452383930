import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import Icon from "./Icon";
import Loading from "./Loading";

const StyledButton = styled.button.attrs((p) => ({
  width: p.width ? `${p.width}px` : "auto",
  bg: p.bg ? p.theme.color[p.bg] : p.theme.color.primary,
  color: p.theme.color.gray50,
  size: p.size || "medium",
}))`
  width: ${(p) => p.width};
  height: auto;
  padding: 10px 30px;
  font-weight: 700;
  line-height: 0;
  border-radius: 9999px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  min-width: 100px;
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fonts.primary} svg {
    line-height: 0;
    margin-left: 0px;
    transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  }
  ${(p) => {
    if (p.variant === "outlined")
      return css`
        background-color: transparent;
        color: ${(p) => p.bg};
        border: ${(p) => p.bg} 1px solid;
        svg {
          fill: ${(p) => p.bg};
        }
        &:hover:not(:disabled),
        &.selected {
          background-color: ${(p) => p.bg || p.theme.color.bg};
          color: ${(p) =>
            p.bg === "white" ? p.theme.color.black : p.theme.color.white};
          border-color: ${(p) => p.bg || p.theme.color.primary};
          svg {
            fill: ${(p) =>
              p.bg === "white" ? p.theme.color.black : p.theme.color.white};
          }
        }
      `;
    else if (p.variant === "text")
      return css`
        background-color: transparent;
        color: ${(p) => p.bg};
        border: none;
        &:hover {
          background-color: ${(p) => p.theme.color.gray200};
        }
        svg {
          fill: ${(p) => p.theme.color.primary};
        }
      `;
    else
      return css`
        background-color: ${(props) => props.bg};
        color: ${(props) => props.color};
        border: none;
        svg {
          fill: ${(p) => p.theme.color.white};
        }
      `;
  }}
  &:hover {
    opacity: 0.8;
    transform: scale(0.99);
  }
  &:active,
  &:disabled {
    opacity: 0.6;
    transform: scale(0.975);
  }
  &:disabled {
    cursor: default;
  }
  ${(props) => {
    if (props.size === "large")
      return css`
        height: 48px;
        padding: 0 38px;
        font-size: 16px;
        border-width: 3px;
        svg {
          width: 20px;
          margin-left: 8px;
        }
      `;
    else if (props.size === "medium")
      return css`
        height: 42px;
        padding: 0 32px;
        font-size: 14px;
        svg {
          width: 16px;
          margin-left: 6px;
        }
      `;
    else if (props.size === "small")
      return css`
        height: 37px;
        padding: 0 24px;
        font-size: 12px;
        svg {
          width: 12px;
          margin-left: 4px;
        }
      `;
  }}
  // depricated class use size="small" props instead
  &.small {
    height: 40px;
    font-size: 15px;
    text-transform: none;
  }
  // depricated class use size="large" props instead
  &.large {
    height: 54px; 
    font-size: 21px;
    text-transform: none;
    padding: 0 26px;
  }
  span.loading {
    margin-top: 0px;
  }
  // depricated class use variant="text" prop instead
  &.transparent {
    background-color: white;
    color: ${(props) => props.theme.color.grayLight};
    box-shadow: 0px 4px 8px #0000001f;
  }
  // depricated class use variant="outlined" prop instead
  &.outlined {
    background-color: transparent;
    color: ${(props) => props.theme.color.grayLight};
    border: ${(props) => props.theme.color.dark} 1px solid;
  }
  ${stylesBlocks}
`;

function Button({
  children,
  loading = false,
  disabled,
  iconName = null,
  ...props
}) {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? <Loading size={20} /> : children}
      {iconName && !loading && <Icon name={iconName} />}
    </StyledButton>
  );
}

export default Button;
