import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Flex, InputRadio, InputText, Link, Type } from "../../ui";
import "../../css/flags.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";
import InputMobile from "../../components/InputMobil";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),
});

function LoginForm({ onSuccess }) {
  const [error, setError] = useState(null);
  const [type, setType] = useState("email");
  const navigate = useNavigate();
  const { signin, resendCode } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleVerify = async () => {
    try {
      const email = getValues("email");
      await resendCode({ email });
      navigate(`/verification-code?email=${email}`);
    } catch (e) {
      console.log(e);
    }
  };

  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "UNVERIFIED_EMAIL")
        setError(
          <Flex dir="column" sx={{ mt: -20, mb: 60 }}>
            <Type sx={{ c: "red" }}>
              You need to verify your email to login.
            </Type>
            <Button className="small" onClick={handleVerify}>
              Verify email now
            </Button>
          </Flex>
        );
      else if (e.message === "INVALID_USER" || e.message === "INVALID_LOGIN")
        setError(
          <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
            Invalid Login Credentials.
          </Type>
        );
    } else if(status === 429) {
      setError(
        <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
          Too many request. {e.data.message}
        </Type>
      );
    }
  };

  const onSubmit = async (values) => {
    setError(null)
    try {
      await signin(values);
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      handleError(e);
      console.log("e", e);
    }
  };

  return (
    <Box sx={{ w: [620, 620, 620, '100%'], px: 30 }} as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && error}
      <Flex jc="space-evenly" sx={{ mb: 26 }}>
        <div>
          <InputRadio
            label="Login with your Email"
            value="email"
            name="login-type"
            checked={type === "email"}
            onChange={() => setType("email")}
          />
        </div>
        <div>
          <InputRadio
            label="Login with your Phone Number"
            value="mobile"
            name="login-type"
            checked={type !== "email"}
            onChange={() => setType("mobile")}
          />
        </div>
      </Flex>
      {type === "email" ? (
        <InputText
          placeholder="Email"
          error={!!errors.email}
          msg={errors.email?.message}
          inputProps={{
            ...register("email"),
            autoFocus: true,
          }}
        />
      ) : (
        <InputMobile />
      )}
      <InputText
        placeholder="Password"
        type="password"
        error={!!errors.password}
        msg={errors.password?.message}
        inputProps={{
          ...register("password"),
        }}
      />
      <Type sx={{ ta: "right", mt: -20, mb: 20 }} as="p">
        <Link to="/forgot-password?type=email">Forgot Password?</Link>
      </Type>
      <Button
        type="submit"
        loading={isSubmitting}
        size="large"
        bg="secondary"
        style={{ margin: '0 auto' }}
      >
        Login
      </Button>
      {/* <Type sx={{ ta: "center", mt: 30, c: 'gray600', fw: 300 }} var="p" as="p">
        Don’t have an account? <Link to="/register">Register</Link> here
      </Type> */}
    </Box>
  );
}

export default LoginForm;
