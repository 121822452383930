import { Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import AddCompany from "./AddCompany";
// import Company from "./Company";
import CompanyView from "./CompanyView";
import Opportunities from "./Opportunities";
import RewardDetails from "./RewardDetails";
import Rewards from "./Rewards";
import OwnCompanyView from "./OwnCompanyView";
import LoadingPage from "../../ui/LoadingPage";

const Company = lazy(() => import("./Company"));

export default (
  <Route
    path="company"
    element={
      <RequireAuth>
        <Suspense fallback={<LoadingPage />}>
          <Company />
        </Suspense>
      </RequireAuth>
    }
  >
    <Route
      path="own"
      element={
        <RequireAuth>
          <OwnCompanyView />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddCompany />
        </RequireAuth>
      }
    />
    <Route
      path=":id/live-investment"
      element={
        <RequireAuth>
          <CompanyView />
        </RequireAuth>
      }
    />
    {/* <Route
      path=":id/legal-entity"
      element={
        <RequireAuth>
          <OwnCompanyView />
        </RequireAuth>
      }
    /> */}
    <Route path="opportunities" element={<Opportunities />} />
    <Route path="rewards" element={<Rewards />} />
    <Route
      path="rewards/:id"
      element={
        <RequireAuth>
          <RewardDetails />
        </RequireAuth>
      }
    />
  </Route>
);
