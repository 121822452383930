import { useState } from "react";
import FileField from "../../components/FileField";
import { useAuth } from "../../services/auth";
import { useUtils } from "../../services/utilsProvider";
import { Box } from "../../ui";
import useImageLink from "../../utilities/hooks/useImageLink";
import { useProfile } from "./provider";

function AvatarUpload({profile}) {
  const { user } = useAuth();
  const imgUrl = useImageLink(profile.user.avatarURL);
  const [preview, setPreview] = useState(null);

  const {
    state: { status },
    saveAvatar,
  } = useProfile();

  const { notify } = useUtils();

  const handleChange = async (e) => {
    if (status === "loading") return;
    const file = e.target.files[0];
    setPreview(URL.createObjectURL(file));
    // setFile(file);
    await saveAvatar(file, user.id);
    notify("Image has been saved successfully.");
  };

  return (
    <Box>
      <FileField
        style={{ width: "280px", marginLeft: 0 }}
        label=""
        buttonLabel={user.avatar !== "" ? "Change Avatar" : "Upload Avatar"}
        preview={imgUrl || preview}
        edit={Boolean(user.avatar !== "")}
        loading={Boolean(status === "loading")}
        inputProps={{
          name: "logo",
          onChange: handleChange,
          accept: "image/jpng,image/png,image/jpg,image/jpeg",
        }}
      />
    </Box>
  );
}

export default AvatarUpload;
