import { Button, Type } from "../../../ui";
import { dateFromNow, truncatWork } from "../../../utilities/functions";
import useSubscribe from "../../../utilities/hooks/useSubscribe";

function Upcoming({ data }) {
  const { handleSub, sub } = useSubscribe(data);

  return (
    <div>
      <Type as="em" sx={{ fs: 18, fw: 300, fStyle: "italic" }}>
        Days to Start &bull; {dateFromNow(data.openingTime * 1000)[0]}
      </Type>
      <Type sx={{ h: [48, 48, 48, "auto"] }}>
        {truncatWork(data.description, 70)}
      </Type>
      <ul>
        <li>
          <span>Token Price:</span> <span>{data.tokenPrice || "N/A"}</span>
        </li>
        <li>
          <span>Tokens Left:</span> <span>{data.tokenLeft || "N/A"}</span>
        </li>
        <li>
          <span>Investors:</span> <span>{data.investorCount || "N/A"}</span>
        </li>
      </ul>
      {sub ? (
        <Type var="titleWisp" sx={{ mt: 30, c: "gray500", ta: "center" }}>
          Subscribed.
        </Type>
      ) : (
        <Button
          sx={{ mt: 30, mb: 10, w: "100%" }}
          size="small"
          onClick={handleSub}
        >
          I'm Intrested
        </Button>
      )}
    </div>
  );
}

export default Upcoming;
