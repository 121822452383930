import { Flex, InputText, Select } from "../ui";
import countries from "../data/countries.json";
import SelectCountryItem from "./SelectCountryItem";
import { getUserCountry } from "../utilities/functions";

function InputMobile() {
  return (
    <Flex gap={20}>
      <Select
        style={{ width: "280px", marginBottom: "38px" }}
        data={countries.map((c) => ({
          display: <SelectCountryItem c={c} />,
          name: c.dial_code || "",
        }))}
        defaultValue={getUserCountry().dial_code}
        mapData={{ label: "display", value: "name" }}
        name="dial"
      />
      <InputText placeholder="Phone Number" style={{ flexGrow: 1 }} />
    </Flex>
  );
}

export default InputMobile;
