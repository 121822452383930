const theme = {
  color: {
    primary: "#c63644",
    bgPrimary: "#222f3f",
    secondary: "#4ebf61",
    bgSecondary: "#141414",
    white: "#ffffff",
    black: "#000000",
    bg: "#f9f9f9",
    red: "#B83138",
    success: "#288657",
    yellow: "#f5d85b99",
    bgYellow: "#f5d85b16",

    boxShadow0: "#00000018",
    boxShadow1: "#00000011",
    boxShadow2: "#00000011",
    boxShadow3: "#00000011",
    boxShadow4: "#00000011",
    boxShadow5: "#00000011",
    boxShadow6: "rgba(0,0,0, .07)",
    //gray shads
    gray50: "#f8f8f8",
    gray100: "#f5f5f5",
    gray200: "#eeeeee",
    gray300: "#dddddd",
    gray400: "#cccccc",
    gray500: "#999999",
    gray600: "#666666",
    gray700: "#444444",
    gray800: "#222222",
    gray900: "#1d1d1d",
    gray1k: "#111111",
    gray2k: "#090909",
  },
  fonts: {
    primary: "'Montserrat', sans-serif;",
    secondary: "'Montserrat', sans-serif;",
    body: "'Montserrat', sans-serif;",
    display: "'Montserrat', sans-serif;",

    size: {
      body: "16px",
    },
  },
  pageWidth: 1350,
  borderRadius: 16,
  spaces: {
    blockY: 180,
  }
};

export default theme;
