import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { Box, Grid, Icon, Link, Slider, Type } from "../../ui";
import { useCompanies } from "./provider";
import theme from "../../theme";
import Overview from "./companyView/Overview";
import Materials from "./companyView/Materials";
import { useAuth } from "../../services/auth";
import styled from "styled-components";
import BgImg from "../../ui/BgImg";
import img1 from "../../assets/shape-color-3.png";
import img2 from "../../assets/shape-color-4.png";
import InfoRewards from "./companyView/InfoRewards";
import { useProfile } from "../profile/provider";
import Stake from "./companyView/Stake";
import CheckNetwork from "components/CheckNetwork";

const SliderBg = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.color.gray200};
  img {
    width: 100%;
    max-height: calc(100vw * 0.7);
    height: calc(100vh - 120px);
    object-fit: cover;
    object-position: center;
  }
`;

function RewardDetails() {
  const { user } = useAuth();
  const { id } = useParams();

  let [searchParams] = useSearchParams();

  const {
    state: { selected, financialInfo },
    fetchOne,
    fetchStakedFinancialInfo,
  } = useCompanies();

  const {
    state: { selected: profile },
    fetchOne: fetchProfile,
  } = useProfile();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (profile) return;
    fetchProfile(Number(user.user.id));
  }, [fetchProfile, profile, user]);

  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id]);

  useEffect(() => {
    if (!profile) return;
    fetchStakedFinancialInfo(id, profile.walletAddress);
  }, [fetchStakedFinancialInfo, id, user, profile]);

  const Slide = ({ data, slideRef }) => {
    return (
      <SliderBg sx={{ w: "100%" }} ref={slideRef}>
        <img src={`${user.tokens.awsData.s3PublicURI}${data}`} alt="" />
      </SliderBg>
    );
  };

  const DefaultSlide = ({ data, slideRef }) => {
    return (
      <Box
        sx={{ w: "100%", bgc: "gray800", ta: "center", py: 120 }}
        ref={slideRef}
      >
        <Type var="h1" as="h1" sx={{ c: "white" }}>
          {data.companyName}
        </Type>
        <Type sx={{ c: "white", mt: 15 }}>
          <Link to="/dashboard">Dashboard</Link>
          <Box as="span" sx={{ px: 8 }}>
            <Icon name="arrowRight" w={10} c="white" />
          </Box>
          <Type as="strong" sx={{ fw: 600, c: "white" }}>
            {searchParams.get("unstake") ? "Unstake" : "Stake"}
          </Type>
        </Type>
      </Box>
    );
  };

  return (
    <Layout>
      {Boolean(financialInfo) && (
        <>
          {financialInfo.imageSlider?.length ? (
            <Slider data={financialInfo.imageSlider} Slide={Slide} />
          ) : (
            <Slider data={[financialInfo]} Slide={DefaultSlide} />
          )}
        </>
      )}

      <CheckNetwork />

      {/* <BgSlops sx={{ pt: 20, pb: 100 }}> */}
      <BgImg
        img1={img1}
        img2={img2}
        pos1={{
          w: 600,
          h: 600,
          t: 80,
          r: [-140, -140, -300, -350],
        }}
        pos2={{
          w: 600,
          h: 600,
          t: -80,
          l: [70, 70, -50, -100],
        }}
      >
        <Grid
          cols={["3fr 2fr", "3fr 2fr", "3fr 2fr", "100%"]}
          gap={40}
          w={theme.pageWidth}
          center
          sx={{ mb: 80, mt: 60, mx: "auto", px: 30 }}
        >
          {Boolean(financialInfo) && (
            <>
              <InfoRewards financialInfo={financialInfo} />

              <Stake
                financialInfo={financialInfo}
                isUnstake={searchParams.get("unstake")}
              />
            </>
          )}
        </Grid>
        {Boolean(financialInfo) && (
          <Overview company={{ ...financialInfo, ...selected }} />
        )}
        <Materials id={id} />
      </BgImg>
      {/* </BgSlops> */}
    </Layout>
  );
}

export default RewardDetails;
