import LayoutMasked from "../../layout/LayoutMasked";
import { Box, Button, Flex, Type } from "../../ui";
import imgInvestor from "../../assets/investor-account.png";
import imgLegal from "../../assets/legal-entity-account.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";

function AccountType() {
  const {signout} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    navigate(location.pathname === "/account-type" ? "/" : location);
  };

  return (
    <LayoutMasked title="Welcome to Stitvest" onClose={handleClose}>
      <Box sx={{ w: [950, 950, 750, 'auto'] }}>
        <Flex dir={["row", "row", "row", "column"]} gap={40} jc="space-between">
          <Box
            sx={{ ta: "center", cursor: "pointer" }}
            onClick={() => navigate("/verify-identity")}
          >
            <Type var="h4" sx={{ mb: 40 }} as="h3">
              Register as an Investor
            </Type>
            <img width="320" src={imgInvestor} alt="Investor account" />
          </Box>
          <Box
            sx={{ ta: "center", cursor: "pointer" }}
            onClick={() => navigate("/company/new")}
          >
            <Type var="h4" sx={{ mb: 40 }} as="h3">
              Register as a Legal Entity
            </Type>
            <img width="320" src={imgLegal} alt="Legal entity account" />
          </Box>
        </Flex>
        <Flex sx={{mt: 80}} onClick={signout}><Button variant="text" size="medium">Logout here</Button></Flex>
      </Box>
    </LayoutMasked>
  );
}

export default AccountType;
