import styled from "styled-components";

const Wrap = styled.div`
    width: ${props => props.w+'px' || '30%'};
`;

function Item({slide, data, w}) {
    const Slide = slide;
    return <Wrap className="item" w={w}><Slide data={data} /></Wrap>
}

export default Item;