import Layout from "../../layout/Layout";
import { Box, Divider, Flex, Grid, Type } from "../../ui";
import Subscribe from "../home/Subscribe";
import ImportantFacts from "../../components/ImportantFacts";
import Block from "../../ui/Block";
import logo from "../../assets/logo.png";
import Future from "./about/Future";

function About() {
  return (
    <Layout title="About">

      <Divider h={80} />

      <Block mini>
        <Grid cols={["48% 52%", "52% 48%", "100%", "100%"]} gap={80}>
          <Box>
            <Type var="display" as="h1" sx={{ mb: 25 }}>
              What is Stitvest?
            </Type>
            <Type>
              Stitvest is an application that corporates a fully regulated,
              <br /> end-to-end digital solution for businesses to seek
              financing through investors, providing a mutual benefit by helping
              companies raise their capital and investors grow their money. The
              new application leverages blockchain technology and security
              tokens to enable capital raises and provide the potential for
              shareholder liquidity in an uncomplicated technique.
            </Type>
          </Box>
          <Flex>
            <img style={{ width: "100%", maxWidth: 350 }} src={logo} alt="" />
          </Flex>
        </Grid>
      </Block>

      <Box w={800} center>
        <Divider border="gray300" h={0} />
      </Box>

      <Block mini>
        <ImportantFacts />
      </Block>

      <Box w={800} center>
        <Divider border="gray300" h={0} />
      </Box>

      <Block mini>
        <Future />
      </Block>

      <Box w={800} center>
        <Divider border="gray300" h={0} />
      </Box>

      <Block mini>
        <Type var="h2" as="h2" sx={{ mb: 0, ta: "center" }}>
          Core Values
        </Type>
        <Type
          var="subTitle"
          as="h3"
          sx={{ mt: 5, mb: 50, c: "gray600", ta: "center" }}
        >
          Aiming For Higher Standards
        </Type>

        <Grid
          cols={["1fr 1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr", "1fr"]}
          gap={60}
        >
          <Box sx={{ ta: "center" }}>
            <Type var="h5" as="h5" sx={{ mb: 10, text: "uppercase" }}>
              Authenticity
            </Type>
            <Type sx={{fs: 15}}>
              Having genuine conversations, assuming positive intent is how
              Stitvest delivers its best work. Believing in the work, and how it
              should show. Expressing thoughts respectfully, staying open to
              being challenged by different points of view, and interacting in a
              real ways. We are honest with one another, keep our commitments
              Earn Trust and do what we say we’re going to do.
            </Type>
          </Box>
          <Box sx={{ ta: "center" }}>
            <Type var="h5" as="h5" sx={{ mb: 10, text: "uppercase" }}>
              Innovation
            </Type>
            <Type sx={{fs: 15}}>
              Believing in the power of ideas, and listening to teams and
              partners to better understand people wants and what customers
              needs. From developing leading new products to evolving the work
              ways; we are committed to continuous improvement. Changing,
              growing, and learning from setbacks makes better companies.
              Solving the tough problems is part of who we are, and we’ll always
              find a way. We push for progress, knowing it is part of our
              pursuit of excellence.
            </Type>
          </Box>
          <Box sx={{ ta: "center" }}>
            <Type var="h5" as="h5" sx={{ mb: 10, text: "uppercase" }}>
              Leadership
            </Type>
            <Type sx={{fs: 15}}>
              Leadership is a mindset, not a position. Each day we choose how to
              show up, keeping a positive attitude and focusing on solutions. We
              stay humble, ask for feedback, and invest in our colleagues’
              success through mentoring and inspiring them to achieve their
              best. We lead with passion and conviction to achieve our purpose,
              take risks we believe in, and are guided by the reasons we can
              win, not by the fear we could lose.
            </Type>
          </Box>
        </Grid>
      </Block>
      <Subscribe />
    </Layout>
  );
}

export default About;
