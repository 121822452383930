import { createContext, useReducer, useContext, useCallback } from "react";
import Snackbar from "../ui/Snackbar";

const UtilsContext = createContext(null);

export function useUtils() {
  return useContext(UtilsContext);
}

const initState = {
  alert: {
    open: false,
    content: "",
    autoHideDuration: 4000,
    severity: "success",
    position: { vertical: "bottom", horizontal: "left" },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_alert":
      return { ...state, alert: { ...state.alert, ...action.payload } };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function UtilsProvider({ children }) {
  let [state, dispatch] = useReducer(reducer, initState);

  const handleClose = () => {
    dispatch({ type: "set_alert", payload: { open: false, content: "" } });
  };

  const notify = useCallback(
    (content, severity = "success") => {
      setTimeout(handleClose, state.alert.autoHideDuration);
      dispatch({
        type: "set_alert",
        payload: { open: true, content, severity },
      });
    },
    [state.alert.autoHideDuration]
  );

  // const alert = (content) => {
  //   setTimeout(handleClose, state.alert.autoHideDuration);
  //   dispatch({
  //     type: "set_alert",
  //     payload: {
  //       open: true,
  //       content,
  //       severity: "error",
  //       autoHideDuration: 10000,
  //     },
  //   });
  // };

  let value = { state, notify, alert, dispatch };

  return (
    <UtilsContext.Provider value={value}>
      <Snackbar
        open={state.alert.open}
        onClose={handleClose}
        anchorOrigin={state.alert.position}
        severity={state.alert.severity}
      >
        {state.alert.content}
      </Snackbar>

      {children}
    </UtilsContext.Provider>
  );
}
