import { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../layout/Layout";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  InputRadio,
  Loading,
  Pagination,
  Type,
} from "../../ui";
import { getStatus, getStatusId } from "../../utilities/functions";
import CompanyBox from "../company/companyBox/CompanyBox";
import { useCompanies } from "../company/provider";

const Section = styled(Box)`
  margin: 20px 0;
  padding: 0 0 20px 0;
  /* border-bottom: ${(props) => props.theme.color.gray300} 1px solid; */
  h6 {
    text-transform: uppercase;
  }
  svg {
    transition: transform 0.2s 0.1s cubic-bezier(0.4, 0, 1, 1);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -5px;
    max-height: 0;
    transition: max-height 0.3s cubic-bezier(0.4, 0, 1, 1);
    overflow: hidden;
    li {
      padding: 10px 0;
      &:first-child {
        padding-top: 20px;
      }
      input {
        background-color: transform;
      }
    }
  }
  &.show {
    ul {
      max-height: 999px;
    }
    svg {
      transform: rotate(180deg);
    }
  }
`;

const FiterSection = ({ title, name, options, selected, onChange }) => {
  const [open, setOpen] = useState(true);

  return (
    <Section className={open ? "show" : ""}>
      <Flex jc="space-between">
        <Type var="h6" as="h6">
          {title}
        </Type>
        <Icon
          hover
          onClick={() => setOpen((v) => !v)}
          name="angleDown"
          w={10}
          c="white"
        />
      </Flex>
      <ul>
        {options.map((opt) => (
          <li key={opt.id}>
            <InputRadio
              size="small"
              id={opt.id}
              name={name}
              value={opt.id}
              checked={selected === opt.id}
              label={opt.categoryName}
              onChange={() => onChange(opt, name)}
            />
          </li>
        ))}
      </ul>
    </Section>
  );
};

function List() {
  // const [page, setPage] = useState(1);
  const [category, setCategory] = useState(null);

  const {
    state: { list: companies, categories, status },
    fetchList,
    fetchCategories,
  } = useCompanies();

  useEffect(() => {
    console.log('List is here!')
    fetchList({ statusID: getStatusId("closed"), categoryID: category });
  }, [fetchList, category]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // const handleChange = (v, name) => {
  //   setFilters((values) =>
  //     values.includes(v)
  //       ? values.filter((value) => value !== v)
  //       : [...values, v]
  //   );
  // };

  const handleChange = (v, name) => {
    setCategory(v.id);
  };

  return (
    <Layout>
      <Box w={1430} sx={{ px: 40 }} center>
        <Type var="h3" as="h3" sx={{ mt: 60 }}>
          Marketplace
        </Type>
        <Divider border="gray300" h={40} />

        {/* <Grid cols="1fr 3fr" sx={{ mb: 40, mt: 35 }} gap={40}>
          <InputText
            placeholder="Search here"
            size="small"
            style={{ marginBottom: 0 }}
            icon={<Icon name="search" w="21" c="white" />}
          />
          <Flex jc="space-between" ai="flex-start">
            <Box>
              {filters.map((f) => (
                <Chip key={f} style={{ margin: "0 10px 10px 0" }}>
                  <Flex gap={8} sx={{ lh: 0 }}>
                    <span>{f}</span>{" "}
                    <Icon
                      onClick={() => handleChange(f, "")}
                      hover
                      name="xmark"
                      c="gray500"
                      w={7}
                    />
                  </Flex>
                </Chip>
              ))}
            </Box>
            <Select
              size="small"
              name="Sort by"
              data={["name", "date", "price"]}
            />
          </Flex>
        </Grid> */}

        <Grid cols={["1fr 4fr", "1fr 3fr", "30% 70%", "100%"]} gap={40}>
          <Box sx={{ mt: 30 }}>
            <Flex jc="space-between" sx={{ mb: 30 }}>
              <Type var="h4" as="h4">
                FILTERS{" "}
              </Type>
              <Button
                size="small"
                variant="text"
                onClick={() => setCategory(null)}
                sx={{ fs: 14 }}
              >
                Reset
              </Button>
            </Flex>
            {/* <FiterSection
              title="COMPANY LIST"
              name="company"
              onChange={handleChange}
              selected={filters}
              options={[
                "Liban Call",
                "Stitvest",
                "Stitchain",
                "LibanCall",
                "The Company Name",
              ]}
            /> */}
            {/* <FiterSection
              title="currencies list"
              name="currency"
              onChange={handleChange}
              selected={filters}
              options={["Bitcoin", "Enigma", "Dollar", "USDT"]}
            /> */}
            <FiterSection
              title="industry"
              name="industry"
              onChange={handleChange}
              selected={category}
              options={categories}
            />
            {/* <FiterSection
              title="Geographic market"
              name="market"
              onChange={handleChange}
              selected={filters}
              options={[
                "Geographic 1",
                "Geographic 2",
                "Geographic 3",
                "Geographic 4",
              ]}
            /> */}
          </Box>
          <Box sx={{ mb: 100 }}>
            {Boolean(companies.length) ? (
              <Grid cols={["1fr 1fr 1fr", "1fr 1fr", "1fr", "1fr"]} gap={30}>
                {companies.map((c) => (
                  <CompanyBox
                    key={c.id}
                    status={getStatus(c.status)}
                    data={c}
                    to={`company/${c.id}`}
                  />
                ))}
              </Grid>
            ) : (
              <Box sx={{ ta: "center", mt: 60 }}>
                {status === "fetching" ? (
                  <Loading />
                ) : (
                  <Type>No Oportunities Found!</Type>
                )}
              </Box>
            )}

            {/* {Boolean(companies.length) && (
              <Pagination
                style={{ justifyContent: "center" }}
                noInfo
                count={100}
                page={page}
                onPageChange={(p) => setPage(p)}
              />
            )} */}
          </Box>
        </Grid>
      </Box>
    </Layout>
  );
}

export default List;
