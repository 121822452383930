import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Divider = styled.div.attrs((props) => ({
  h: props.h ? props.h : 20,
}))`
  ${(props) =>
    props.border
      ? css`
          border-${props.vertical ? "right" : "bottom"}: ${
          props.theme.color[
            typeof props.border === "string" ? props.border : "gray100"
          ]
        } 1px solid;
        `
      : undefined}
  ${(props) =>
    props.vertical
      ? css`
          margin-left: ${(props) => (props.w ? props.w / 2 : 0)}px;
          margin-right: ${(props) => (props.w ? props.w / 2 : 0)}px;
          height: ${(props) => (props.h ? props.h : '20px')}px;
        `
      : css`
          margin-bottom: ${(props) => (props.h ? props.h / 2 : 0)}px;
          height: ${(props) => props.h/2}px;
        `}

    ${stylesBlocks}
`;

export default Divider;
