import { lazy, Suspense } from "react";
import styled, { css } from "styled-components";
import { firstUp } from "../utilities/functions";

const icons = {
  Search: lazy(() => import("./icons/Search")),
  ArrowRight: lazy(() => import("./icons/ArrowRight")),
  ArrowLeft: lazy(() => import("./icons/ArrowLeft")),
  Bell: lazy(() => import("./icons/Bell")),
  Snapchat: lazy(() => import("./icons/Snapchat")),
  Facebook: lazy(() => import("./icons/Facebook")),
  Instagram: lazy(() => import("./icons/Instagram")),
  Linkedin: lazy(() => import("./icons/Linkedin")),
  Twitter: lazy(() => import("./icons/Twitter")),
  Tiktok: lazy(() => import("./icons/Tiktok")),
  Pen: lazy(() => import("./icons/Pen")),
  CheckCircle: lazy(() => import("./icons/CheckCircle")),
  Cloud: lazy(() => import("./icons/Cloud")),
  Donut: lazy(() => import("./icons/Donut")),
  Pdf: lazy(() => import("./icons/Pdf")),
  CircleArrowRight: lazy(() => import("./icons/CircleArrowRight")),
  CircleArrowLeft: lazy(() => import("./icons/CircleArrowLeft")),
  Trash: lazy(() => import("./icons/Trash")),
  EllipsisVertical: lazy(() => import("./icons/EllipsisVertical")),
  Sliders: lazy(() => import("./icons/Sliders")),
  CheckCircleSolid: lazy(() => import("./icons/CheckCircleSolid")),
  AngleLeft: lazy(() => import("./icons/AngleLeft")),
  AngleDown: lazy(() => import("./icons/AngleDown")),
  AngleUp: lazy(() => import("./icons/AngleUp")),
  Xmark: lazy(() => import("./icons/Xmark")),
  Mouse: lazy(() => import("./icons/Mouse")),
  Play: lazy(() => import("./icons/Play")),
  Download: lazy(() => import("./icons/Download")),
  Copy: lazy(() => import("./icons/Copy")),
  Gears: lazy(() => import("./icons/Gears")),
  Shield: lazy(() => import("./icons/Shield")),
  Globe: lazy(() => import("./icons/Globe")),
  ClipboardList: lazy(() => import("./icons/ClipboardList")),
  LightBulb: lazy(() => import("./icons/LightBulb")),
  UniversalAccess: lazy(() => import("./icons/UniversalAccess")),
  CloudSun: lazy(() => import("./icons/CloudSun")),
  Seedling: lazy(() => import("./icons/Seedling")),
  PeopleGroup: lazy(() => import("./icons/PeopleGroup")),
  Calendar: lazy(() => import("./icons/Calendar")),
  User: lazy(() => import("./icons/User")),
  CircleExclamation: lazy(() => import("./icons/CircleExclamation")),
  ArrowsRotate: lazy(() => import("./icons/ArrowsRotate")),
  Refresh: lazy(() => import("./icons/Refresh")),
  Eye: lazy(() => import("./icons/Eye")),
};

const Wrap = styled.span`
  line-height: 0;
  transition: all .07s cubic-bezier(0.4, 0, 1, 1);
  ${(p) =>
    p.hover &&
    css`
      cursor: pointer;
      width: calc(6px + ${p => p.w}px);
      height: calc(6px + ${p => p.w}px);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      outline: ${p.theme.color[p.hover]} 0px solid;
      &:hover {
        /* fill: ${p.theme.color[p.c]}; */
        background-color: ${p.theme.color[p.hover]}cc;
        outline: ${p.theme.color[p.hover]}cc 8px solid;
      }
      &:active {
        background-color: ${p.theme.color[p.hover]}99;
        outline: ${p.theme.color[p.hover]}99 5px solid;
      }
    `}
  svg {
    fill: ${(p) => p.c && p.theme.color[p.c]};
    width: ${(p) => p.w}px;
  }
  ${(p) =>
    p.circle &&
    css`
      width: ${p.w + 22}px;
      height: ${p.w + 22}px;
      display: inline-block;
      border-radius: 200px;
      background-color: ${(p) => p.c && p.theme.color[p.c]};
      position: relative;
      svg {
        fill: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

function Icon({ name, w = "30", c = "dark", hover, ...rest }) {
  if (!icons[firstUp(name || "")]) return;
  const Component = icons[firstUp(name)];
  return (
    <Suspense fallback={"loading"}>
      <Wrap {...{ w, c, hover, ...rest }}>
        <Component />
      </Wrap>
    </Suspense>
  );
}

export default Icon;
