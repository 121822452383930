import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import fields from "./fields";
import { Box, Button, Grid, InputText, Select, Type } from "../../ui";
import { useAuth } from "../../services/auth";
import { useProfile } from "./provider";
import { transformDate } from "../../utilities/functions";

import { format, subYears } from "date-fns";
import InputDate from "../../ui/InputDate";


const yupSchema = fields.reduce(createYupSchema, {});
const schema = Yup.object().shape(yupSchema);

function ProfileForm({ profile, onSuccess }) {
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(null);

  const { getCountries } = useAuth();
  const { update } = useProfile();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: profile,
  });

  useEffect(() => {
    (async () => {
      setCountries(await getCountries());
    })();
  }, [getCountries]);

  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "USER_ALREADY_EXISTS")
        setError("Email already exsist.");
    } else {
      setError(e.message);
    }
  };

  const onSubmit = async (values) => {
    console.log(values)
    // const dateOfBirth = format(values.dateOfBirth, 'YYY-M-d');
    // return;
    try {
      await update({
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: format(values.dateOfBirth, 'YYY-M-d'),
        countryID: values.countryID,
      });
      // reset();
      onSuccess(values);
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) handleError(e);
      console.log("e", e);
    }
  };

  const onChange = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );

  const resources = {
    countries,
  };

  return (
    <Box
      sx={{ w: [750, 750, 750, "auto"] }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {error && (
        <Type sx={{ c: "red", ta: "center", mt: -10, mb: 40 }}>{error}</Type>
      )}
      <Grid cols={["1fr 1fr", "1fr 1fr", "1fr 1fr", "1fr"]} colGap={30}>
        {fields.map((f, i) => {
          if (f.viewOnly) return null;

          const inputProps = { ...f.inputProps };
          if (i === 0) inputProps.autoFocus = true;
          if (f.required) inputProps.required = true;
          if (f.type === "date" && getValues(f.name))
            setValue(f.name, transformDate(new Date(getValues(f.name))));

          if (f.data) {
            const data = f.resourceName ? resources[f.resourceName] : f.data;

            if (!data?.length) return null;

            return (
              <div key={i}>
                <Select
                  label={f.label}
                  style={{
                    width: "100%",
                    height: "60px",
                    marginBottom: "60px",
                  }}
                  size="medium"
                  name={f.label}
                  data={data}
                  mapData={f.mapData}
                  required={f.required}
                  defaultValue={getValues(f.name)}
                  onChange={onChange}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message || f.msg}
                  inputProps={{
                    ...register(f.name),
                    "aria-label": f.label,
                    required: true,
                  }}
                />
              </div>
            );
          } else if (f.type === "date")
            return (
              <InputDate
                key={i}
                label={f.label}
                name={f.name}
                error={!!errors[f.name]}
                msg={errors[f.name]?.message}
                type={f.type}
                size="medium"
                value={new Date(getValues(f.name))}
                onDateChange={(v) => setValue(f.name, v)}
                pickerProps={{
                  maxDate: subYears(new Date(), 10),
                }}
              />
            );
          else
            return (
              <InputText
                key={i}
                label={f.label}
                name={f.name}
                error={!!errors[f.name]}
                msg={errors[f.name]?.message}
                type={f.type}
                size="medium"
                inputProps={{
                  ...register(f.name),
                  ...inputProps,
                  "aria-label": f.label,
                }}
              />
            );
        })}
      </Grid>
      <Button type="submit" disabled={!isDirty} loading={isSubmitting}>
        Save Changes
      </Button>
    </Box>
  );
}

export default ProfileForm;
