import styled from "styled-components";
import BackArrow from "../components/BackArrow";
import Box from "./Box";
import Flex from "./Flex";
import Loading from "./Loading";
import Mask from "./Mask";
import Type from "./Type";

const Wrap = styled(Box).attrs((p) => ({
  elevation: Number.isInteger(p.elevation) ? p.elevation : 1,
}))`
  box-shadow: ${(p) =>
    `${6 + 2 * p.elevation}px ${6 + 2 * p.elevation}px ${
      10 + 3 * p.elevation
    }px 0px ${p.theme.color[`boxShadow${p.elevation < 0 ? 0 : p.elevation}`]}`};
  background-color: ${(p) => p.theme.color[p.bg || "white"]};
  border-radius: ${p => p.rad ? p.rad : '25'}px;
  border: ${p => p.theme.color.gray100} 1px solid;
  position: relative;
`;

const Head = styled.div`
  padding: ${(p) => p.padding || "20px 30px"};
  border-bottom: ${(props) => props.theme.color.gray300} 1px solid;
`;

const Body = styled.div`
  padding: ${(p) => p.padding || "20px 30px"};
  position: relative;
  @media only print {
    width: 90vw;
  }
  /* .floating-badge {
    position: absolute;
    top: 20px;
    right: -20px;
    background-color: ${(p) => p.theme.color.green};
    border-radius: 10px;
    color: white;
    text-align: center;
    min-width: 140px;
    padding: 10px;
    line-height: 20px;
    font-size: 15px;
  } */
`;

function Card({ title, children, onClose, loading = null, padding, ...props }) {
  return (
    <Wrap {...props}>
      {Boolean(title) && (
        <Head padding={padding}>
          <Flex jc="space-between">
            <Type var="h3" as="h3" sx={{ m: 0 }}>
              {title}
            </Type>
            <BackArrow />
          </Flex>
        </Head>
      )}
      <Body padding={padding}>{children}</Body>
      {Boolean(loading !== null) && (
        <Mask relative show={loading} style={{borderRadius: '15px'}}>
          <Loading />
        </Mask>
      )}
    </Wrap>
  );
}

export default Card;
