import styled from "styled-components";
import { Box, Button, Card, Flex, Icon, Type } from "../../ui";
import Carousel from "../../ui/carousel/Carousel";
import useImageLink from "../../utilities/hooks/useImageLink";
import useSubscribe from "../../utilities/hooks/useSubscribe";
import { format } from "date-fns";

const Wrap = styled(Flex)`
  max-width: 1350px;
  margin: 0px auto;
  div.event-card {
    position: relative;
    img {
      width: 100%;
      height: 262px;
      object-fit: cover;
      object-position: center;
    }
    .date {
      position: absolute;
      top: 60px;
      left: 0;
      span {
        display: block;
        background-color: ${(p) => p.theme.color.gray100};
        padding: 6px 10px;
        width: 180px;
        text-align: center;
        &:first-child {
          background-color: ${(p) => p.theme.color.secondary};
          color: ${(p) => p.theme.color.white};
          padding: 12px 15px;
          margin-bottom: 1px;
          font-weight: 500;
        }
      }
    }
  }
`;

const Slide = ({ data }) => {
  const { handleSub, sub } = useSubscribe(data);

  const img = useImageLink(data.imageURL);

  const date = format(new Date(data.date), "MMMM, dd yyyy");
  const time = format(new Date(data.date), "hh:mm a (O)");

  return (
    <Card className="event-card" padding="0" sx={{ m: 20 }}>
      <img src={img} alt="" />

      <Box sx={{ p: 20 }}>
        <Type var="h4" as="h4">
          {data.name}
        </Type>
        <Type var="p" sx={{ mb: 0, lh: 24 }}>
          {data.description}
        </Type>
        {Boolean(!sub) ? (
          <Button sx={{ mt: 30, w: "100%" }} onClick={handleSub}>
            I'm Intrested
          </Button>
        ) : (
          <Type var="titleWisp" sx={{ mt: 30, c: "gray500", ta: "center" }}>
            Subscribed.
          </Type>
        )}
      </Box>
      <div className="date">
        <span>{date}</span>
        <span>{time}</span>
      </div>
    </Card>
  );
};

const Next = () => {
  return <Icon circle name="arrowRight" c="primary" w={20} />;
};

const Prev = () => {
  return <Icon circle name="arrowLeft" c="primary" w={20} />;
};

function ListEvents({ list }) {
  return (
    <Wrap jc="space-between" dir={["row", "row", "row", "column-reverse"]}>
      <div>
        {list.length ? (
          <Carousel
            options={{
              slide: Slide,
              slides: list,
              itemW: 360,
              nav: {
                style: {
                  position: "absolute",
                  right: "-150px",
                  bottom: "20px",
                },
                next: <Next />,
                prev: <Prev />,
              },
            }}
          />
        ) : (
          "No Coming Soon Opportunities are available at this moment."
        )}
      </div>
      <Box
        sx={{
          w: [430, 430, 430, 320],
          mnw: [550, 550, 550, 0],
          p: 20,
          mt: 100,
        }}
      >
        <Type var="supTitle" as="h4">
          To Stitvest
        </Type>
        <Type var="h1" as="h1">
          Events
        </Type>
        <Type var="p">
          Discover more golden investment opportunities in our marketplace. An
          open space to showcase issued and transferable tokens and make
          investments among our trustworthy users.
        </Type>
      </Box>
    </Wrap>
  );
}

export default ListEvents;
