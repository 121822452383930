import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// import { RequireAuth } from "./services/auth";
import Providers from "./services/providers";
import Login from "./views/login/Login";
import ForgotPass from "./views/forgotPass/ForgotPass";
import Register from "./views/register/Register";
import ResetPass from "./views/resetPass/ResetPass";
import ResetSuccess from "./views/resetPass/ResetSuccess";
import ChangePass from "./views/changePass/ChangePass";
import ChangeSuccess from "./views/changePass/ChangeSuccess";
import GlobalStyle from "./GlobalStyles";
import { RequireAuth } from "services/auth";
import Dashboard from "views/dashboard/Dashboard";
import About from "views/pages/About";
import companyRoutes from "./views/company/routes";
import AccountType from "./views/register/AccountType";
import VerifyEmail from "./views/register/verifyEmail/VerifyEmail";
import VerifyIdentity from "./views/verifyIdentity/VerifyIdentity";
import Profile from "./views/profile/Profile";
import marketRoutes from "./views/marketplace/routes";
import Terms from "./views/pages/Terms";
import Privacy from "./views/pages/Privacy";
import Faq from "./views/pages/faq/Faq";
import Home from "views/home/Home";

Sentry.init({
  dsn: "https://b441926ec0244c91960b380b7c4e32b3@o4504524571738112.ingest.sentry.io/4504525574569984",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Providers>
    <GlobalStyle />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
        {/* <Route path="/" element={<App />}> */}
          {/* <Route index element={<Login />} /> */}
          <Route index element={<RequireAuth><Home /></RequireAuth>} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<RequireAuth><About /></RequireAuth>} />
          <Route path="/terms" element={<RequireAuth><Terms /></RequireAuth>} />
          <Route path="/privacy" element={<RequireAuth><Privacy /></RequireAuth>} />
          <Route path="/faq" element={<RequireAuth><Faq /></RequireAuth>} />
          <Route path="/forgot-password" element={<ForgotPass />} />
          <Route path="/verification-code" element={<VerifyEmail />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/login" element={<Register />} />
          <Route path="/reset-password" element={<ResetPass />} />
          <Route path="/change-password" element={<RequireAuth><ChangePass /></RequireAuth>} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route path="/reset-success" element={<ResetSuccess />} />
          <Route path="/change-success" element={<ChangeSuccess />} />
          <Route
            path="/account-type"
            element={
              <RequireAuth>
                <AccountType />
              </RequireAuth>
            }
          />
          <Route
            path="/verify-identity"
            element={
              <RequireAuth>
                <VerifyIdentity />
              </RequireAuth>
            }
          />
          {companyRoutes}
          {marketRoutes} 
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  </Providers>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
