import BackArrow from "../../components/BackArrow";
import Layout from "../../layout/Layout";
import { Box, Divider, Flex, Type } from "../../ui";
import Subscribe from "../home/Subscribe";

function Privacy() {
  return (
    <Layout>
      <Divider />
      <Box w={1350} center>
        <Flex jc="space-between" ai="flex-end" sx={{ px: [5, 30, 30, 30] }}>
          <Type var="h4" as="h4" sx={{ mt: 40 }}>
            Terms of Use
          </Type>
          <BackArrow />
        </Flex>
        <Divider border="gray300" h={30} />
      </Box>
      <Box w={1200} center sx={{ mb: 100, mt: 100, px: 30 }} className="box">
        <Type>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          sagittis elit ac ipsum viverra volutpat. Nam sit amet egestas velit.
          Vestibulum pharetra libero non libero fermentum, nec euismod elit
          eleifend. Sed placerat odio non pellentesque condimentum. Integer
          bibendum magna ligula, sed posuere est pulvinar id. Suspendisse
          finibus ornare interdum. Fusce magna nisi, sollicitudin in finibus a,
          congue at est. Donec dignissim urna sed massa scelerisque, in viverra
          ex euismod. Integer venenatis odio ac odio egestas tincidunt. Sed quis
          ex condimentum, posuere tortor a, ultrices enim. Etiam nunc ligula,
          finibus eu turpis nec, congue finibus orci. Integer felis nisi, rutrum
          vel ligula vitae, finibus gravida massa. Fusce id ex dui. Nunc id elit
          in tortor tempor elementum at ut arcu. Vivamus eget aliquet nulla. In
          et leo non dui viverra vehicula id nec ipsum.
        </Type>

      </Box>
    </Layout>
  );
}

export default Privacy;
