/* global BigInt */
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  InputText,
  Loading,
  Tooltip,
} from "../../../ui";
import useWeb3 from "../../../utilities/hooks/useWeb3";
import useMetamask from "../../../utilities/hooks/useMetamask";
import { emptyStringToNull } from "../../../utilities/yupSchemaCreator";
import { useProfile } from "../../profile/provider";
import { useEffect, useState } from "react";
import Dropdown from "../../../ui/core/Dropdown";

const schema = Yup.object().shape({
  amount: Yup.number("required")
    .nullable()
    .min(1, "Minimum 1")
    .required("Required")
    .transform(emptyStringToNull),
  address: Yup.string().required("Required"),
});

function MintTokensForm({ onSuccess }) {
  const [investorProfile, setInvestorProfile] = useState(null);
  const { connectMetamask } = useMetamask();
  const { buyTokensCash } = useWeb3();
  const {
    state: { status },
    fetchByWallet,
  } = useProfile();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAddress = watch("address", null);

  useEffect(() => {
    (async () => {
      if (watchAddress && watchAddress.length === 42) {
        try {
          const data = await fetchByWallet(watchAddress);
          setInvestorProfile(data);
        } catch (e) {
          setInvestorProfile(null);
        }
      } else setInvestorProfile(null);
    })();
  }, [watchAddress, fetchByWallet]);

  const handleError = ({ status, errors: e }) => {
    console.log("error", errors);
    console.log("status", status);
  };

  const onSubmit = async (values) => {
    try {
      await connectMetamask();
      await buyTokensCash(
        values.address,
        BigInt(values.amount) * BigInt(Math.pow(10, 6))
      );
      reset();
      onSuccess();
    } catch (e) {
      if (e.status === 400) handleError(e);
    }
  };

  function isAddressWrong() {
    return Boolean(
      status !== "fetching by wallet" && watchAddress && !investorProfile
    );
  }

  return (
    <Box
      sx={{ w: [680, 680, 680, "100%"], py: 20 }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Flex dir={["row", "row", "column"]} jc="flex-start" ai="flex-start" gap={24}>
        <InputText
          style={{ marginBottom: 0 }}
          label="Amount"
          id="amount"
          size="small"
          error={!!errors.amount}
          msg={errors.amount?.message}
          inputProps={{
            ...register("amount"),
            type: "number",
            min: 1,
            style: { maxWidth: 110 },
          }}
        />
        <Flex ai="flex-end" sx={{ mnw: [460, 460, 460, 300] }} gap={10}>
          <InputText
            style={{ marginBottom: 0, flexGrow: 1, maxWidth: "100%" }}
            label="Investor Wallet Address"
            id="Investor Wallet Address"
            placeholder="0x0000000000000000000000000000000000000000"
            size="small"
            error={!!errors.address}
            msg={errors.address?.message}
            inputProps={{
              ...register("address"),
            }}
          />
          {status === "fetching by wallet" && <Loading />}
          {investorProfile && (
            <Dropdown
              placement="right"
              content={
                <Tooltip>
                  {investorProfile.firstName} {investorProfile.lastName}
                  <Divider border="gray1k" h={12} />
                  {investorProfile.user.email}
                  <Divider border="gray1k" h={12} />
                  {investorProfile.user.mobileNumber}
                </Tooltip>
              }
            >
              <Box sx={{ d: "inline" }}>
                <Icon name="user" w={12} c="gray400" circle hover />
              </Box>
            </Dropdown>
          )}
          {isAddressWrong() && <Icon name="circleExclamation" c="red" w={34} />}
        </Flex>
      </Flex>
      <Button
        size="small"
        type="submit"
        loading={isSubmitting}
        bg="secondary"
        sx={{ mt: 24 }}
      >
        Mint Tokens
      </Button>
    </Box>
  );
}

export default MintTokensForm;
