import { useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import RegisterForm from "./RegisterForm";

function Register() {
  const navigate = useNavigate();

  const handleSuccess = ({ email }) => {
    navigate(`/verification-code?email=${email}`);
  };

  return (
    <LayoutMasked title="Create your Stitvest account">
      <RegisterForm onSuccess={handleSuccess} />
    </LayoutMasked>
  );
}

export default Register;
