import { useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import { useUtils } from "../../services/utilsProvider";
import ResetPassForm from "./ResetPassForm";

function ResetPass() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate("/login");
    notify('Password has been reset successfully.');
  };

  return (
    <LayoutMasked
      title="Reset your Password"
      subtitle="Create a new password of at least 6 characters"
    >
      <ResetPassForm onSuccess={handleSuccess} />
    </LayoutMasked>
  );
}

export default ResetPass;
