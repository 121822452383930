import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../services/auth";
import { Box, Button, InputText, Type } from "../../ui";
import { useState } from "react";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

function ForgotPassForm({ onSuccess }) {
  const [error, setError] = useState(null);

  const { resetPass } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = ({ status, errors: e }) => {
    console.log("error", errors);
    console.log("status", status);
    if (status === 400) {
      if (e.message === "INVALID_USER") setError("Wrong email.");
    }
  };

  const onSubmit = async (values) => {
    try {
      await resetPass(values.email);
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) handleError(e);
    }
  };

  return (
    <Box sx={{ w: [740, 740, 740, '100%'], px: 30, }} as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* {searchParams.get("type") === "email" ? (
        <ResetByEmail />
      ) : (
        <ResetByPhone />
      )} */}
      {error && (
        <Type sx={{ c: "red", ta: "center", mt: -10, mb: 40 }}>{error}</Type>
      )}
      <InputText
        placeholder="Email"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          ...register("email"),
          autoFocus: true,
        }}
      />
      <Button
        size="large"
        type="submit"
        loading={isSubmitting}
        bg="secondary"
        style={{ margin: "0 auto" }}
      >
        Confirm Email Address
      </Button>
    </Box>
  );
}

export default ForgotPassForm;
