var apiUrl;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://api.stitvest.com";
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://staging.api.stitvest.com";
else {
  apiUrl = "https://staging.api.stitvest.com";
}

export { apiUrl };
