import { useState } from "react";
import { useUtils } from "services/utilsProvider";
import { Button } from "ui";
import useMetamask from "utilities/hooks/useMetamask";
import useWeb3 from "utilities/hooks/useWeb3";

function CancelOrderBtn({ id }) {
  const { removeOrder } = useWeb3();
  const { connectMetamask } = useMetamask();
  const { notify } = useUtils();

  const [loading, setLoading] = useState();
  const handleRemoveOrder = async (id) => {
    try {
      setLoading(true);
      await connectMetamask();
      await removeOrder(id);
      let timer = 4;
      notify(
        `Order has beem removed successfully. This page will reload in a few seconds`
      );
      setTimeout(() => {
        window.location.reload();
      }, timer * 1000);
    } catch (e) {
      notify("an error has accured please try again later.", "alert");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      size="small"
      loading={loading}
      onClick={() => handleRemoveOrder(id)}
    >
      Cancel
    </Button>
  );
}

export default CancelOrderBtn;
