import styled from "styled-components";

const DotWrap = styled.span`
  cursor: pointer;
`;

function Pagination({ count, pager, page, setPager }) {
  const { dot, ...props } = pager;
  return (
    <div className="slider-dots" {...props}>
      {new Array(count).fill(0).map((_, i) => (
        <DotWrap
          key={i}
          onClick={() => setPager(i)}
          className={`item ${i === page ? "active" : ""}`}
        >
          {dot}
        </DotWrap>
      ))}
    </div>
  );
}

export default Pagination;
