import styled from "styled-components";

const Label = styled.label`
  font-size: 15px;
  color: ${(props) => props.theme.color.gray600};
  display: block;
  margin-bottom: 14px;
  font-weight: 500;
  padding-left: 8px;
  /* letter-spacing: 1px; */
`;

export default Label;
